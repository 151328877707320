import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Box from "@material-ui/core/Box";

import { i18n } from "../../translate/i18n";
import imageDefaultUser from "../../assets/imageDefaultUser.webp";

import api, { apione } from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { Chip, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: theme.palette.tabHeaderBackground,
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  avatarContainer: {
    position: "relative",
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: "0 auto 16px",
    borderRadius: "50%",
    "&:hover": {
      "& $avatarOverlay": {
        opacity: 1,
      }
    }
  },
  avatar: {
    width: "100%",
    height: "100%",
    border: `3px solid ${theme.palette.primary.main}`,
  },
  avatarOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "50%",
    opacity: 0,
    transition: "opacity 0.3s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  input: {
    display: "none"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  btnWrapper: {
    position: "relative",
    borderRadius: 10,
    padding: 10,
    width: 100,
  },
  noHover: {
    minWidth: 0,
    paddingRight: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000014",
      },
      "&:hover fieldset": {
        borderColor: "#00000014",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000014",
      },
    },
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "¡Muy corto!")
    .max(50, "¡Demasiado largo!")
    .required("Requerido"),
  password: Yup.string()
    .min(5, "¡Muy corto!")
    .max(50, "¡Demasiado largo!")
    .required("Requerido"),
  email: Yup.string().email("Correo electrónico inválido").required("Requerido"),
});

const UserEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "¡Muy corto!")
    .max(50, "¡Demasiado largo!")
    .required("Requerido"),
  password: Yup.string().min(5, "¡Muy corto!").max(50, "¡Demasiado largo!"),
  email: Yup.string().email("Correo electrónico inválido").required("Requerido"),
});

const UserModal = ({ open, onClose, userId, usersLength = [], adminCount }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user: loggedInUser } = useContext(AuthContext);
  const [profileDisabled, setProfileDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    profile: "",
    languageId: "",
    profileImage: ""
  });
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) {
        setUser({
          name: "",
          email: "",
          password: "",
          profile: "",
          languageId: "",
          profileImage: ""
        });
        setSelectedQueueIds([]);
        return;
      }
      setIsLoading(true);
      try {
        const { data } = await api.get(`/users/${userId}`);
        setUser({
          ...data,
          password: "",
          profileImage: data.profile_image || ""
        });
        const userQueueIds = data.queues?.map((queue) => queue.id) || [];
        setSelectedQueueIds(userQueueIds);
      } catch (err) {
        toastError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [userId, open]);

  useEffect(() => {
    const isEditingSelf = userId === loggedInUser.id;
    const isCurrentUserAdmin = loggedInUser.profile === "admin";
    const hasMultipleAdmins = adminCount > 1;

    if (isEditingSelf && isCurrentUserAdmin && !hasMultipleAdmins) {
      setProfileDisabled(true);
    } else {
      setProfileDisabled(false);
    }
  }, [adminCount, userId, loggedInUser.id, loggedInUser.profile]);

  const handleClose = () => {
    setIsSubmitting(false);
    onClose();
  };

  const handleSaveUser = async (values) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      
      // Agregar los campos del formulario
      formData.append('name', values.name);
      formData.append('email', values.email);
      if (values.password) formData.append('password', values.password);
      if (values.profile) formData.append('profile', values.profile);
      if (values.languageId) formData.append('languageId', values.languageId);
      
      // Convertir el array de queueIds a JSON string
      if (selectedQueueIds.length > 0) {
        formData.append('queueIds', JSON.stringify(selectedQueueIds));
      }

      // Si hay una imagen nueva (en formato base64), convertirla a Blob
      if (values.profileImage && values.profileImage.startsWith('data:image')) {
        const response = await fetch(values.profileImage);
        const blob = await response.blob();
        formData.append('profileImage', blob, 'profile-image.jpg');
      }

      // Agregar logs para debugging
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      if (userId) {
        await api.put(`/users/${userId}`, formData, config);
      } else {
        await api.post("/users", formData, config);
      }
      
      toast.success(i18n.t("userModal.success"));
      handleClose();
    } catch (err) {
      console.error("Error completo:", err);
      toastError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = async (e, setFieldValue, values) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) { // 2MB
      toast.error(i18n.t("La imagen no debe superar los 2MB"));
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      // Actualizar solo la imagen manteniendo el resto de valores
      setUser(prev => ({
        ...prev,
        ...values,  // Mantener todos los valores actuales
        profileImage: reader.result
      }));
      setFieldValue("profileImage", reader.result);
    };
    reader.readAsDataURL(file);
  };

  const normalizeImagePath = (path) => {
    if (!path) return imageDefaultUser;
    // Si es una imagen base64 (recién cargada), retornarla directamente
    if (path.startsWith('data:image')) return path;
    // Si ya es una URL completa, retornarla
    if (path.startsWith('http')) return path;
    // Reemplazar las barras invertidas por barras normales
    const normalizedPath = path.replace(/\\/g, '/');
    // Asegurarse de que la ruta comience con la URL base
    return `${process.env.REACT_APP_BACKEND_URL}${normalizedPath}`;
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: theme.palette.tabHeaderBackground }}
        >
          {userId
            ? `${i18n.t("userModal.title.edit")}`
            : `${i18n.t("userModal.title.add")}`}
        </DialogTitle>
        {isLoading ? (
          <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </DialogContent>
        ) : (
          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={userId ? UserEditSchema : UserSchema}
            onSubmit={(values, actions) => {
              handleSaveUser(values);
            }}
          >
            {({ touched, errors, setFieldValue, values }) => (
              <Form>
                <DialogContent
                  dividers
                  style={{ backgroundColor: theme.palette.tabHeaderBackground }}
                >
                  <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
                    <div className={classes.avatarContainer}>
                      <Avatar
                        src={normalizeImagePath(values.profileImage)}
                        className={classes.avatar}
                        alt={values.name || "Profile"}
                      />
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        onChange={(e) => handleImageUpload(e, setFieldValue, values)}
                      />
                      <label htmlFor="icon-button-file">
                        <div className={classes.avatarOverlay}>
                          <IconButton
                            component="span"
                            size="small"
                            style={{ color: "white" }}
                          >
                            <PhotoCamera />
                          </IconButton>
                        </div>
                      </label>
                    </div>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.name")}
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.email")}
                        name="email"
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.password")}
                        type="password"
                        name="password"
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel id="language-selection-input-label">
                          {i18n.t("userModal.form.language")}
                        </InputLabel>
                        <Field
                          as={Select}
                          label={i18n.t("userModal.form.language")}
                          name="languageId"
                          labelId="language-selection-label"
                          id="language-selection"
                          className={classes.textField}
                          required
                        >
                          <MenuItem value="2">Español</MenuItem>
                          <MenuItem value="1">Inglés</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Can
                        role={loggedInUser.profile}
                        perform="user-modal:editProfile"
                        yes={() => (
                          <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel id="profile-selection-input-label">
                              {i18n.t("userModal.form.profile")}
                            </InputLabel>
                            <Field
                              as={Select}
                              label={i18n.t("userModal.form.profile")}
                              name="profile"
                              labelId="profile-selection-label"
                              id="profile-selection"
                              required
                              disabled={profileDisabled}
                            >
                              <MenuItem value="admin">Admin</MenuItem>
                              <MenuItem value="user">User</MenuItem>
                            </Field>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Can
                        role={loggedInUser.profile}
                        perform="user-modal:editProfile"
                        yes={() => (
                          <FormControl fullWidth variant="outlined" margin="dense" className={classes.formControl}>
                            <QueueSelect selectedQueueIds={selectedQueueIds} onChange={setSelectedQueueIds} />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  style={{ backgroundColor: theme.palette.tabHeaderBackground }}
                >
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
                    className={classes.btnWrapper}
                  >
                    {i18n.t("userModal.buttons.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {userId
                      ? `${i18n.t("userModal.buttons.okEdit")}`
                      : `${i18n.t("userModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    </div>
  );
};

export default UserModal;
