import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, IconButton, InputAdornment, useTheme, Popover } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import EmojiPicker from "emoji-picker-react";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import 'moment/locale/es';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
    borderRadius: 10,
    padding: 10,
    width: 100,
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  noHover: {
    minWidth: 0,
    paddingRight: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00000014",
      },
      "&:hover fieldset": {
        borderColor: "#00000014",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00000014",
      },
    },
  },
  emojiButton: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },  
  },
  emojiPickerStyle: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '300px',
    background: theme.palette.tabHeaderBackground,
  },
  dialogContent: {
    position: 'relative',
    minHeight: '300px',
    background: theme.palette.tabHeaderBackground,
  }
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string().min(5, "mensaje corto").required("Obligatorio"),
  contactId: Yup.number().required("Obligatorio"),
  sendAt: Yup.string()
    .required("Obligatorio")
    // .test("futureDate", "La fecha debe ser al menos 10 minutos posterior a la actual", function(value) {
    //   const currentDate = moment();
    //   const selectedDate = moment(value);
    //   return selectedDate.isAfter(currentDate.add(10, 'minutes'));
    // }),
});

const ScheduleModal = ({
  open,
  onClose,
  scheduleId,
  contactId,
  cleanContact,
  reload,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const bodyRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(moment().add(20, 'minutes'));
  const [initialDate] = useState(moment());

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(20, 'minutes').format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
  };

  const initialContact = {
    id: "",
    name: "",
  };

  const [schedule, setSchedule] = useState(initialState);
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [contacts, setContacts] = useState([initialContact]);

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      setLoading(true);
      try {
        (async () => {
          const { data: contactList } = await api.get("/contacts/list", {
            params: { companyId: companyId },
          });
          let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customList)) {
            setContacts([{ id: "", name: "" }, ...customList]);
          }
          if (contactId) {
            setSchedule((prevState) => {
              return { ...prevState, contactId };
            });
          }

          if (scheduleId) {
            const { data } = await api.get(`/schedules/${scheduleId}`);
            setSchedule((prevState) => {
              return {
                ...prevState,
                ...data,
                sendAt: moment(data.sendAt).local().format("YYYY-MM-DDTHH:mm"),
              };
            });
            setCurrentContact(data.contact);
          }
          setLoading(false);
        })();
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    }
  }, [scheduleId, contactId, open, user]);

  useEffect(() => {
    if (contactId && open) {
      setSchedule((prevState) => ({
        ...prevState,
        contactId: contactId,
        sendAt: scheduleId ? prevState.sendAt : moment().add(20, 'minutes').format("YYYY-MM-DDTHH:mm")
      }));
    }
  }, [contactId, open, scheduleId]);

  const handleClose = () => {
    onClose();
    if (cleanContact) {
      cleanContact();
    }
    setSchedule({
      ...initialState,
      sendAt: moment().add(20, 'minutes').format("YYYY-MM-DDTHH:mm")
    });
    setCurrentContact(initialContact);
    setSelectedDate(moment().add(20, 'minutes'));
  };

  const handleSaveSchedule = async (values) => {
    setLoading(true);
    try {
      const dataToSend = {
        ...values,
        sendAt: moment(values.sendAt).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      };

      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, dataToSend);
      } else {
        await api.post("/schedules", dataToSend);
      }
      toast.success(i18n.t("scheduleModal.success"));
      handleClose();
      if (reload) reload();
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const handleEmojiButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmojiPickerClose = () => {
    setAnchorEl(null);
  };

  const handleEmojiClick = (emojiObject, setFieldValue) => {
    const emoji = emojiObject.emoji || emojiObject.native || emojiObject;
    const field = bodyRef.current;
    
    if (field) {
      const start = field.selectionStart || 0;
      const end = field.selectionEnd || 0;
      const text = field.value || "";
      const newText = text.substring(0, start) + emoji + text.substring(end);

      setFieldValue("body", newText);
      field.focus();
      setTimeout(() => {
        field.setSelectionRange(start + emoji.length, start + emoji.length);
      }, 0);
    }
    handleEmojiPickerClose();
  };

  const isError = schedule.status === "ERROR";

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ 
            background: theme.palette.tabHeaderBackground,
            color: isError ? 'red' : 'inherit'
          }}
        >
          {isError
            ? "Error de Envío - No se puede editar"
            : `Programar mensaje ${capitalize(schedule.status)}`}
        </DialogTitle>
        
        {loading ? (
          <DialogContent className={classes.loadingContainer}>
            <CircularProgress />
          </DialogContent>
        ) : (
          <Formik
            initialValues={schedule}
            enableReinitialize={true}
            validationSchema={ScheduleSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveSchedule(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values, setFieldValue }) => (
              <Form>
                <DialogContent
                  dividers
                  style={{ background: theme.palette.tabHeaderBackground }}
                >
                  <div className={classes.multFieldLine}>
                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        disabled={isError}
                        fullWidth
                        value={currentContact}
                        options={contacts}
                        className={classes.textField}
                        onChange={(e, contact) => {
                          const contactId = contact ? contact.id : "";
                          setSchedule(prevState => ({
                            ...prevState,
                            contactId,
                            sendAt: prevState.sendAt
                          }));
                          setCurrentContact(contact ? contact : initialContact);
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => {
                          return value.id === option.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Contacto"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <br />
                  <div className={classes.multFieldLine}>
                    <Field
                      as={TextField}
                      rows={9}
                      multiline={true}
                      label={i18n.t("scheduleModal.form.body")}
                      name="body"
                      error={touched.body && Boolean(errors.body)}
                      helperText={touched.body && errors.body}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      inputRef={bodyRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              className={classes.emojiButton}
                              onClick={handleEmojiButtonClick}
                            >
                              <InsertEmoticonIcon />
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <br />
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                    <KeyboardDateTimePicker
                      disabled={isError || loading}
                      variant="inline"
                      format="DD/MM/YYYY HH:mm"
                      margin="normal"
                      fullWidth
                      label="Fecha y hora de envío"
                      value={values.sendAt}
                      style={{ marginLeft: theme.spacing(2) }}
                      onChange={(date) => {
                        if (date) {
                          const localDate = moment(date);
                          setSelectedDate(localDate);
                          setFieldValue(
                            "sendAt",
                            localDate.format("YYYY-MM-DDTHH:mm")
                          );
                        }
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date and time",
                      }}
                      className={classes.textField}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        style: { color: "#888" },
                      }}
                      error={touched.sendAt && Boolean(errors.sendAt)}
                      helperText={touched.sendAt && errors.sendAt}
                      minDate={initialDate}
                      minDateMessage="La fecha no puede ser anterior a la actual"
                      ampm={false}
                    />
                  </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions
                  style={{ background: theme.palette.tabHeaderBackground }}
                >
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={loading}
                    variant="outlined"
                    className={classes.btnWrapper}
                  >
                    {i18n.t("scheduleModal.buttons.cancel")}
                  </Button>
                  {!isError && (
                    <Button
                      type="submit"
                      color="primary"
                      disabled={loading}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {loading ? (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      ) : scheduleId ? (
                        i18n.t("scheduleModal.buttons.okEdit")
                      ) : (
                        i18n.t("scheduleModal.buttons.okAdd")
                      )}
                    </Button>
                  )}
                </DialogActions>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleEmojiPickerClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div className={classes.emojiPickerStyle}>
                    <EmojiPicker
                      onEmojiClick={(emojiObject) => handleEmojiClick(emojiObject, setFieldValue)}
                      searchPlaceholder="Buscar emojis"
                      categories={{
                        smileys_people: 'Caras y personas',
                        animals_nature: 'Animales y naturaleza',
                        food_drink: 'Comida y bebida',
                        travel_places: 'Viajes y lugares',
                        activities: 'Actividades',
                        objects: 'Objetos',
                        symbols: 'Símbolos',
                        flags: 'Banderas'
                      }}
                    />
                  </div>
                </Popover>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
