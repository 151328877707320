import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";
import { isString } from 'lodash';

const toastError = err => {
	if (!err) {
		console.error('Error indefinido recibido en toastError');
		toast.error('Error inesperado');
		return;
	}

	const toastConfig = {
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const errorMsg = err.response?.data?.error;
	const responseData = err.response?.data;

	if (errorMsg) {
		const message = i18n.exists(`backendErrors.${errorMsg}`) 
			? i18n.t(`backendErrors.${errorMsg}`)
			: errorMsg;

		toast.error(message, {
			...toastConfig,
			toastId: errorMsg,
		});
		return;
	}

	if (isString(responseData)) {
		toast.error(responseData, toastConfig);
		return;
	}

	console.error('Error detallado:', err);
	toast.error(err, toastConfig);
};

export default toastError;
