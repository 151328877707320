import React, { useEffect, useState, useRef } from 'react';
import { Box, IconButton, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import api from '../../services/api';
import { getSocketConnection } from '../../services/socket';
import EmojiPicker from 'emoji-picker-react';
import EmoteIcon from "../../assets/icon/EmoteIcon";
import AttachFile from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';
import InputBase from '@material-ui/core/InputBase';
import SendIcon from '@material-ui/icons/Send';

// Sonido de notificación
import notificationSound from '../../assets/chat_notify.mp3';

// Configuración para la conexión con el backend
// El token identifica la compañía a la que llegarán los mensajes
const TOKEN = 'wc_245_m87kd3c2_fanm7wrln35';
const COMPANY_ID = 245; // ID de la compañía correspondiente al token

// Mensajes por defecto que se usarán si no se pueden obtener de la conexión
const DEFAULT_WELCOME_MESSAGE = {
  id: 'welcome-message',
  text: '¡Hola! Bienvenido a nuestro chat de soporte. ¿En qué podemos ayudarte hoy?',
  sender: 'bot',
};

const DEFAULT_WAITING_MESSAGE = {
  id: 'waiting-message',
  text: 'Gracias por tu mensaje. Un agente de soporte te atenderá en breve. Por favor, espera un momento.',
  sender: 'bot',
};

const useStyles = makeStyles((theme) => ({
  widgetButton: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(3),
    zIndex: 1000,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 56,
    height: 56,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  widgetButtonWrapper: {
    position: 'relative',
    display: 'inline-flex',
  },
  chatContainer: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1000,
    width: 350,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[10],
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
    transform: (props) => props.isOpen ? 'translateY(0)' : 'translateY(20px)',
    opacity: (props) => props.isOpen ? 1 : 0,
    visibility: (props) => props.isOpen ? 'visible' : 'hidden',
  },
  chatHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chatTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  chatBody: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  chatFooter: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  messageInput: {
    width: '100%',
    padding: theme.spacing(1, 2),
    borderRadius: 20,
    border: `1px solid ${theme.palette.divider}`,
    outline: 'none',
    fontSize: 14,
    marginLeft: 5,
  },
  message: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    borderRadius: 10,
    maxWidth: '70%',
    wordBreak: 'break-word',
  },
  userMessage: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    alignSelf: 'flex-end',
    borderBottomRightRadius: 0,
  },
  botMessage: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.contrastText,
    alignSelf: 'flex-start',
    borderBottomLeftRadius: 0,
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  loadingDots: {
    animation: '$blink 1.4s infinite both',
  },
  '@keyframes blink': {
    '0%': {
      opacity: 0.2,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.2,
    },
  },
  // Estilos para imágenes y archivos adjuntos
  mediaPreview: {
    maxWidth: '100%',
    maxHeight: 150,
    borderRadius: 5,
    marginBottom: 5,
    cursor: 'pointer',
    objectFit: 'contain',
  },
  mediaWithCaption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    width: '100%',
    marginBottom: 5,
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageCaption: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: '14px',
    wordBreak: 'break-word',
  },
  notificationBadge: {
    position: 'absolute',
    top: -15,
    right: -15,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: '50%',
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    boxShadow: theme.shadows[2],
  },
  emojiBox: {
    position: 'absolute',
    bottom: 70,
    right: 0,
    zIndex: 100,
  },
  emojiButton: {
    color: theme.palette.grey[600],
    padding: '6px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  emojiPickerContainer: {
    position: 'absolute',
    bottom: 70,
    right: 10,
    zIndex: 1001,
  },
  inputToolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  filePreview: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  fileIcon: {
    marginRight: theme.spacing(0.5),
  },
  mediaPreviewContainer: {
    position: 'relative',
    marginBottom: theme.spacing(1),
  },
  mediaPreviewDelete: {
    position: 'absolute',
    top: -10,
    right: -10,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: 2,
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: 20,
    zIndex: 10,
  },
  fileInput: {
    display: 'none',
  },
  attachButton: {
    color: theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  root: {
    display: 'none',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  inputContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    padding: theme.spacing(1),
  },
  sendButton: {
    color: theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  mediaImage: {
    maxWidth: '100%',
    maxHeight: 150,
    borderRadius: 5,
    marginBottom: 10,
    cursor: 'pointer',
  },
  mediaVideo: {
    maxWidth: '100%',
    maxHeight: 150,
    borderRadius: 5,
    marginBottom: 10,
  },
  mediaAudio: {
    maxWidth: '100%',
    maxHeight: 150,
    borderRadius: 5,
    marginBottom: 10,
  },
  mediaFile: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    border: '1px solid #ccc',
    borderRadius: 5,
    backgroundColor: '#f0f0f0',
    margin: 10,
    cursor: 'pointer',
  },
  fileInfo: {
    marginLeft: 10,
  },
}));

/**
 * Componente ChatWidget 
 * 
 * Widget de chat para integrarse con el sistema de tickets de Venttify.
 * Utiliza un token específico de empresa para enviar los mensajes y generar tickets
 * a través del canal WebChat.
 */
const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [newMessage, setNewMessage] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [companyId, setCompanyId] = useState(COMPANY_ID);
  const [hasAgentReplied, setHasAgentReplied] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [processedMessageIds, setProcessedMessageIds] = useState(new Set());
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [welcomeMessage, setWelcomeMessage] = useState(DEFAULT_WELCOME_MESSAGE);
  const [waitingMessage, setWaitingMessage] = useState(DEFAULT_WAITING_MESSAGE);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const notificationAudioRef = useRef(new Audio(notificationSound));
  const theme = useTheme();
  const classes = useStyles({ isOpen });
  
  // Función para obtener los mensajes de la conexión de WhatsApp
  const fetchWhatsAppMessages = async () => {
    try {
      console.log('Obteniendo mensajes para token:', TOKEN);
      const response = await api.get(`/api/whatsapp/connection/${TOKEN}`);
      console.log('Respuesta de la API:', response.data);
      
      // Asegurarnos de que el mensaje de bienvenida siempre esté configurado
      const welcomeText = response.data?.welcomeMessage || DEFAULT_WELCOME_MESSAGE.text;
      console.log('Configurando mensaje de bienvenida:', welcomeText);
      setWelcomeMessage({
        id: 'welcome-message',
        text: welcomeText,
        sender: 'bot',
      });
      
      // Asegurarnos de que el mensaje de espera siempre esté configurado
      const waitingText = response.data?.waitingMessage || DEFAULT_WAITING_MESSAGE.text;
      console.log('Configurando mensaje de espera:', waitingText);
      setWaitingMessage({
        id: 'waiting-message',
        text: waitingText,
        sender: 'bot',
      });
    } catch (error) {
      console.error('Error al obtener mensajes de WhatsApp:', error);
      // Si hay error, usamos los mensajes por defecto
      console.log('Usando mensajes por defecto debido al error');
      setWelcomeMessage(DEFAULT_WELCOME_MESSAGE);
      setWaitingMessage(DEFAULT_WAITING_MESSAGE);
    }
  };
  
  // Generar y cargar sessionId del localStorage al iniciar
  useEffect(() => {
    const savedSessionId = localStorage.getItem('venttify_session_id');
    const savedTicketId = localStorage.getItem('venttify_ticket_id');
    const savedCompanyId = localStorage.getItem('venttify_company_id');
    const hasSeenWelcome = localStorage.getItem('venttify_seen_welcome') === 'true';
    const savedUnreadCount = localStorage.getItem('venttify_unread_count');
    
    console.log('Estado inicial:', {
      savedSessionId,
      savedTicketId,
      savedCompanyId,
      hasSeenWelcome,
      savedUnreadCount
    });
    
    if (savedCompanyId) {
      setCompanyId(parseInt(savedCompanyId, 10));
    }
    
    if (savedSessionId) {
      setSessionId(savedSessionId);
      setIsFirstOpen(!hasSeenWelcome);
      console.log('SessionId encontrado, isFirstOpen:', !hasSeenWelcome);
      
      if (savedTicketId) {
        console.log('TicketId encontrado:', savedTicketId);
        setTicketId(savedTicketId);
        // Cargar mensajes inmediatamente si hay un ticketId
        loadMessages(savedTicketId);
      }
      
      if (savedUnreadCount) {
        setUnreadMessages(parseInt(savedUnreadCount, 10));
      }
    } else {
      const newSessionId = `webchat_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
      setSessionId(newSessionId);
      localStorage.setItem('venttify_session_id', newSessionId);
      setIsFirstOpen(true);
      console.log('Nueva sesión creada, isFirstOpen:', true);
    }

    // Obtener los mensajes de la conexión de WhatsApp
    fetchWhatsAppMessages();
  }, []);
  
  // Persistir el contador de mensajes no leídos
  useEffect(() => {
    if (unreadMessages > 0) {
      localStorage.setItem('venttify_unread_count', unreadMessages.toString());
    } else {
      localStorage.removeItem('venttify_unread_count');
    }
  }, [unreadMessages]);
  
  // Función para cargar mensajes
  const loadMessages = async (ticketId) => {
    try {
      console.log('Cargando mensajes para ticket:', ticketId);
      const response = await api.get(`/api/tickets/${ticketId}/messages`);
      console.log('Respuesta de la API de mensajes:', response.data);
      
      if (response.data && Array.isArray(response.data)) {
        const formattedMessages = response.data.map(msg => ({
          id: msg.id,
          text: msg.body,
          sender: msg.fromMe ? 'bot' : 'user',
          mediaUrl: msg.mediaUrl,
          mediaType: msg.mediaType
        }));
        
        console.log('Mensajes formateados:', formattedMessages);
        
        // Verificar si hay algún mensaje del agente
        const agentReplied = formattedMessages.some(msg => msg.sender === 'bot');
        setHasAgentReplied(agentReplied);
        
        // Si es la primera vez que se abre y no hay mensajes, mostrar bienvenida
        if (formattedMessages.length === 0 && isFirstOpen) {
          console.log('No hay mensajes y es primera apertura, mostrando bienvenida');
          setMessages([welcomeMessage]);
          localStorage.setItem('venttify_seen_welcome', 'true');
          setIsFirstOpen(false);
        } else {
          setMessages(formattedMessages);
        }
      }
    } catch (error) {
      console.error('Error al cargar mensajes:', error);
      // Si hay error y es primera apertura, mostrar mensaje de bienvenida
      if (isFirstOpen) {
        console.log('Error al cargar mensajes y es primera apertura, mostrando bienvenida');
        setMessages([welcomeMessage]);
        localStorage.setItem('venttify_seen_welcome', 'true');
        setIsFirstOpen(false);
      }
    }
  };
  
  // Desplazarse al último mensaje cuando se envían o reciben nuevos mensajes
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  useEffect(() => {
    if (isOpen && messagesEndRef.current) {
      scrollToBottom();
    }
  }, [messages, isOpen]);
  
  // Función para reproducir el sonido de notificación
  const playNotificationSound = () => {
    try {
      // Reiniciar el audio para poder reproducirlo múltiples veces
      notificationAudioRef.current.currentTime = 0;
      notificationAudioRef.current.play().catch(error => {
        // Capture las excepciones causadas por restricciones del navegador
        console.log("No se pudo reproducir el sonido de notificación:", error);
      });
    } catch (error) {
      console.error("Error al reproducir el sonido:", error);
    }
  };
  
  // Configuración de sockets para escuchar mensajes entrantes del ticket
  useEffect(() => {
    // Escuchar mensajes incluso cuando el chat está cerrado
    if (!ticketId || !sessionId) return;
    
    const socket = getSocketConnection(companyId);
    
    // Unirse al canal específico para webchat
    if (sessionId) {
      socket.emit('joinChatBox', ticketId);
      socket.emit('joinWebchat', sessionId);
      
      // Registrar canal específico para el contacto (webchat:<contactId>)
      if (sessionId.startsWith('webchat_')) {
        socket.emit('joinChatBox', `webchat:${sessionId}`);
      }
      
      console.log(`Joined webchat channels for ticket: ${ticketId} and session: ${sessionId}`);
    }
    
    // Escuchar mensajes del ticket
    const handleContactMessage = (data) => {
      if (data.message && data.message.ticketId === parseInt(ticketId, 10)) {
        console.log('Mensaje recibido por socket (contactsMessage):', data);
        
        // Procesamos todos los mensajes que no hayan sido procesados antes
        if (!processedMessageIds.has(data.message.id)) {
          setProcessedMessageIds(prev => {
            const updated = new Set(prev);
            updated.add(data.message.id);
            return updated;
          });
          
          // Si es un mensaje del agente, marcamos que ha habido respuesta
          if (data.message.fromMe) {
            setHasAgentReplied(true);
          }
          
          // Si el widget está cerrado y es mensaje del agente, incrementar contador
          if (!isOpen && data.message.fromMe) {
            setUnreadMessages(prev => prev + 1);
            playNotificationSound();
          }
          
          // Almacenar el mensaje en la lista de mensajes aunque el widget esté cerrado
          const newMessage = {
            id: data.message.id,
            text: data.message.body,
            sender: data.message.fromMe ? 'bot' : 'user',
            mediaUrl: data.message.mediaUrl,
            mediaType: data.message.mediaType
          };
          
          // Solo agregar el mensaje si no existe ya en la lista
          setMessages(prev => {
            if (!prev.some(m => m.id === data.message.id)) {
              return [...prev, newMessage];
            }
            return prev;
          });
        }
      }
    };
    
    // Mensaje específico para webchat
    const handleWebchatMessage = (data) => {
      if (data.message) {
        console.log('Mensaje webchat recibido (webchatMessage):', data);
        
        // Procesamos todos los mensajes que no hayan sido procesados antes
        if (!processedMessageIds.has(data.message.id)) {
          setProcessedMessageIds(prev => {
            const updated = new Set(prev);
            updated.add(data.message.id);
            return updated;
          });
          
          // Si es un mensaje del agente, marcamos que ha habido respuesta
          if (data.message.fromMe) {
            setHasAgentReplied(true);
          }
          
          // Si el widget está cerrado y es un mensaje del agente, incrementar contador
          if (!isOpen && data.message.fromMe) {
            setUnreadMessages(prev => prev + 1);
            playNotificationSound();
          }
          
          // Crear el objeto de mensaje
          const newMessage = {
            id: data.message.id,
            text: data.message.body,
            sender: data.message.fromMe ? 'bot' : 'user',
            mediaUrl: data.message.mediaUrl,
            mediaType: data.message.mediaType
          };
          
          // Solo agregar el mensaje si no existe ya en la lista
          setMessages(prev => {
            if (!prev.some(m => m.id === data.message.id)) {
              return [...prev, newMessage];
            }
            return prev;
          });
        }
      }
    };
    
    // Escuchar eventos de socket
    socket.on(`company-${companyId}-contactsMessage`, handleContactMessage);
    socket.on('webchatMessage', handleWebchatMessage);
    
    // Limpiar listeners al desmontar
    return () => {
      socket.off(`company-${companyId}-contactsMessage`, handleContactMessage);
      socket.off('webchatMessage', handleWebchatMessage);
    };
  }, [ticketId, sessionId, companyId, isOpen, processedMessageIds]);
  
  // Efecto para mostrar el mensaje de bienvenida al abrir el chat
  useEffect(() => {
    console.log('Estado del chat:', {
      isOpen,
      isFirstOpen,
      messagesLength: messages.length,
      welcomeMessage,
      ticketId
    });

    if (isOpen && isFirstOpen && messages.length === 0) {
      console.log('Mostrando mensaje de bienvenida');
      setMessages([welcomeMessage]);
      localStorage.setItem('venttify_seen_welcome', 'true');
      setIsFirstOpen(false);
    }
  }, [isOpen, isFirstOpen, messages.length, welcomeMessage, ticketId]);
  
  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      // Al abrir el chat, resetear el contador de mensajes no leídos
      setUnreadMessages(0);
      localStorage.removeItem('venttify_unread_count');
      
      // Verificar si es la primera vez que se abre
      const hasSeenWelcome = localStorage.getItem('venttify_seen_welcome') === 'true';
      setIsFirstOpen(!hasSeenWelcome);
      console.log('Abriendo chat, isFirstOpen:', !hasSeenWelcome);
    } else {
      // Al cerrar el chat, ocultar el selector de emojis
      setShowEmoji(false);
    }
  };
  
  const handleMessageChange = (e) => {
    setNewMessage(e.target.value);
  };
  
  const handleAddEmoji = (emojiData) => {
    const emoji = emojiData.emoji;
    setNewMessage(prev => prev + emoji);
  };

  const handleOpenEmoji = () => {
    setShowEmoji(!showEmoji);
  };
  
  // Manejo de selección de archivos
  const handleFileClick = () => {
    fileInputRef.current.click();
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Validar tamaño máximo (10MB)
    if (file.size > 10 * 1024 * 1024) {
      alert('El archivo es demasiado grande. El tamaño máximo es 10MB.');
      return;
    }
    
    setSelectedFile(file);
    
    // Crear vista previa
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFilePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith('video/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFilePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith('audio/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFilePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Para otros tipos de archivos, solo mostramos el nombre
      setFilePreview({
        type: 'file',
        name: file.name
      });
    }
  };
  
  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFilePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleMessageSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      
      const messageText = newMessage.trim();
      if (!messageText && !selectedFile) return;
      
      setNewMessage('');
      setIsLoading(true);
      
      const dataObject = {
        message: messageText,
        token: TOKEN,
        sessionId: sessionId,
        companyId: companyId
      };
      
      const formData = new FormData();
      
      formData.append('message', messageText);
      formData.append('token', TOKEN);
      formData.append('sessionId', sessionId);
      formData.append('companyId', companyId);
      
      if (selectedFile) {
        formData.append('medias', selectedFile);
      }
      
      setMessages(prev => [...prev]);
      
      setSelectedFile(null);
      setFilePreview(null);
      
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      
      const response = await api.post('/api/messages/webchat', formData);
      
      if (response.data && response.data.ticket && response.data.ticket.id) {
        const newTicketId = response.data.ticket.id;
        setTicketId(newTicketId);
        localStorage.setItem('venttify_ticket_id', newTicketId);
        
        if (response.data.ticket.companyId) {
          setCompanyId(response.data.ticket.companyId);
          localStorage.setItem('venttify_company_id', response.data.ticket.companyId);
        }
      }
      
      if (!hasAgentReplied) {
        setTimeout(() => {
          if (!messages.some(m => m.id === waitingMessage.id)) {
            setMessages(prev => [...prev, waitingMessage]);
          }
        }, 1000);
      }
      
    } catch (error) {
      console.error('Error al enviar mensaje:', error);
      
      const errorMessage = {
        id: Date.now() + 1,
        text: 'Hubo un error al enviar tu mensaje. Por favor, intenta nuevamente.',
        sender: 'bot',
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Renderizar contenido multimedia si existe
  const renderMediaContent = (message) => {
    // Para los mensajes temporales con vistas previas locales
    if (message.mediaPreview) {
      if (message.mediaPreview.type === 'image') {
        return (
          <img 
            src={message.mediaPreview.url} 
            alt="Imagen" 
            className={classes.mediaPreview}
          />
        );
      } else if (message.mediaPreview.type === 'video') {
        return <video src={message.mediaPreview.url} controls className={classes.mediaPreview} />;
      } else if (message.mediaPreview.type === 'audio') {
        return <audio src={message.mediaPreview.url} controls className={classes.mediaPreview} />;
      } else if (message.mediaPreview.type === 'file') {
        return (
          <div className={classes.filePreview}>
            <AttachFile className={classes.fileIcon} />
            <span>{message.mediaPreview.name}</span>
          </div>
        );
      }
      return null;
    }
    
    // Para mensajes recibidos del servidor con MediaUrl
    if (!message.mediaUrl) return null;
    
    // Verificar si la URL es completa o solo un nombre de archivo
    // Si la URL ya incluye el token SAS o es una URL completa, no necesitamos modificarla
    const isFullUrl = 
      message.mediaUrl.startsWith('http') || 
      message.mediaUrl.includes('?') || // Incluye parámetros de consulta (como un token SAS)
      message.mediaUrl.includes('blob.core.windows.net');
    
    // Obtener la URL correcta para mostrar la imagen
    // Si ya es una URL completa, la usamos directamente
    const mediaUrl = isFullUrl 
      ? message.mediaUrl 
      : `https://venttifystorage.blob.core.windows.net/venttify-images/${message.mediaUrl}`;
    
    // Extraer el nombre del archivo de la URL si no hay mediaName
    let displayName = message.mediaName;
    
    // Si no hay mediaName pero hay body y hay mediaUrl, el body podría contener el nombre del archivo
    if (!displayName && message.mediaUrl && message.text) {
      // Verificar si el body no contiene etiquetas HTML o saltos de línea,
      // lo que sugeriría que es solo el nombre del archivo
      const isPlainFileName = !message.text.includes('<') && !message.text.includes('\n');
      
      // Solo marcar como usado si NO es una imagen - para imágenes queremos mostrar el texto también
      if (isPlainFileName && (!message.mediaType || !message.mediaType.startsWith('image'))) {
        displayName = message.text;
        // Marcar que el texto ya se ha utilizado para mostrar el nombre del archivo
        message.textUsedAsFileName = true;
      } else if (isPlainFileName && !displayName) {
        // Para imágenes, usamos el nombre pero no marcamos como usado
        displayName = message.text;
      }
    }
    
    // Si todavía no tenemos un nombre, intentar extraerlo de la URL
    if (!displayName && message.mediaUrl) {
      // Intentar extraer el nombre del archivo de la URL
      const urlParts = message.mediaUrl.split('/');
      const fileNameWithParams = urlParts[urlParts.length - 1];
      // Eliminar parámetros de consulta si existen
      displayName = fileNameWithParams.split('?')[0];
      // Decodificar el nombre en caso de que tenga caracteres especiales
      try {
        displayName = decodeURIComponent(displayName);
      } catch (e) {
        console.error("Error decodificando nombre de archivo:", e);
      }
    }
    
    if (message.mediaType && message.mediaType.startsWith('image')) {
      return (
        <img 
          src={mediaUrl} 
          alt={displayName || "Imagen"} 
          className={classes.mediaPreview} 
          onClick={() => window.open(mediaUrl, '_blank')}
        />
      );
    } else if (message.mediaType && message.mediaType.startsWith('audio')) {
      return <audio src={mediaUrl} controls className={classes.mediaPreview} />;
    } else if (message.mediaType && message.mediaType.startsWith('video')) {
      return <video src={mediaUrl} controls className={classes.mediaPreview} />;
    } else if (message.mediaUrl) {
      // Para archivos como PDF, Excel, Word, etc.
      let fileIcon = <AttachFile className={classes.fileIcon} />;
      
      // Determinar el tipo de archivo para mostrar información más relevante
      let fileType = "";
      if (message.mediaType) {
        if (message.mediaType.includes('pdf')) {
          fileType = "PDF";
        } else if (message.mediaType.includes('excel') || message.mediaType.includes('sheet') || (message.mediaUrl && message.mediaUrl.includes('.xls'))) {
          fileType = "Excel";
        } else if (message.mediaType.includes('word') || message.mediaType.includes('document')) {
          fileType = "Word";
        } else if (message.mediaType.includes('text')) {
          fileType = "Texto";
        } else {
          // Usar la extensión del tipo MIME si está disponible
          fileType = message.mediaType.split('/')[1]?.toUpperCase() || "Archivo";
        }
      }
      
      // Prioriza el nombre original del archivo si está disponible o proporciona una alternativa legible
      const fileName = displayName || `${fileType} ${new Date().toLocaleTimeString()}`;
      
      return (
        <div onClick={() => window.open(mediaUrl, '_blank')} className={classes.filePreview}>
          {fileIcon}
          <Typography 
            variant="body2" 
            style={{ 
              wordBreak: 'break-word', 
              maxWidth: '100%', 
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {fileName}
          </Typography>
        </div>
      );
    }
    
    return null;
  };
  
  /* Renderiza la vista previa del archivo seleccionado */
  const renderFilePreview = () => {
    if (!selectedFile) return null;

    const isImage = selectedFile.type.startsWith('image/');
    const isVideo = selectedFile.type.startsWith('video/');
    const isAudio = selectedFile.type.startsWith('audio/');
    
    return (
      <div className={classes.mediaPreviewContainer}>
        <CancelIcon 
          className={classes.mediaPreviewDelete} 
          onClick={handleRemoveFile} 
        />
        {isImage && (
          <img 
            src={filePreview} 
            alt="Preview" 
            style={{ maxWidth: '100%', maxHeight: 150, objectFit: 'contain' }} 
          />
        )}
        {isVideo && (
          <video 
            src={filePreview}
            controls 
            style={{ maxWidth: '100%', maxHeight: 150 }} 
          />
        )}
        {isAudio && (
          <audio 
            src={filePreview}
            controls 
            style={{ width: '100%' }} 
          />
        )}
        {!isImage && !isVideo && !isAudio && (
          <div className={classes.filePreview}>
            <AttachFile className={classes.fileIcon} />
            <Typography variant="body2">{selectedFile.name}</Typography>
          </div>
        )}
      </div>
    );
  };
  
  return (
    <>
      {!isOpen && (
        <IconButton
          className={classes.widgetButton}
          onClick={toggleChat}
          aria-label="Abrir chat"
        >
          <div className={classes.widgetButtonWrapper}>
            <ChatIcon />
            {unreadMessages > 0 && (
              <div className={classes.notificationBadge}>
                {unreadMessages > 9 ? '9+' : unreadMessages}
              </div>
            )}
          </div>
        </IconButton>
      )}
      
      <Box className={classes.chatContainer}>
        <Box className={classes.chatHeader}>
          <div className={classes.chatTitle}>Soporte Venttify</div>
          <IconButton size="small" onClick={toggleChat} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.chatBody}>
          <Box className={classes.messagesContainer}>
            {messages.map((message) => (
              <Box
                key={message.id}
                className={`${classes.message} ${
                  message.sender === 'user' ? classes.userMessage : classes.botMessage
                }`}
                style={{
                  alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                }}
              >
                <div className={classes.messageContent}>
                  {renderMediaContent(message)}
                  {message.mediaUrl && message.mediaType && message.mediaType.startsWith('image') 
                    ? null 
                    : message.text}
                </div>
              </Box>
            ))}
            <div ref={messagesEndRef} />
          </Box>
        </Box>
        
        <Box className={classes.chatFooter}>
          <form onSubmit={handleMessageSubmit}>
            <div className={classes.inputToolbar}>
              <input 
                ref={fileInputRef}
                id="file-input"
                type="file"
                className={classes.fileInput}
                onChange={handleFileChange}
                accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.txt,.xls,.xlsx,.csv,.ppt,.pptx"
              />
                            <IconButton
                className={classes.emojiButton}
                onClick={handleOpenEmoji}
                size="small"
                disabled={isLoading}
              >
                <EmoteIcon />
              </IconButton>
              <IconButton 
                className={classes.attachButton}
                onClick={handleFileClick}
                size="small"
                disabled={isLoading}
              >
                <AttachFile />
              </IconButton>
              <div style={{ position: 'relative', flexGrow: 1, display: 'flex' }}>
                <input
                  className={classes.messageInput}
                  value={newMessage}
                  onChange={handleMessageChange}
                  placeholder={isLoading ? "Enviando..." : "Escribe un mensaje..."}
                  disabled={isLoading}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleMessageSubmit(e);
                    }
                  }}
                />
                <IconButton
                  className={classes.sendButton}
                  onClick={handleMessageSubmit}
                  disabled={isLoading || (!newMessage.trim() && !selectedFile)}
                  style={{ marginLeft: '5px' }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <SendIcon />
                  )}
                </IconButton>
              </div>
            </div>
          </form>
          {renderFilePreview()}
          {showEmoji && (
            <div className={classes.emojiPickerContainer}>
              <EmojiPicker
                lazyLoadEmojis
                width={300}
                height={400}
                onEmojiClick={handleAddEmoji}
                previewConfig={{ showPreview: false }}
              />
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ChatWidget; 