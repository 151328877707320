const GroupIcon = ({
  fill = "none",
  width = "24",
  height = "24",
  strokeWidth = "1.8",
}) => {
  return (
<svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5001 5.37C13.4551 5.3625 13.4026 5.3625 13.3576 5.37C12.3226 5.3325 11.4976 4.485 11.4976 3.435C11.4976 2.3625 12.3601 1.5 13.4326 1.5C14.5051 1.5 15.3676 2.37 15.3676 3.435C15.3601 4.485 14.5351 5.3325 13.5001 5.37Z" stroke={fill} stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.7276 10.8301C13.7551 11.0026 14.8876 10.8226 15.6826 10.2901C16.7401 9.58512 16.7401 8.43012 15.6826 7.72512C14.8801 7.19262 13.7326 7.01262 12.7051 7.19262" stroke={fill} stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.47736 5.37C4.52236 5.3625 4.57486 5.3625 4.61986 5.37C5.65486 5.3325 6.47986 4.485 6.47986 3.435C6.47986 2.3625 5.61736 1.5 4.54486 1.5C3.47236 1.5 2.60986 2.37 2.60986 3.435C2.61736 4.485 3.44236 5.3325 4.47736 5.37Z" stroke={fill} stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.25008 10.8301C4.22258 11.0026 3.09008 10.8226 2.29508 10.2901C1.23758 9.58512 1.23758 8.43012 2.29508 7.72512C3.09758 7.19262 4.24508 7.01262 5.27258 7.19262" stroke={fill} stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.00006 10.9716C8.95506 10.9641 8.90256 10.9641 8.85756 10.9716C7.82256 10.9341 6.99756 10.0866 6.99756 9.03656C6.99756 7.96406 7.86006 7.10156 8.93256 7.10156C10.0051 7.10156 10.8676 7.97156 10.8676 9.03656C10.8601 10.0866 10.0351 10.9416 9.00006 10.9716Z" stroke={fill} stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.81754 13.3345C5.76004 14.0395 5.76004 15.1945 6.81754 15.8995C8.01754 16.702 9.98254 16.702 11.1825 15.8995C12.24 15.1945 12.24 14.0395 11.1825 13.3345C9.99004 12.5395 8.01754 12.5395 6.81754 13.3345Z" stroke={fill} stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};
export default GroupIcon;
