import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import backgroundChat from '../../assets/img/backgroundChat.png';

import TicketsManager from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";
import { i18n } from "../../translate/i18n";
import { TicketsContext } from "../../context/Tickets/TicketsContext";

const useStyles = makeStyles(theme => ({
	chatContainer: {
		flex: 1,
		paddingLeft: theme.spacing(1),
		paddingTop: theme.spacing(3.5),
		height: `calc(100% - 78px)`,
		overflowY: "hidden",
		backgroundImage: `url(${backgroundChat})`,
		[theme.breakpoints.down(1044)]: {
			paddingTop: theme.spacing(0.25),
		}
	},

	chatPapper: {
		display: "flex",
		height: "100%",
	},
	contactsWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
		[theme.breakpoints.down(1044)]: {
			width: '100%',
			flex: 1,
			display: props => props.ticketId ? 'none' : 'flex',
			flexBasis: '100%',
			maxWidth: '100%',
		}
	},
	messagesWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		[theme.breakpoints.down(1044)]: {
			display: props => props.ticketId ? 'flex' : 'none',
			flexBasis: '100%',
			maxWidth: '100%',
		}
	},
	welcomeMsg: {
		background: theme.palette.total,
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
		borderRadius: 15,
	},
	mobileWrapper: {
		width: '100%',
		height: '100%',
	},
	gridContainer: {
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: 'repeat(24, 1fr)',
		}
	},
	ticketManagerMd: {
		[theme.breakpoints.down('md')]: {
			flexBasis: props => props.ticketId ? '36%' : '100%',  
			maxWidth: props => props.ticketId ? '36%' : '100%',
		}
	},
	ticketMd: {
		[theme.breakpoints.down('md')]: {
			flexBasis: '64%', 
			maxWidth: '64%',
			display: props => props.ticketId ? 'block' : 'none'
		}
	}
}));

const TicketsCustom = () => {
	const { ticketId } = useParams();
	const history = useHistory();
	const classes = useStyles({ ticketId });
	const { ticketsList } = useContext(TicketsContext);

	useEffect(() => {
		if (ticketId) {
			const ticketExists = ticketsList.some(t => t.uuid === ticketId);
			if (!ticketExists) {
				history.push("/tickets");
			}
		}
	}, [ticketId, ticketsList, history]);

	return (
		<div className={classes.chatContainer}>
			<div className={classes.chatPapper}>
				<Grid container spacing={0}>
					<Grid 
						item 
						xl={4} 
						lg={5} 
						md={4}
						className={classes.contactsWrapper}
					>
						<TicketsManager />
					</Grid>
					<Grid 
						item 
						xl={8} 
						lg={7} 
						md={8}
						className={classes.messagesWrapper}
					>
						{ticketId && ticketsList.some(t => t.uuid === ticketId) ? (
							<Ticket />
						) : (
							<Paper square variant="outlined" className={classes.welcomeMsg}>
								<span>{i18n.t("chat.noTicketMessage")}</span>
							</Paper>
						)}
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default TicketsCustom;
