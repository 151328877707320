import React, { useState, useRef, useEffect, useContext, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import useSound from "use-sound";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

import TicketListItem from "../TicketListItem";
import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";
import alertSound from "../../assets/sound.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";
import NotificationBellIcon from "../../assets/icon/NotificationBellIcon";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    overflowY: "auto",
    maxHeight: 350,
    ...theme.scrollbarStyles,
  },
  popoverPaper: {
    width: "100%",
    maxWidth: 350,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 270,
    },
  },
  noShadow: {
    boxShadow: "none !important",
  },
  titleContainer: {
    backgroundColor: theme.palette.tabHeaderBackground,
    padding: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: '1.1rem',
    fontWeight: 500
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }
}));

const NotificationsPopOver = () => {
  const classes = useStyles();

  const history = useHistory();
  const { user } = useContext(AuthContext);
  const ticketIdUrl = +history.location.pathname?.split("/")[2];
  const ticketIdRef = useRef(ticketIdUrl);
  const anchorEl = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { profile, queues } = user;
  const [invisible, setInvisible] = useState(true);
  const [, setDesktopNotifications] = useState([]);
  const [transferNotifications, setTransferNotifications] = useState([]);

  const { tickets } = useTickets({ 
    withUnreadMessages: "true",
    userId: user?.id
  });
  const [play] = useSound(alertSound);
  const soundAlertRef = useRef();

  const historyRef = useRef(history);

	useEffect(() => {
		soundAlertRef.current = play;

		if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}
	}, [play]);

	useEffect(() => {
		setNotifications(tickets);
	}, [tickets]);

	useEffect(() => {
		ticketIdRef.current = ticketIdUrl;
	}, [ticketIdUrl]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");
    const socket = socketConnection({ companyId });

    console.log(companyId, "socket")
    const queueIds = queues.map((q) => q.id);

    // Mantener el listener existente para tickets
    socket.on(`company-${companyId}-ticket`, (data) => {
      console.log("Socket event received:", data);
      
      if (data.action === "update") {
        setNotifications(prevState => {
          if (data.ticket.userId === user?.id) {
            if (data.ticket.unreadMessages > 0) {
              console.log("Actualizando ticket con mensajes no leídos:", data.ticket);
              const filteredState = prevState.filter(t => t.id !== data.ticket.id);
              return [data.ticket, ...filteredState];
            } else {
              console.log("Removiendo ticket sin mensajes no leídos:", data.ticket.id);
              return prevState.filter(t => t.id !== data.ticket.id);
            }
          }
          return prevState;
        });
      } else if (data.action === "delete") {
        console.log("Eliminando ticket:", data.ticketId);
        setNotifications(prevState => 
          prevState.filter(t => t.id !== data.ticketId)
        );
      } else if (data.action === "RESET_UNREAD") {
        setNotifications(prevState => 
          prevState.filter(t => t.id !== data.ticketId)
        );
      }

      setDesktopNotifications(prevState => {
        const notificationIndex = prevState.findIndex(
          n => n.tag === String(data.ticketId)
        );
        if (notificationIndex !== -1) {
          prevState[notificationIndex].close();
          const newState = [...prevState];
          newState.splice(notificationIndex, 1);
          return newState;
        }
        return prevState;
      });
    });

    // Mantener el listener para mensajes de la aplicación
    socket.on(`company-${companyId}-appMessage`, (data) => {
      console.log("datapopover", data)
      if (
        data.action === "create" &&
        !data.message.read &&
        data.ticket.userId === user?.id
      ) {
        setNotifications(prevState => {
          const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
          if (ticketIndex !== -1) {
            prevState[ticketIndex] = data.ticket;
            return [...prevState];
          }
          return [data.ticket, ...prevState];
        });

        const shouldNotNotificate =
          (data.message.ticketId === ticketIdRef.current &&
            document.visibilityState === "visible");

        if (shouldNotNotificate) return;

        handleNotifications(data);
      }
    });

    // Listener para notificaciones de transferencia
    socket.on(`company-${companyId}-notification`, (data) => {
      if (data.action === "create" && data.userId === parseInt(userId)) {
        if (data.record.type === "transfer_notification") {
          // Reproducir sonido de notificación
          soundAlertRef.current();
          
          // Mostrar notificación del sistema
          if (Notification.permission === "granted") {
            new Notification("Nuevo ticket transferido", {
              body: data.record.text,
              icon: "/logo192.png"
            });
          }

          setTransferNotifications(prevState => [data.record, ...prevState]);
          setInvisible(false);
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user, profile, queues]);

  const handleNotifications = (data) => {
    const { message, contact, ticket } = data;

    const options = {
      body: `${message.body} - ${format(new Date(), "HH:mm")}`,
      icon: contact.profilePicUrl,
      tag: ticket.id,
      renotify: true,
    };

    const notification = new Notification(
      `${i18n.t("tickets.notification.message")} ${contact.name}`,
      options
    );

    notification.onclick = (e) => {
      e.preventDefault();
      window.focus();
      historyRef.current.push(`/tickets/${ticket.uuid}`);
    };

    setDesktopNotifications((prevState) => {
      const notfiticationIndex = prevState.findIndex(
        (n) => n.tag === notification.tag
      );
      if (notfiticationIndex !== -1) {
        prevState[notfiticationIndex] = notification;
        return [...prevState];
      }
      return [notification, ...prevState];
    });

    soundAlertRef.current();
  };

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const NotificationTicket = ({ children }) => {
    return <div onClick={handleClickAway}>{children}</div>;
  };

  // Combinar las notificaciones de tickets y transferencias
  const allNotifications = useMemo(() => {
    return [...transferNotifications, ...notifications].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }, [transferNotifications, notifications]);

  return (
    <>
      <IconButton
        onClick={handleClick}
        ref={anchorEl}
        aria-label="Mostrar Notificaciones"
        variant="contained"
      >
        <Badge 
          badgeContent={allNotifications.length} 
          classes={{ badge: classes.badge }}
          invisible={invisible}
          max={99}
        >
          <NotificationBellIcon />
        </Badge>
      </IconButton>
      <Popover
        disableScrollLock
        open={isOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: classes.popoverPaper }}
        onClose={handleClickAway}
      >
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>
            Notificaciones
          </Typography>
        </div>
        <List dense className={classes.tabContainer}>
          {allNotifications.length === 0 ? (
            <ListItem>
              <ListItemText>{i18n.t("notifications.noTickets")}</ListItemText>
            </ListItem>
          ) : (
            allNotifications.map((item) => (
              <NotificationTicket key={item.id}>
                {item.type === "transfer_notification" ? (
                  <ListItem button>
                    <ListItemText
                      primary={item.title}
                      secondary={item.text}
                    />
                  </ListItem>
                ) : (
                  <TicketListItem ticket={item} />
                )}
              </NotificationTicket>
            ))
          )}
        </List>
      </Popover>
    </>
  );
};

export default NotificationsPopOver;
