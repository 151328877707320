import React, { useState, useEffect, useContext, useCallback, useReducer, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';
import TagModal from "../../components/TagModal";
import { Button, IconButton, useTheme, Tooltip } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ConfirmationModal from "../../components/ConfirmationModal";
import EditIcon from "@material-ui/icons/Edit";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import AddIcon from "../../assets/icon/AddIcon";
import DownArrow from "../../assets/icon/DownArrow";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import { socketConnection } from "../../services/socket";


const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_TAGS": {
      const tags = action.payload;
      return tags.sort((a, b) => a.order - b.order);
    }
    case "UPDATE_TAGS": {
      const tag = action.payload;
      const tagIndex = state.findIndex((s) => s.id === tag.id);

      if (tagIndex !== -1) {
        return state.map((t) => (t.id === tag.id ? tag : t));
      } else {
        return [...state,tag];
      }
    }

    case "DELETE_TAG": {
      const tagId = action.payload;
      return state.filter((tag) => tag.id !== tagId);
    }

    case "RESET":
      return [];

    default:
      return state;
  }
};


const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  tableContainer: {
    width: "100%",
    maxWidth: "90%",
    height: "calc(100vh - 100px)",
    ...theme.scrollbarStyles,
    "& .react-trello-lane": {
      minWidth: "320px !important",
      width: "320px !important",
      backgroundColor: `${theme.palette.tabHeaderBackground} !important`,
      borderRadius: "8px !important",
      border: "1px solid rgba(0, 0, 0, 0.05) !important",
      margin: "0 4px !important",
      padding: "8px !important",
      maxHeight: "calc(100vh - 200px) !important",
      overflowY: "auto !important",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& .react-trello-card": {
      minWidth: "290px !important",
      width: "290px !important",
      background: `${theme.palette.tabHeaderBackground} !important`,
      borderRadius: "8px !important",
      padding: "8px !important",
      border: "none !important",
      marginBottom: "8px !important",
      boxShadow: "none !important",
      borderTop: "none !important",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
        cursor: "pointer !important",
      },
      "&:not(:first-child)": {
        borderTop: "none !important",
      },
      "& > div": {
        padding: "0 !important",
        borderTop: "none !important",
        "&:before": {
          display: "none !important",
        },
        "&:after": {
          display: "none !important",
        }
      },
      "&:before": {
        display: "none !important",
      },
      "&:after": {
        display: "none !important",
      }
    },
    "& .react-trello-board": {
      backgroundColor: "transparent !important",
      marginTop: '-20px',
    },
  },
  buttonContainer: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  cardContent: {
    backgroundColor: theme.palette.tabHeaderBackground,
    borderRadius: "8px",
    marginBottom: "12px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    width: "100%",
    minWidth: "280px",
  },
  ticketNumber: {
    position: "absolute",
    top: "30px",
    right: "16px",
    fontSize: "13px",
    color: theme.palette.text.secondary,
  },
  userInfoContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "8px",
    position: "relative",
    paddingRight: "60px",
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    marginRight: "12px",
    backgroundColor: "#F0F0F0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    color: "#666",
    flexShrink: 0,
  },
  userName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    marginBottom: "2px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userNumber: {
    fontSize: "13px",
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  messageContainer: {
    fontSize: "13px",
    color: theme.palette.text.secondary,
    marginLeft: "2px",
    marginBottom: "12px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  viewButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "15px",
    padding: "6px 16px",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "600",
    boxShadow: "none",
    minWidth: "auto",
    alignSelf: "flex-start",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}20`,
      borderColor: theme.palette.primary.main,
    },
  },
  laneHeader: {
    padding: "4px",
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
  },
  colorIndicator: {
    width: "12px",
    height: "12px",
    borderRadius: "3px",
    marginRight: "8px",
  },
  laneHeaderContent: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "4px 8px",
    borderRadius: "10px",
  },
  laneTitle: {
    fontSize: "15px",
    fontWeight: "500",
    color: theme.palette.textPrimary,
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100px",
    cursor: "default",
  },
  laneCount: {
    fontSize: "12px",
    padding: "2px 8px",
    borderRadius: "12px",
    color: theme.palette.text.secondary ,
  },
  iconContainer: {
    display: "flex",
    gap: "4px",
    marginLeft: "50px",
    flexShrink: 0,
  },
  buttonNew: {
    borderColor: "#FFB629",
    color: "#FFB629",
    borderRadius: "20px",
    padding: "6px 16px",
    textTransform: "none",
    "&:hover": {
      borderColor: "#FFB629",
      backgroundColor: "rgba(255, 182, 41, 0.1)",
    },
  },
  boardContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '20px',
    width: '100%',
    height: 'calc(100vh - 160px)',
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginBottom: '20px',
    cursor: 'grab',
    ...theme.scrollbarStyles,
    '&.grabbing': {
      cursor: 'grabbing',
    },
    '&::-webkit-scrollbar': {
      height: '12px',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      minWidth: 'fit-content',
    },
    '& .smooth-dnd-container.horizontal': {
      minWidth: 'fit-content',
    },
    '& *': {
      ...theme.scrollbarStyles,
    }
  },
  addLaneButton: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    backgroundColor: theme.palette.tabHeaderBackground,
    border: "none",
    cursor: "pointer",
    padding: "10px",
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    }
  },
  addLaneIcon: {
    width: "24px",
    height: "24px",
  },
}));
const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [refreshKey, setRefreshKey] = useState(0);
  const [file, setFile] = useState({ lanes: [] });
  const [rtags, dispatch] = useReducer(reducer, []);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingTag, setDeletingTag] = useState(null);
  const [tickets, setTickets] = useState([]);
  const { user } = useContext(AuthContext);
  const { queues } = user;
  const jsonString = queues.map(queue => queue.UserQueue.queueId);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const boardRef = useRef(null);

  useEffect(() => {
    setRefreshKey(prev => prev + 1);
    popularCards();
  }, [theme.mode]);

  useEffect(() => {
    popularCards(); 
  }, [tickets, rtags]);

  const addOpacityToColor = (hexColor, opacity) => {
    const rgbaColor = hexColor
      .replace("#", "")
      .match(/.{1,2}/g)
      .map((x) => parseInt(x, 16));
    return `rgba(${rgbaColor[0]}, ${rgbaColor[1]}, ${rgbaColor[2]}, ${opacity})`;
  };


  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      console.log(response, "response");
      const fetchedTags = response.data.lista || [];
      const defaultLaneOrder = response.data.defaultLaneOrder || 0;
      dispatch({ type: "LOAD_TAGS", payload: fetchedTags });
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);


  const fetchTickets = async (jsonString) => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };

  const handleLaneMove = async (removedIndex, addedIndex, payload) => {
    // Prevenir cualquier movimiento que involucre la lane "add-lane"
    if (payload.id === "add-lane" || 
        removedIndex === file.lanes.length - 1 || 
        addedIndex === file.lanes.length - 1) {
      // Forzar actualización para revertir el movimiento visual
      popularCards();
      return;
    }

    console.log("Se movió una etiqueta");

    const tagLanes = rtags.filter(tag => tag.id !== 'lane0');

    // Ajustar índices considerando la lane0 y la lane de agregar
    const adjustedRemovedIndex = removedIndex - 1;
    const adjustedAddedIndex = addedIndex - 1;

    const newTags = Array.from(tagLanes);
    const [reorderedTag] = newTags.splice(adjustedRemovedIndex, 1);
    newTags.splice(adjustedAddedIndex, 0, reorderedTag);

    const updatedTags = newTags.map((tag, index) => ({
      ...tag,
      order: index,
      id: tag.id
    }));
  
    console.log("Etiquetas actualizadas:", updatedTags);
    dispatch({ type: "LOAD_TAGS", payload: updatedTags });
  
    try {
      await api.put("/tags/reorder", { tags: updatedTags });
    } catch (error) {
      console.error("Error al actualizar el orden de las etiquetas:", error);
      toast.error("No se pudo actualizar el orden de las etiquetas");
      fetchTags();
    }
  };

  const popularCards = () => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);

    const lanes = [
      {
        id: "lane0",
        title: (
          <div className={classes.laneHeader}>
            <div className={classes.laneHeaderContent} style={{backgroundColor: addOpacityToColor("#999999", 0.2)}}>
              <div className={classes.colorIndicator} style={{ backgroundColor: "#999999" }} />
              <span className={classes.laneTitle}>
                {i18n.t("tagsKanban.laneDefault")}
              </span>
              <span className={classes.laneCount}>{filteredTickets.length}</span>
            </div>
          </div>
        ),
        style: {
          width: "100%",
          padding: "8px",
        },
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          draggable: true,
          title: "",
          description: (
            <div>
              <div className={classes.ticketNumber}>
                Ticket {ticket.id}
              </div>
              <div className={classes.userInfoContainer}>
                <div className={classes.avatar}>
                  {ticket.contact.name.charAt(0).toUpperCase()}
                </div>
                <div>
                  <div className={classes.userName}>{ticket.contact.name}</div>
                  <div className={classes.userNumber}>{ticket.contact.number}</div>
                </div>
              </div>
              <div className={classes.messageContainer}>
                {ticket.lastMessage}
              </div>
              <Button
                variant="outlined"
                className={classes.viewButton}
                onClick={() => handleCardClick(ticket.uuid)}
              >
                Ver ticket
              </Button>
            </div>
          ),
        })),
      },
      ...rtags.sort((a, b) => a.order - b.order)
        .map(tag => {
          const filteredTickets = tickets.filter(ticket => {
            const tagIds = ticket.tags.map(tag => tag.id);
            return tagIds.includes(tag.id);
          });

          return {
            id: tag.id.toString(),
            draggable: true,
            title: (
              <div className={classes.laneHeader}>
                <div className={classes.laneHeaderContent} style={{backgroundColor: addOpacityToColor(tag.color, 0.2)}}>
                  <div className={classes.colorIndicator} style={{ backgroundColor: tag.color }} />
                  <Tooltip title={tag.name} placement="top" arrow>
                    <span className={classes.laneTitle}>
                      {tag.name}
                    </span>
                  </Tooltip>
                  <span className={classes.laneCount}>{filteredTickets.length}</span>
                </div>
                <div className={classes.iconContainer}>
                  <IconButton size="small" onClick={() => handleEditTag(tag)}>
                    <PencilEditIcon fill="#7F7F7F" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDeleteClick(tag)}>
                    <TrashIcon fill="#7F7F7F" />
                  </IconButton>
                </div>
              </div>
            ),
            style: {
              width: "100%",
              padding: "8px",
            },
            cards: filteredTickets.map(ticket => ({
              id: ticket.id.toString(),
              draggable: true,
              title: "",
              description: (
                <div>
                  <div className={classes.ticketNumber}>
                    Ticket {ticket.id}
                  </div>
                  <div className={classes.userInfoContainer}>
                    <div className={classes.avatar}>
                      {ticket.contact.name.charAt(0).toUpperCase()}
                    </div>
                    <div>
                      <div className={classes.userName}>{ticket.contact.name}</div>
                      <div className={classes.userNumber}>{ticket.contact.number}</div>
                    </div>
                  </div>
                  <div className={classes.messageContainer}>
                    {ticket.lastMessage}
                  </div>
                  <Button
                    variant="outlined"
                    className={classes.viewButton}
                    onClick={() => handleCardClick(ticket.uuid)}
                  >
                    Ver ticket
                  </Button>
                </div>
              ),
            })),
          };
        }),
      {
        id: "add-lane",
        draggable: false,
        title: (
          <div 
            className={classes.addLaneButton}
            onClick={handleOpenTagModal}
          >
            <AddIcon fill="#7F7F7F" className={classes.addLaneIcon} />
            <span>Agregar etapa</span>
          </div>
        ),
        cards: [],
      }
    ];

    setFile({ lanes });
  };

  useEffect(() => {
    popularCards();
  }, [rtags, tickets]);

  const handleCardClick = (uuid) => {
    // Solo navegamos al ticket, la vista de ticket se encargará de obtener los datos actualizados
    history.push('/tickets/' + uuid);
  };

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      await api.delete(`/ticket-tags/${targetLaneId}`);
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);

      // Actualizar la vista del kanban
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      toast.error("Error al mover la etiqueta");
      fetchTickets(jsonString);
    }
  };

  const handleDeleteClick = (tag) => {
    setDeletingTag(tag);
    setConfirmModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      dispatch({ type: "DELETE_TAG", payload: tagId });
      toast.success(i18n.t("tags.toasts.deleted"));
    } catch (err) {
      console.log(err);
      toast.error("Error al eliminar la etiqueta");
    }
    setDeletingTag(null);
    setConfirmModalOpen(false);
  };

  const handleCloseTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(false);
  };

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setTagModalOpen(true);
  };

  const primaryButtons = [
    { label: "Nueva etapa", onClick: handleOpenTagModal, icon: <AddIcon fill='#000' /> },
  ]

  const handleMouseDown = (e) => {
    // Verificar si el click fue en una card o lane
    if (e.target.closest('.react-trello-card') || 
        e.target.closest('.react-trello-lane') || 
        e.target.closest('button') ||
        e.target.closest('.smooth-dnd-draggable-wrapper')) {
      return;
    }
    
    setIsDragging(true);
    setStartX(e.pageX - boardRef.current.offsetLeft);
    setScrollLeft(boardRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    // Verificar si el mouse está sobre una card o lane
    if (e.target.closest('.react-trello-card') || 
        e.target.closest('.react-trello-lane') || 
        e.target.closest('button') ||
        e.target.closest('.smooth-dnd-draggable-wrapper')) {
      setIsDragging(false);
      return;
    }

    e.preventDefault();
    const x = e.pageX - boardRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    boardRef.current.scrollLeft = scrollLeft - walk;
  };

  const boardContainerStyle = {
    cursor: isDragging ? 'grabbing' : 'grab'
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.tableContainer}>
        <HeaderManager
          title={i18n.t("kanban.title")}
          primaryButtons={primaryButtons}
        />
        <TagModal
          open={tagModalOpen}
          onClose={handleCloseTagModal}
          aria-labelledby="form-dialog-title"
          tagId={selectedTag && selectedTag.id}
          kanban={1}
          dispatch={dispatch}
        />
        <ConfirmationModal
          title={i18n.t("tagsKanban.confirmationModal.deleteTitle")}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={() => deletingTag && handleDeleteTag(deletingTag.id)}
        >
          {i18n.t("tagsKanban.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <div 
          ref={boardRef}
          className={`${classes.boardContainer} ${isDragging ? 'grabbing' : ''}`}
          style={boardContainerStyle}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          <Board
            data={file}
            handleLaneDragEnd={handleLaneMove}
            onCardMoveAcrossLanes={handleCardMove}
            draggable
            laneDraggable
            hideCardDeleteIcon={true}
            style={{ 
              background: "transparent",
              width: "100%",
              height: "100%",
            }}
            key={refreshKey}
          />
        </div>
      </div>
    </div>
  );
};

export default Kanban;