import React, { useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";

const TicketsContext = createContext();

const TicketsContextProvider = ({ children }) => {
	const [currentTicket, setCurrentTicket] = useState({ id: null, code: null });
	const [ticketsList, setTicketsList] = useState([]);
	const [tags, setTags] = useState([]);
	const [loadingTags, setLoadingTags] = useState(false);
	const [lastTagsFetch, setLastTagsFetch] = useState(0);
	const [messagesCache, setMessagesCache] = useState({});
	const [loadingMessages, setLoadingMessages] = useState({});
	const [messagesCacheExpiry, setMessagesCacheExpiry] = useState({});
	const [user, setUser] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (currentTicket.uuid) {
			history.push(`/tickets/${currentTicket.uuid}`);
		}
	}, [currentTicket, history]);

	const fetchTags = async (force = false) => {
		// Si ya hay tags y no es forzado, no hacer nada
		if (tags.length > 0 && !force) return;

		// Si pasó menos de 1 minuto desde la última petición y no es forzado, no hacer nada
		const now = Date.now();
		if (!force && (now - lastTagsFetch) < 60000) return;

		try {
			setLoadingTags(true);
			const { data } = await api.get(`/tags/list`);
			setTags(data);
			setLastTagsFetch(now);
		} catch (err) {
			toastError(err);
		} finally {
			setLoadingTags(false);
		}
	};

	// Cargar tags inicialmente
	useEffect(() => {
		fetchTags();
	}, []);

	// Actualizar loadingTags cuando se modifiquen los tags
	useEffect(() => {
		if (tags.length > 0) {
			setLoadingTags(false);
		}
	}, [tags]);

	const createTag = async (tagData) => {
		try {
			const { data } = await api.post(`/tags`, tagData);
			setTags(prevTags => [...prevTags, data]);
			return data;
		} catch (err) {
			toastError(err);
			return null;
		}
	};

	const syncTicketTags = async (ticketId, ticketTags) => {
		try {
			const { data } = await api.post(`/tags/sync`, {
				ticketId,
				tags: ticketTags
			});
			return data;
		} catch (err) {
			toastError(err);
			return null;
		}
	};

	const isCacheValid = (ticketId) => {
		if (!messagesCacheExpiry[ticketId]) return false;
		return messagesCacheExpiry[ticketId] > Date.now();
	};

	const fetchTicketMessages = async (ticketId, pageNumber = 1, forceUpdate = false) => {
		try {
			if (!forceUpdate && pageNumber === 1 && messagesCache[ticketId] && isCacheValid(ticketId)) {
				return {
					messages: messagesCache[ticketId].messages,
					hasMore: messagesCache[ticketId].hasMore
				};
			}

			setLoadingMessages(prev => ({ ...prev, [ticketId]: true }));
			
			const { data } = await api.get(`/messages/${ticketId}`, {
				params: { pageNumber }
			});

			setMessagesCache(prev => ({
				...prev,
				[ticketId]: {
					messages: pageNumber === 1 ? data.messages : [...(prev[ticketId]?.messages || []), ...data.messages],
					hasMore: data.hasMore,
					lastPage: pageNumber
				}
			}));

			setMessagesCacheExpiry(prev => ({
				...prev,
				[ticketId]: Date.now() + 5 * 60 * 1000 // 5 minutos
			}));

			return data;
		} catch (err) {
			toastError(err);
			return { messages: [], hasMore: false };
		} finally {
			setLoadingMessages(prev => ({ ...prev, [ticketId]: false }));
		}
	};

	const invalidateTicketCache = (ticketId) => {
		setMessagesCacheExpiry(prev => ({
			...prev,
			[ticketId]: 0
		}));
	};

	const cleanupOldCache = () => {
		const now = Date.now();
		const newExpiry = {};
		const newCache = {};

		Object.entries(messagesCacheExpiry).forEach(([ticketId, expiry]) => {
			if (expiry > now) {
				newExpiry[ticketId] = expiry;
				newCache[ticketId] = messagesCache[ticketId];
			}
		});

		setMessagesCacheExpiry(newExpiry);
		setMessagesCache(newCache);
	};

	useEffect(() => {
		const interval = setInterval(cleanupOldCache, 10 * 60 * 1000);
		return () => clearInterval(interval);
	}, []);

	const updateTicketMessage = (ticketId, message) => {
		setMessagesCache(prev => {
			const ticketMessages = prev[ticketId];
			if (!ticketMessages) return prev;

			const updatedMessages = ticketMessages.messages.map(msg => 
				msg.id === message.id ? message : msg
			);

			return {
				...prev,
				[ticketId]: {
					...ticketMessages,
					messages: updatedMessages
				}
			};
		});
	};

	const addTicketMessage = (ticketId, message) => {
		setMessagesCache(prev => {
			const ticketMessages = prev[ticketId];
			if (!ticketMessages) return prev;

			const messageExists = ticketMessages.messages.some(msg => msg.id === message.id);
			if (messageExists) return prev;

			return {
				...prev,
				[ticketId]: {
					...ticketMessages,
					messages: [...ticketMessages.messages, message]
				}
			};
		});
	};

	const updateTicketInList = (updatedTicket) => {
		setTicketsList(prevTickets => {
			// Remover el ticket existente
			const filteredTickets = prevTickets.filter(t => t.id !== updatedTicket.id);
			
			// Si el ticket está cerrado, solo lo removemos
			if (updatedTicket.status === "closed") {
				return filteredTickets;
			}
			
			// Si el ticket está abierto, asegurarse de que tenga userId
			if (updatedTicket.status === "open" && !updatedTicket.userId) {
				return prevTickets;
			}
			
			// Si el ticket está pendiente, asegurarse de que no tenga userId
			if (updatedTicket.status === "pending" && updatedTicket.userId) {
				return prevTickets;
			}

			// Asegurarse de que el ticket tenga toda la información necesaria
			const completeTicket = {
				...updatedTicket,
				whatsapp: updatedTicket.whatsapp || null,
				contact: updatedTicket.contact || null,
				user: updatedTicket.user || null,
				queue: updatedTicket.queue || null,
				tags: updatedTicket.tags || []
			};

			// Obtener la cola del ticket y su conexión si existe
			const queue = user?.queues?.find(q => q.id === completeTicket.queueId);
			const connectionTag = queue?.connections;

			// Si el ticket es nuevo y tiene una cola con conexión, agregar la etiqueta
			if (!completeTicket.tags?.length && connectionTag) {
				completeTicket.tags = [connectionTag];
			}
			
			// Agregar el ticket actualizado al principio de la lista y ordenar
			const newTickets = [completeTicket, ...filteredTickets].sort((a, b) => {
				return new Date(b.updatedAt) - new Date(a.updatedAt);
			});

			// Si el ticket actualizado es el ticket actual, actualizar currentTicket
			if (currentTicket.id === completeTicket.id) {
				setCurrentTicket(completeTicket);
			}
			
			return Array.from(new Map(newTickets.map(ticket => [ticket.id, ticket])).values())
				.filter(ticket => ticket.status !== "closed");
		});
	};

	const updateTicketTags = (ticketId, tags) => {
		setTicketsList(prevTickets => 
			prevTickets.map(ticket => 
				ticket.id === ticketId 
					? { ...ticket, tags } 
					: ticket
			)
		);
	};

	const removeTicketFromList = (ticketId) => {
		setTicketsList(prevTickets => 
			prevTickets.filter(t => t.id !== ticketId)
		);
	};

	useEffect(() => {
		const companyId = localStorage.getItem("companyId");
		const socket = socketConnection({ companyId });

		socket.on(`company-${companyId}-ticket`, (data) => {
			if (data.action === "update") {
				updateTicketInList(data.ticket);
			}

			if (data.action === "updateTags") {
				updateTicketTags(data.ticketId, data.tags);
			}

			if (data.action === "delete") {
				removeTicketFromList(data.ticketId);
			}
		});

		socket.on("user", (data) => {
			if (data.action === "update" && data.tag) {
				// Actualizar el tag en la lista de tags
				setTags(prevTags => {
					const filteredTags = prevTags.filter(t => t.id !== data.tag.id);
					return [...filteredTags, data.tag];
				});

				// Actualizar el tag en todos los tickets que lo usan
				setTicketsList(prevTickets => 
					prevTickets.map(ticket => {
						if (ticket.tags?.some(t => t.id === data.tag.id)) {
							const updatedTags = ticket.tags.map(t => 
								t.id === data.tag.id ? data.tag : t
							);
							return { ...ticket, tags: updatedTags };
						}
						return ticket;
					})
				);
			}
			
			if (data.action === "create" && data.tag) {
				setTags(prevTags => {
					const exists = prevTags.some(t => t.id === data.tag.id);
					if (exists) return prevTags;
					return [...prevTags, data.tag];
				});
			}

			if (data.action === "delete" && data.tagId) {
				const tagIdNumber = parseInt(data.tagId);
				
				// Eliminar el tag de la lista de tags
				setTags(prevTags => prevTags.filter(t => t.id !== tagIdNumber));
				
				// Eliminar el tag de todos los tickets que lo usan
				setTicketsList(prevTickets => 
					prevTickets.map(ticket => ({
						...ticket,
						tags: ticket.tags?.filter(t => t.id !== tagIdNumber) || []
					}))
				);
			}
		});

		socket.on(`company-${companyId}-appMessage`, (data) => {
			if (data.action === "create" && data.ticket) {
				updateTicketInList(data.ticket);
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleNewTicket = (ticket) => {
		// Asegurarse de que el ticket tenga toda la información antes de actualizarlo
		const completeTicket = {
			...ticket,
			whatsapp: ticket.whatsapp || null,
			contact: ticket.contact || null,
			user: ticket.user || null,
			queue: ticket.queue || null,
			tags: ticket.tags || []
		};
		
		updateTicketInList(completeTicket);
		setCurrentTicket(completeTicket);
	};

	return (
		<TicketsContext.Provider
			value={{ 
				currentTicket, 
				setCurrentTicket,
				ticketsList,
				setTicketsList,
				updateTicketInList,
				updateTicketTags,
				removeTicketFromList,
				handleNewTicket,
				getCurrentTicketInfo: () => {
					return ticketsList.find(t => t.uuid === currentTicket.uuid) || null;
				},
				tags,
				loadingTags,
				fetchTags,
				createTag,
				syncTicketTags,
				messagesCache,
				loadingMessages,
				setLoadingMessages,
				fetchTicketMessages,
				updateTicketMessage,
				addTicketMessage,
				invalidateTicketCache
			}}
		>
			{children}
		</TicketsContext.Provider>
	);
};

export { TicketsContext, TicketsContextProvider };
