import React, { useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { socketConnection } from "../../services/socket";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
  Facebook,
  Instagram,
  WhatsApp,
  Chat,
} from "@material-ui/icons";

import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TableManager from "../../components/TableManager/TableManager";
import TrashIcon from "../../assets/icon/TrashIcon";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import SquareWhatsappIcon from "../../assets/icon/SquareWhatsappIcon";
import SquareActiveIcon from "../../assets/icon/SquareActiveIcon";
import SquareInactiveIcon from "../../assets/icon/SquareInactiveIcon";
import CircleIcon from "../../assets/icon/CircleIcon";
import CircleActiveIcon from "../../assets/icon/CircleActiveIcon";
import { AuthContext } from "../../context/Auth/AuthContext";
import AddIcon from "../../assets/icon/AddIcon";
import SelectChannelModal from "../../components/SelectChannelModal";
import WebChatModal from "../../components/WebChatModal";
import smsIcon from "../../assets/img/chat.png";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tableContainer: {
    width: "100%",
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "90%",
    },
  },
  buttonQR: {
    borderRadius: 10,
    width: 100,
  },
}));

const CustomToolTip = ({ title, content, children }) => {
  return (
    <Tooltip
      arrow
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

const IconChannel = (channel) => {
  if (channel === "whatsapp") {
    return <SquareWhatsappIcon />;
  }
  if (channel === "webchat") {
    return (
      <div style={{ 
        backgroundColor: "rgba(0, 123, 255, 0.18)",
        borderColor: "rgba(0, 123, 255)",
        borderRadius: "20%",
        width: 35, 
        height: 35, 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        boxShadow: "0 0 0px 10px rgba(0, 123, 255, 0.18)",
        marginLeft: 10 
      }}>
        <img src={smsIcon} alt="WebChat" style={{ width: 24, height: 24}} />
      </div>
    );
  }
  if (channel === "facebook") {
    return (
      <div style={{ 
        backgroundColor: "#E6F0FF", 
        borderRadius: "50%", 
        width: 32, 
        height: 32, 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center" 
      }}>
        <Facebook style={{ color: "#1877F2" }} />
      </div>
    );
  }
  if (channel === "instagram") {
    return <Instagram style={{ color: "#E1306C" }} />;
  }
  return <div />;
};

const Connections = () => {
  const classes = useStyles();
  const { whatsApps, loading, dispatch } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    confirmButtonText: "",
  });
  const [selectChannelModalOpen, setSelectChannelModalOpen] = useState(false);
  const [selectedChannelType, setSelectedChannelType] = useState(null);
  const [webChatModalOpen, setWebChatModalOpen] = useState(false);
  const [selectedWebChatId, setSelectedWebChatId] = useState(null);

  const handleRequestNewQrCode = async (whatsAppId) => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setWhatsAppModalOpen(true);
  };

  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, []);

  const handleOpenQrModal = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, []);

  const handleEditWhatsApp = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId: whatsAppId,
        confirmButtonText: i18n.t("connections.buttons.ConfirmDeleting"),
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"));
      } catch (err) {
        toastError(err);
      }
    }
    setConfirmModalInfo({
      action: "",
      title: "",
      message: "",
      whatsAppId: "",
    });
    setConfirmModalOpen(false);
  };

  const handleSaveWhatsApp = async (whatsAppId, isDefault) => {
    const newIsDefault = !isDefault;
    const whatsappData = { isDefault: newIsDefault };
    
    try {
      if (whatsAppId) {
        console.log("Iniciando actualización de isDefault:", whatsAppId, "nuevo valor:", newIsDefault);
        
        // Obtener el canal de la conexión que estamos marcando como predeterminada
        const currentWhatsApp = whatsApps.find(w => w.id === whatsAppId);
        
        if (!currentWhatsApp) {
          toast.error("No se pudo encontrar la conexión seleccionada.");
          return;
        }

        // Variable para almacenar el estado actualizado (lo usaremos varias veces)
        let updatedWhatsApps;

        // PRIMERO: Actualización optimista de la interfaz
        if (currentWhatsApp && currentWhatsApp.channel && newIsDefault) {
          console.log("Actualizando conexión a predeterminada y quitando predeterminada a otras del mismo canal:", currentWhatsApp.channel);
          
          // Crear una copia del estado actual para modificarla
          updatedWhatsApps = [...whatsApps].map(wa => {
            // Si es la conexión que estamos cambiando, actualizarla a predeterminada
            if (wa.id === whatsAppId) {
              return { ...wa, isDefault: true };
            }
            
            // Si es otra conexión del mismo canal y está marcada como predeterminada, quitarla
            if (wa.channel === currentWhatsApp.channel && wa.isDefault && wa.id !== whatsAppId) {
              console.log("Quitando predeterminada de:", wa.name);
              return { ...wa, isDefault: false };
            }
            
            // Dejar las demás conexiones sin cambios
            return wa;
          });
        } else {
          // Actualizar solo la conexión actual
          console.log("Actualizando solo la conexión actual:", currentWhatsApp.name);
          updatedWhatsApps = [...whatsApps].map(wa => {
            if (wa.id === whatsAppId) {
              return { ...wa, isDefault: newIsDefault };
            }
            return wa;
          });
        }
        
        // Actualizar la interfaz inmediatamente
        console.log("Actualizando interfaz (1/2)");
        dispatch({ type: "LOAD_WHATSAPPS", payload: updatedWhatsApps });     
        // SEGUNDO: Enviar la solicitud al servidor

        console.log("Enviando solicitud al servidor");
        const response = await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
        
        if (response.status === 200) {
          console.log("Respuesta exitosa del servidor");
          
          // Mostrar notificación de éxito
          toast.success("Conexión actualizada correctamente", {
            autoClose: 1500, 
            position: "bottom-right"
          });
          
          // IMPORTANTE: Asegurarnos que la UI refleje nuestros cambios
          // Volvemos a aplicar nuestra actualización para asegurarnos que no fue sobrescrita
          console.log("Forzando actualización final (2/2)");
          setTimeout(() => {
            dispatch({ type: "LOAD_WHATSAPPS", payload: updatedWhatsApps });
          }, 500);
        } else {
          console.log("Error en respuesta del servidor:", response.status);
          
          // Si hubo un error en el servidor, revertir los cambios locales
          toast.error("Error en el servidor. Revirtiendo cambios...");
          const { data } = await api.get("/whatsapp/one?session=0");
          if (data) {
            dispatch({ type: "LOAD_WHATSAPPS", payload: data });
          }
        }
      } else {
        toast.error("No se pudo actualizar la cuenta predeterminada.");
      }
    } catch (err) {
      console.error("Error en handleSaveWhatsApp:", err);
      
      // Si hay un error, intentar recargar los datos originales
      toastError(err);
      toast.error("Error al actualizar la conexión. Recargando datos...", {
        autoClose: 3000,
        position: "bottom-right"
      });
      
      try {
        const { data } = await api.get("/whatsapp/one?session=0");
        if (data) {
          dispatch({ type: "LOAD_WHATSAPPS", payload: data });
        }
      } catch (refreshErr) {
        console.error("Error al recuperar datos originales:", refreshErr);
      }
    }
  };

  const handleWebChatConnection = async (whatsAppId, currentStatus) => {
    try {
      const newStatus = currentStatus === "DISCONNECTED" ? "CONNECTED" : "DISCONNECTED";
      await api.put(`/whatsapp/${whatsAppId}`, { status: newStatus });
      toast.success(
        newStatus === "CONNECTED" 
          ? "WebChat conectado exitosamente"
          : "WebChat desconectado exitosamente"
      );
    } catch (err) {
      console.error(err);
      toastError(err);
    }
  };

  const NameAndUpdateInfo = ({ name, updatedAt }) => {
    const formatDate = (date) => {
      try {
        if (!date && name === "Sin actualización") return "Esperando registro...";
        if (!date) return "En proceso de registro";
        return `últ. actualización ${format(parseISO(date), "dd/MM/yy HH:mm")}`;
      } catch (error) {
        console.error("Error al formatear fecha:", error);
        return "Error en registro";
      }
    };

    const isLoading = !updatedAt && name !== "Sin actualización";

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontWeight: "bold", fontSize: 16 }}>{name}</span>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ 
            fontSize: 14,
            color: !updatedAt ? "#ffa726" : "#666"
          }}>
            {formatDate(updatedAt)}
          </span>
          {isLoading && <CircularProgress size={12} style={{ color: "#ffa726" }} />}
        </div>
      </div>
    );
  };
  
  const handleEditWebChat = (webChat) => {
    setSelectedWebChatId(webChat.id);
    setWebChatModalOpen(true);
  };

  const handleCloseWebChatModal = () => {
    setSelectedWebChatId(null);
    setWebChatModalOpen(false);
  };

  const handleChannelSelect = (channelType) => {
    setSelectChannelModalOpen(false);
    
    // Guardar el tipo de canal seleccionado
    setSelectedChannelType(channelType);
    
    // Abrir el modal correspondiente según el tipo de canal
    if (channelType === "webchat") {
      // Para WebChat, abrir el modal de WebChat con id null (crear nuevo)
      setSelectedWebChatId(null);
      setWebChatModalOpen(true);
    } else {
      // Para WhatsApp u otros canales, usar el modal existente
      setWhatsAppModalOpen(true);
    }
  };

  const handleOpenSelectChannelModal = () => {
    setSelectChannelModalOpen(true);
  };

  const primaryButtons = user.profile === "admin" ? [
    { 
      label: "Agregar conexión", 
      onClick: handleOpenSelectChannelModal, 
      icon: <AddIcon fill='#000' /> 
    },
  ] : [];

  const columns = [
    {
      field: "channel",
      renderCell: ({ row }) => IconChannel(row.channel),
      width: "5%",
    },
    {
      field: "name",
      renderCell: ({ row }) => (
        <NameAndUpdateInfo name={row.name} updatedAt={row.updatedAt} />
      ),
    },
    {
      field: "status",
      renderCell: ({ row }) => renderStatusToolTips(row),
      width: "20%",
    },
    {
      field: "isDefault",
      renderCell: ({ row }) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Button
            onClick={() => handleSaveWhatsApp(row.id, row.isDefault)}
            style={{ minWidth: "auto", padding: "4px" }}
          >
            {row.isDefault ? <CircleActiveIcon /> : <CircleIcon />}
          </Button>
          <span>{i18n.t("whatsappModal.form.default")}</span>
        </div>
      ),
    },
    {
      field: "session",
      renderCell: user.profile === "admin" ? ({ row }) => renderActionButtons(row) : null,
    },
  ];

  const actionButtons = user.profile === "admin" ? [
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          // Verificar el tipo de canal y llamar a la función correspondiente
          if (row.channel === "webchat") {
            handleEditWebChat(row);
          } else {
            handleEditWhatsApp(row);
          }
        }}
        style={{ marginTop: 17 }}
      >
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => handleOpenConfirmationModal("delete", row.id)}
        style={{ marginTop: 17 }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
      ),
    ]
  : [];

  const renderActionButtons = (whatsApp) => {
    // Para conexiones de WebChat, mostramos botones de editar y eliminar
    if (whatsApp.channel === "webchat") {
      return (
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => handleWebChatConnection(whatsApp.id, whatsApp.status)}
          className={classes.buttonQR}
        >
          {whatsApp.status === "DISCONNECTED" ? "Conectar" : "Desconectar"}
        </Button>
      );
    }
    
    // Para conexiones de WhatsApp
    if (whatsApp.status === "qrcode" ) {
      return (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.buttonQR}
          onClick={() => handleOpenQrModal(whatsApp)}
        >
          {i18n.t("connections.buttons.qrcode")}
        </Button>
      );
    }
    if (whatsApp.status === "DISCONNECTED") {
      return (
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => handleRequestNewQrCode(whatsApp.id)}
          className={classes.buttonQR}
        >
          {i18n.t("connections.buttons.newQr")}
        </Button>
      );
    }
    if (["CONNECTED", "PAIRING", "TIMEOUT"].includes(whatsApp.status)) {
      return (
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            handleOpenConfirmationModal("disconnect", whatsApp.id);
          }}
        >
          {i18n.t("connections.buttons.disconnect")}
        </Button>
      );
    }
    if (whatsApp.status === "OPENING") {
      return (
        <Button size="small" variant="outlined" disabled color="default">
          {i18n.t("connections.buttons.connecting")}
        </Button>
      );
    }
    return (
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={() => handleRequestNewQrCode(whatsApp.id)}
        className={classes.buttonQR}
      >
        {i18n.t("connections.buttons.newQr")}
      </Button>
    );
  };

  const renderStatusToolTips = (whatsApp) => {
    // Para conexiones de WebChat, mostramos un estado diferente
    if (whatsApp.channel === "webchat") {
      return (
        <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
        <SquareActiveIcon />
      </CustomToolTip>
      );
    }
    
    // Para conexiones de WhatsApp
    if (whatsApp.status === " ") {
      return (
        <CustomToolTip
          title={i18n.t("connections.toolTips.disconnected.title")}
          content={i18n.t("connections.toolTips.disconnected.content")}
          style={{ marginTop: 5 }}
        >
          <SquareInactiveIcon />
        </CustomToolTip>
      );
    }
    if (whatsApp.status === "OPENING") {
      return <CircularProgress size={24} />;
    }
    if (whatsApp.status === "qrcode") {
      return (
        <CustomToolTip
          title={i18n.t("connections.toolTips.qrcode.title")}
          content={i18n.t("connections.toolTips.qrcode.content")}
        >
          <CropFree />
        </CustomToolTip>
      );
    }
    if (whatsApp.status === "CONNECTED") {
      return (
        <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
          <SquareActiveIcon />
        </CustomToolTip>
      );
    }
    if (["TIMEOUT", "PAIRING"].includes(whatsApp.status)) {
      return (
        <CustomToolTip
          title={i18n.t("connections.toolTips.timeout.title")}
          content={i18n.t("connections.toolTips.timeout.content")}
        >
          <SignalCellularConnectedNoInternet2Bar color="secondary" />
        </CustomToolTip>
      );
    }
    return null;
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <ConfirmationModal
          title={confirmModalInfo.title}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={handleSubmitConfirmationModal}
          confirmButtonText={confirmModalInfo.confirmButtonText}
        >
          {confirmModalInfo.message}
        </ConfirmationModal>
        <QrcodeModal
          open={qrModalOpen}
          onClose={handleCloseQrModal}
          whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
        />
        <WhatsAppModal
          open={whatsAppModalOpen}
          onClose={handleCloseWhatsAppModal}
          whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
          whatsApps={whatsApps}
        />
        <SelectChannelModal
          open={selectChannelModalOpen}
          onClose={() => setSelectChannelModalOpen(false)}
          onSelect={handleChannelSelect}
        />
        <WebChatModal
          open={webChatModalOpen}
          onClose={handleCloseWebChatModal}
          webChatId={selectedWebChatId}
        />
        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("connections.title")}
            primaryButtons={primaryButtons}
          />
          <TableManager
            columns={columns}
            data={whatsApps}
            loading={loading}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </>
  );
};

export default Connections;
