import React, { useState, useEffect, useReducer, useContext } from "react";
import { debounce } from 'lodash';

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItemCustom";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TicketsContext } from "../../context/Tickets/TicketsContext";




const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "75vh",
    flexDirection: "column",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    background: theme.palette.background.default,
    [theme.breakpoints.up("ultra")]: {
      height: "82vh",
    },
  },

  ticketsList: {
    flex: 1,
    overflowY: "auto",
    ...theme.scrollbarStyles,
    background: theme.palette.background.default
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100%",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  console.log("Reducer llamado:", {
    type: action.type,
    payload: action.payload,
    currentState: state.length
  });

  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;
    console.log("Cargando tickets:", {
      newTicketsCount: newTickets.length,
      isInitialLoad: action.isInitialLoad
    });
    
    if (action.isInitialLoad) {
      const sortedTickets = [...newTickets].sort((a, b) => 
        new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      console.log("Nueva lista inicial:", sortedTickets.length);
      return sortedTickets;
    } else {
      const ticketIds = new Set(state.map(t => t.id));
      const uniqueNewTickets = newTickets.filter(ticket => !ticketIds.has(ticket.id));
      const result = [...state, ...uniqueNewTickets].sort((a, b) => 
        new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      console.log("Lista actualizada:", {
        uniqueNewTickets: uniqueNewTickets.length,
        totalTickets: result.length
      });
      return result;
    }
  }

  if (action.type === "UPDATE_TICKET") {
    console.log("Actualizando ticket en reducer:", action.payload);
    const ticket = action.payload;
    
    if (ticket.status === "closed") {
      console.log("Eliminando ticket cerrado:", ticket.id);
      return state.filter(t => t.id !== ticket.id);
    }
    
    const ticketIndex = state.findIndex(t => t.id === ticket.id);
    console.log("Índice del ticket:", ticketIndex);
    
    if (ticketIndex === -1) {
      const result = [ticket, ...state].sort((a, b) => 
        new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      console.log("Agregando nuevo ticket:", result.length);
      return result;
    }
    
    const newState = [...state];
    newState[ticketIndex] = ticket;
    const result = newState.sort((a, b) => 
      new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    console.log("Estado actualizado:", result.length);
    return result;
  }

  if (action.type === "DELETE_TICKET") {
    console.log("Eliminando ticket:", action.payload);
    const result = state.filter(ticket => ticket.id !== action.payload);
    console.log("Tickets restantes:", result.length);
    return result;
  }

  if (action.type === "RESET") {
    console.log("Reseteando estado");
    return [];
  }

  return state;
};

const TicketsListCustom = (props) => {
  const {
    status,
    searchParam,
    tags,
    users,
    showAll,
    selectedQueueIds,
    updateCount,
    style,
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [update, setUpdate] = useState(0);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const [ticketsListUpdated, setTicketsListUpdated] = useState([]);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;
  const { ticketId } = useParams();
  const { setTicketsList } = useContext(TicketsContext);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, tags, users, selectedQueueIds]);

  const { tickets, hasMore, loading, reloadTickets } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    tags: JSON.stringify(tags),
    users: JSON.stringify(users),
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    const queueIds = queues.map((q) => q.id);
    const filteredTickets = tickets.filter(
      (t) => queueIds.indexOf(t.queueId) > -1
    );

    const ticketsToUse = profile === "user" ? filteredTickets : tickets;

    dispatch({ 
      type: "LOAD_TICKETS", 
      payload: ticketsToUse,
      isInitialLoad: pageNumber === 1 
    });

    // Actualizar el contexto con la lista completa de tickets
    setTicketsList(prev => {
      const newTickets = ticketsToUse.filter(
        ticket => !prev.some(t => t.id === ticket.id)
      );
      return [...prev, ...newTickets];
    });
  }, [tickets, status, searchParam, queues, profile, pageNumber, setTicketsList]);

  const debouncedDispatch = debounce((action) => {
    dispatch(action);
  }, 300); // 300ms de delay

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    const shouldUpdateTicket = (ticket) => {
        // Si el ticket está cerrado, no debería estar en ninguna lista excepto "closed"
        if (ticket.status === "closed" && status !== "closed") {
            return false;
        }

        // Si el ticket está abierto, debe tener un userId asignado
        if (ticket.status === "open" && !ticket.userId) {
            return false;
        }

        // Si el ticket está pendiente, no debe tener userId
        if (ticket.status === "pending" && ticket.userId) {
            return false;
        }

        return (
            (!ticket.userId || ticket.userId === user?.id || showAll) &&
            (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1) &&
            ticket.status === status
        );
    };

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on(`company-${companyId}-ticket`, async (data) => {
        console.log("Socket evento recibido:", {
            action: data.action,
            ticketId: data.ticketId,
            ticket: data.ticket,
            currentStatus: status
        });

        if (data.action === "RESET_UNREAD" || data.action === "removeFromList") {
            console.log("Forzando recarga de tickets por evento:", data.action);
            reloadTickets();
            return;
        }

        if (data.action === "update" || data.action === "CREATE_TICKET") {
            const ticketBelongsToStatus = data.ticket?.status === status;
            console.log("Verificación de estado:", {
                ticketStatus: data.ticket?.status,
                listStatus: status,
                belongsToStatus: ticketBelongsToStatus
            });
            
            // Forzar recarga si el ticket está cerrado
            if (data.ticket?.status === "closed") {
                console.log("Forzando recarga por ticket cerrado");
                reloadTickets();
                return;
            }
            
            // Actualizar el ticket solo si cumple con los criterios
            const shouldUpdate = shouldUpdateTicket(data.ticket);
            console.log("Verificación de criterios:", {
                shouldUpdate,
                ticket: data.ticket
            });

            if (shouldUpdate) {
                console.log("Actualizando ticket:", data.ticket);
                dispatch({
                    type: "UPDATE_TICKET",
                    payload: data.ticket,
                });
            } else {
                console.log("Eliminando ticket por no cumplir criterios:", data.ticket?.id);
                dispatch({ 
                    type: "DELETE_TICKET", 
                    payload: data.ticket?.id 
                });
                // Forzar recarga para asegurar sincronización
                reloadTickets();
            }
        }

        if (data.action === "delete") {
            console.log("Eliminando ticket por acción delete:", data?.ticketId);
            dispatch({ 
                type: "DELETE_TICKET", 
                payload: data?.ticketId 
            });
            // Forzar recarga después de eliminar
            reloadTickets();
        }
    });

    socket.on(`company-${companyId}-appMessage`, (data) => {
      if (data.action === "create") {
        const ticketBelongsToStatus = data.ticket.status === status;
        
        if (data.ticket.status === "closed" || !ticketBelongsToStatus) {
          dispatch({ 
            type: "DELETE_TICKET", 
            payload: data.ticket.id 
          });
          return;
        }

        if (shouldUpdateTicket(data.ticket)) {
          dispatch({
            type: "UPDATE_TICKET",
            payload: data.ticket
          });
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, user, selectedQueueIds]);

  useEffect(() => {
    if (typeof updateCount === "function") {
      updateCount(ticketsList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);


  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  useEffect(() => {
    return () => {
      dispatch({ type: "RESET" });
      debouncedDispatch.cancel();
    };
  }, []);

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0, height: '100%' }}>
          {ticketsList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {ticketsList.map((ticket) => (
                <TicketListItem 
                  ticket={ticket}
                  key={ticket.id} 
                  isSelected={ticket.uuid === ticketId}
                  reloadTickets={reloadTickets}
                  setUpdate={setUpdate}
                />
              ))}
            </>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsListCustom;
