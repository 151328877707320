import React, { useEffect, useState, useContext } from "react";
import { Box, Chip, TextField, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import DownArrow from "../../assets/icon/DownArrow";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  inputRoot: {
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000014',
        transition: 'border-color 0.2s ease-in-out',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#bfbfbf",
    textShadow: "1px 1px 1px #000",
    color: "white",
  },
  icon: {
    right: theme.spacing(1),
    position: "absolute",
    pointerEvents: "none",
  },
}));

export function UsersFilter({ onFiltered, initialUsers }) {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadUsers();
    }
    fetchData();
  }, []);

  useEffect(() => {
    setSelecteds([]);
    if (
      Array.isArray(initialUsers) &&
      Array.isArray(users) &&
      users.length > 0
    ) {
      onChange(initialUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUsers, users]);

  const loadUsers = async () => {
    try {
      const { data } = await api.get(`/users/list`);
      const userList = data
        .filter(u => u.id !== user.id)
        .map((u) => ({ id: u.id, name: u.name }));
      setUsers(userList);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Box style={{ padding: "0px 10px 10px" }}>
      <FormControl className={classes.formControl}>
        <Autocomplete
          multiple
          size="small"
          options={users}
          value={selecteds}
          onChange={(e, v) => onChange(v)}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => {
            return (
              option?.id === value?.id ||
              option?.name.toLowerCase() === value?.name.toLowerCase()
            );
          }}
          renderTags={(value, getUserProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.name}
                {...getUserProps({ index })}
                size="small"
                className={classes.chip}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Filtro por usuarios"
              InputProps={{
                ...params.InputProps,
                classes: { root: classes.inputRoot },
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
}