import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
	dialog: {
		"& .MuiDialog-paper": {
			borderRadius: 15,
			minWidth: props => props.minWidth || 'auto',
			width: props => props.width || 'auto'
		}
	},
	dialogTitle: {
		backgroundColor: theme.palette.tabHeaderBackground,
		padding: "16px 24px",
		"& h2": {
			fontSize: "24px",
			fontWeight: "normal",
			color: theme.palette.text.primary,
		}
	},
	dialogContent: {
		backgroundColor: theme.palette.tabHeaderBackground,
		padding: "20px 24px",
		color: theme.palette.text.primary,
		minHeight: props => props.minHeight || 'auto'
	},
	dialogActions: {
		backgroundColor: theme.palette.tabHeaderBackground,
		padding: "16px 24px",
		gap: "10px",
	},
	cancelButton: {
		borderRadius: 12,
		padding: "10px 20px",
	},
	confirmButton: {
		borderRadius: 12,
		backgroundColor: "#FF4444",
		padding: "10px 20px",
		color: "white",
		"&:hover": {
			backgroundColor: "#E03C3C",
		}
	}
}));

const ConfirmationModal = ({ 
	title, 
	children, 
	open, 
	onClose, 
	onConfirm, 
	confirmButtonText, 
	showConfirmButton = true,
	maxWidth = "xs",
	minWidth,
	width,
	minHeight
}) => {
	const classes = useStyles({ minWidth, width, minHeight });

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={maxWidth}
			fullWidth
			className={classes.dialog}
		>
			<DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Typography>{children}</Typography>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button
					variant="outlined"
					onClick={() => onClose(false)}
					color="secondary"
					className={classes.cancelButton}
				>
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
				{showConfirmButton && (
					<Button
						onClick={() => {
							onConfirm();
							onClose(false);
						}}
						variant="contained"
						className={classes.confirmButton}
					>
						{confirmButtonText ? confirmButtonText : i18n.t("confirmationModal.buttons.confirm")}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationModal;