import React, { useContext, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { green } from '@material-ui/core/colors';
import UndoIcon from "../../assets/icon/UndoIcon";
import SuccessIcon from "../../assets/icon/SuccessIcon";
import MoreIcon from "../../assets/icon/MoreIcon";
import { toast } from "react-toastify";
import useTickets from "../../hooks/useTickets";


const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.2),
		},
	},
}));

const TicketActionButtonsCustom = ({ ticket: initialTicket, handleDrawerOpen, reloadTickets }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const { 
		setCurrentTicket, 
		ticketsList,
		updateTicketInList,
		removeTicketFromList,
		setTicketsList
	} = useContext(TicketsContext);


	// Obtener el ticket más actualizado del contexto
	const ticket = useMemo(() => {
		const updatedTicket = ticketsList.find(t => t.id === initialTicket.id);
		return updatedTicket || initialTicket;
	}, [ticketsList, initialTicket.id]);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			const { data } = await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});
			console.log("entro en updating")
			console.log(status, "status")
			
			// Actualizar el ticket en el contexto global según el cambio de estado
			if (status === "open") {
				console.log("entro en open")
				// Primero removemos el ticket de la lista actual y agregamos el actualizado
				setTicketsList(prevTickets => {
					const filteredTickets = prevTickets.filter(t => t.id !== data.id);
					return [data, ...filteredTickets];
				});
				setCurrentTicket({ id: null, code: "#open" });
				toast.success("Chat abierto correctamente.");
			} else if (status === "pending") {
				console.log("data", data)
				// Actualizar el estado del ticket en la lista
				updateTicketInList(data);
				// Remover el ticket actual
				setCurrentTicket({ id: null, code: null });
				toast.success("Chat devuelto a pendientes.");
			} else if (status === "closed") {
				// Remover inmediatamente el ticket de la lista y del contexto
				console.log("entro en closed")
				removeTicketFromList(ticket.id);
				setCurrentTicket({ id: null, code: null });
				// Forzar la actualización en el contexto global
				setTicketsList(prevTickets => prevTickets.filter(t => t.id !== ticket.id));
				
				// Usar reloadTickets para actualizar la lista
				if (reloadTickets) {
					reloadTickets();
				}
				
				history.push("/tickets");
				toast.success("Chat resuelto correctamente.");
			}

			// Redirigir después de actualizar el estado
			history.push("/tickets");
		} catch (err) {
			setLoading(false);
			toastError(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<Tooltip title={i18n.t("messagesList.header.buttons.return")}>
						<IconButton onClick={e => handleUpdateTicketStatus(e, "pending", null)}>
							<UndoIcon width="22" height="22" />
						</IconButton>
					</Tooltip>
					<Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
						<IconButton onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)} color="primary">
							<SuccessIcon fill={"#5BC64C"}  width="22" height="22" />
						</IconButton>
					</Tooltip>
					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreIcon fill="#909090" stroke="1.8" width="22" height="22" />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
						handleDrawerOpen={handleDrawerOpen}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtonsCustom;
