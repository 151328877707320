import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from 'material-ui-color';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Backdrop } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Checkbox, FormControlLabel, InputAdornment } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	
	btnWrapper: {
		position: "relative",
		borderRadius: 10,
		padding: 10,
		width: 100,
	  },
	  noHover: {
		minWidth: 0,
		paddingRight: 5,
		"&:hover": {
		  backgroundColor: "transparent",
		},
	  },
	  textField: {
		"& .MuiOutlinedInput-root": {
		  "& fieldset": {
			borderColor: "#00000014",
		  },
		  "&:hover fieldset": {
			borderColor: "#00000014",
		  },
		  "&.Mui-focused fieldset": {
			borderColor: "#00000014",
		  },
		},
	  },
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	dialog: {
		"& .MuiDialog-paper": {
			borderRadius: 15,
		}
	},
	dialogTitle: {
		backgroundColor: theme.palette.tabHeaderBackground,
		padding: "16px 24px",
		"& h2": {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		}
	},
	closeButton: {
		position: "absolute",
		right: 8,
		top: 8,
		backgroundColor: theme.palette.tabHeaderBackground,
		borderRadius: 10,
		padding: 5,
	},
	content: {
		backgroundColor: theme.palette.tabHeaderBackground,
		padding: "20px 24px",
	},
	colorGrid: {
		display: "grid",
		gridTemplateColumns: "repeat(9, 1fr)",
		gap: 8,
		marginBottom: 20,
	},
	colorBox: {
		width: "100%",
		paddingBottom: "100%",
		borderRadius: 8,
		cursor: "pointer",
		border: "2px solid transparent",
		"&:hover": {
			transform: "scale(1.1)",
		},
		"&.selected": {
			border: "2px solid #000",
		}
	},
	buttons: {
		display: "flex",
		justifyContent: "space-between",
		gap: 10,
		marginTop: 20,
	},
	button: {
		borderRadius: 8,
		padding: "8px 16px",
		flex: 1,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
	buttonWrapper: {
		position: 'relative',
		display: 'inline-block',
		flex: 1,
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 200,
	},
	loadingProgress: {
		color: '#FFB52B',
	},
}));

const TagSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, "Mensaje corto")
		.required("Obligatorio")
});

const predefinedColors = [
	"#ff7b7b", "#ff6b2b", "#ff8c2b", "#ffd700", "#ffb52b", 
	"#ffe4b5", "#90ee90", "#98fb98", "#00ffff",
	"#1e90ff", "#0000ff", "#e6e6fa", "#ff00ff", 
	"#ffb6c1", "#ff69b4", "#ff0000", "#ffcbcb"
];

const TagModal = ({ open, onClose, tagId, reload, kanban, dispatch }) => {
	const classes = useStyles();
	const theme = useTheme();
	const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(false);

	const initialState = {
		name: "",
		color: predefinedColors[0],
		kanban: kanban
	};

	const [tag, setTag] = useState(initialState);

	useEffect(() => {
		const fetchTag = async () => {
			if (!tagId) return;
			
			try {
				setInitialLoading(true);
				const { data } = await api.get(`/tags/${tagId}`);
				setTag(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toast.error(err);
			} finally {
				setInitialLoading(false);
			}
		};

		if (open) {
			fetchTag();
		}
	}, [tagId, open]);

	const handleClose = () => {
		setTag(initialState);
		onClose();
	};

	const handleSaveTag = async (values, actions) => {
		try {
			setLoading(true);
			if (tagId) {
				const { data } = await api.put(`/tags/${tagId}`, values);
				dispatch({ type: "UPDATE_TAGS", payload: data });
				toast.success(i18n.t("tagModal.success"));
			} else {
				// Obtener todas las etiquetas existentes para determinar el último orden
				const { data: existingTags } = await api.get("/tags/kanban");
				let newOrder = 0;

				if (existingTags.lista && existingTags.lista.length > 0) {
					newOrder = Math.max(...existingTags.lista.map(tag => tag.order || 0)) + 1;
				}

				// Agregar el orden al final
				const newValues = {
					...values,
					order: newOrder
				};
				
				const { data } = await api.post("/tags", newValues);
				dispatch({ type: "UPDATE_TAGS", payload: data });
				toast.success(i18n.t("tagModal.success"));
			}
			handleClose();
			if (typeof reload === "function") {
				reload();
			}
		} catch (err) {
			console.error("Error completo:", err);
			const errorMsg = err.response?.data?.error || 
							err.message || 
							"Error al procesar la solicitud";
			toast.error(`Error: ${errorMsg}`);
			
			// Mostrar más detalles en la consola para debugging
			if (err.response) {
				console.error("Datos de la respuesta:", err.response.data);
				console.error("Estado HTTP:", err.response.status);
				console.error("Cabeceras:", err.response.headers);
			}
		} finally {
			setLoading(false);
			actions.setSubmitting(false);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				className={classes.dialog}
			>
				<DialogTitle className={classes.dialogTitle}>
					<span>{tag.name || "Etapa"}</span>
					<IconButton className={classes.closeButton} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent className={classes.content}>
					{initialLoading ? (
						<div className={classes.loadingContainer}>
							<CircularProgress 
								size={50}
								className={classes.loadingProgress}
							/>
						</div>
					) : (
						<Formik
							initialValues={tag}
							enableReinitialize={true}
							validationSchema={TagSchema}
							onSubmit={(values, actions) => {
								handleSaveTag(values, actions);
							}}
						>
							{({ touched, errors, isSubmitting, values, setFieldValue }) => (
								<Form>
									<Field
										as={TextField}
										label="Nombre de la etapa"
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										fullWidth
										className={classes.textField}
									/>

									<Box>
										<p style={{ marginBottom: 10 }}>Selecciona un color</p>
										<div className={classes.colorGrid}>
											{predefinedColors.map((color, index) => (
												<div
													key={index}
													className={`${classes.colorBox} ${values.color === color ? 'selected' : ''}`}
													style={{ backgroundColor: color }}
													onClick={() => {
														setFieldValue('color', color);
													}}
												/>
											))}
										</div>
									</Box>

									<div className={classes.buttons}>
										<Button
											onClick={handleClose}
											variant="outlined"
											className={classes.button}
											disabled={loading}
										>
											Cancelar
										</Button>
										<div className={classes.buttonWrapper}>
											<Button
												type="submit"
												color="primary"
												disabled={loading}
												variant="contained"
												className={classes.button}
											>
												{loading ? (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												) : (
													"Guardar cambios"
												)}
											</Button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default TagModal;
