import React, { useState, useContext, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';

import {
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TableManager from "../../components/TableManager/TableManager";
import LinkIcon from "../../assets/icon/LinkIcon";
import moment from "moment";
import PersonChatingImg from "../../assets/img/PersonChatingImg";
import CheckoutModal from "../../components/CheckoutPage/CheckoutPage";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '70%'
    },
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  manageButton: {
    fontSize: "1rem",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.warning.main,
  },
  Button: {
    borderRadius: "10px",
    backgroundColor: '#f8bf00',
    marginTop: theme.spacing(3),
    color: '#000',
    '&:hover': {
      backgroundColor: '#e0ac00',
    },
  },
}));

const Suscripcion = () => {
  const classes = useStyles();
  const theme = useTheme();
  const minorThenMd = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useContext(AuthContext);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const PayDate = user?.company?.dueDate;
  const subscribed = user?.company?.subscribed;
  const formattedDate = formatDateToSpanish(PayDate);
  const [portalUrl, setPortalUrl] = useState(null);

  useEffect(() => {
    if (!portalUrl) {
      fetchPortalUrl();
    }
  }, [portalUrl]);

  const fetchPortalUrl = async () => {
    try {
      const response = await api.post('/create-customer-portal-session', {
        companyId: user.company.id
      });
      setPortalUrl(response.data.url);
    } catch (error) {
      console.error("Error fetching portal URL:", error);
    }
  };

  function formatDateToSpanish(dateString) {
    moment.locale('es');
    return moment(dateString).format('D [de] MMMM, YYYY');
  }
  
  const handleOpenContactModal = () => {
    setSelectedContactId(user.id);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(user.id);
    setContactModalOpen(false);
  };

  const handleGestionarSuscripcion = () => {
    if (portalUrl) {
      window.location.href = portalUrl;
    }
  };

  const subscriptionColumns = [
    {
      field: 'proximaFechaPago',
      headerName: 'Próxima fecha de pago',
      headerStyle: { fontWeight: 'bold',},
      cellStyle: {fontWeight: 'bold',fontSize: `${minorThenMd?'18px' : '24px' }`,},
      width: '40%',
    },
    {
      field: 'usuarios',
      headerName: 'Usuarios',
      headerStyle: {
        fontWeight: 'bold',
      },
      cellStyle: {
        fontWeight: 'bold',
        fontSize: `${minorThenMd?'18px' : '24px' }`,
      },
      width: '15%',
    },
    {
      field: 'conexiones',
      headerName: 'Conexiones',
      headerStyle: {
        fontWeight: 'bold',
      },
      cellStyle: {
        fontWeight: 'bold',
        fontSize: `${minorThenMd?'18px' : '24px' }`,
      },
      width: '25%',
    },
    {
      field: 'monto',
      headerName: 'Monto',
      headerStyle: {
        fontWeight: 'bold',
      },
      cellStyle: {
        fontWeight: 'bold',
        fontSize: `${minorThenMd?'18px' : '24px' }`,
      },
      width: '150px',
    },
  ];
  const subscriptionData = [
    {
      proximaFechaPago: formattedDate,
      usuarios: user?.company?.totalUsers || 3,
      conexiones: user?.company?.totalConnection || 2,
      monto: '$60/mes',
    },
  ];

  return (
    <div className={classes.mainContainer}>
      <CheckoutModal
        open={contactModalOpen}
        handleClose={handleCloseContactModal}
        contactId={selectedContactId}
        user={user}
      ></CheckoutModal>
      {console.log("esta suscrito?", subscribed)}
      {subscribed ?
        <>
          <HeaderManager
            title="Suscripción"
            titlePosition="center"
          />
          <div className={classes.tableContainer}>
            <TableManager
              columns={subscriptionColumns}
              data={subscriptionData}
            />
          </div>
          <Grid container justifyContent="flex-end" className={classes.tableContainer}>
            <Button
              className={classes.manageButton}
              onClick={handleGestionarSuscripcion}
            >
              Gestionar suscripción
            </Button>
            <Button
            className={` ${classes.Button}`}
            variant="contained"
            onClick={handleOpenContactModal}
          >
            Gestionar usuarios y conexiones
          </Button>
          </Grid>

          {/* <div className={classes.tableContainer}>
            <TableManager
              title="Facturas"
              columns={facturaColumns}
              data={facturaData}
              actionCellStyle={actionCellStyle}
              actionButtons={[
                ({ row }) => (
                  <Button>
                    <LinkIcon fill="#7F7F7F" />
                  </Button>
                )
              ]}
            />
          </div> */}

        </>
        :
        <>
          <div style={{ marginTop: "20px" }}>
            <PersonChatingImg />
          </div>
          <Typography >
            No cuentas con una suscripción activa
          </Typography>
          <Button
            className={` ${classes.Button}`}
            variant="contained"
            onClick={handleOpenContactModal}
          >
            Suscribirme ahora
          </Button>
        </>}
    </div>
  );
};

export default Suscripcion;