import React, { createContext, useReducer, useContext, useState, useCallback } from 'react';
import { AuthContext } from '../Auth/AuthContext';
import { getSocketConnection } from '../../services/socket';
import api from '../../services/api';
import imageDefaultUser from "../../assets/imageDefaultUser.webp";

export const ChatContext = createContext({
  unreadCount: 0,
  chats: [],
  dispatch: () => {},
  imageCache: {},
  getCachedImage: () => {},
  cacheImage: () => {}
});

const chatReducer = (state, action) => {
  switch (action.type) {
    case 'SET_UNREAD_COUNT':
      return {
        ...state,
        unreadCount: action.payload
      };
    case 'SET_CHATS':
      return {
        ...state,
        chats: action.payload
      };
    case 'UPDATE_CHAT':
      return {
        ...state,
        chats: state.chats.map(chat => {
          if (chat.id === action.payload.id) {
            return {
              ...chat,
              title: action.payload.title || chat.title,
              lastMessage: action.payload.lastMessage || chat.lastMessage,
              users: chat.users || action.payload.users,
              groupInfo: chat.groupInfo || action.payload.groupInfo,
              participants: chat.participants || action.payload.participants,
              updatedAt: new Date().toISOString(),
              lastMessage: action.payload.lastMessage,
              status: action.payload.status || chat.status
            };
          }
          return chat;
        }).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      };
    case 'UPDATE_CHAT_UNREADS':
      return {
        ...state,
        chats: state.chats.map(chat => {
          if (chat.id === action.payload.chatId) {
            return {
              ...chat,
              users: chat.users.map(user => {
                if (user.userId === action.payload.userId) {
                  return { ...user, unreads: 0 };
                }
                return user;
              })
            };
          }
          return chat;
        })
      };
    default:
      return state;
  }
};

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, {
    unreadCount: 0,
    chats: []
  });
  
  const [imageCache, setImageCache] = useState({});
  const { user } = useContext(AuthContext);

  const normalizeImagePath = useCallback((imagePath) => {
    if (!imagePath) return imageDefaultUser;
    if (imagePath.startsWith('data:image')) return imagePath;
    if (imagePath.startsWith('http')) return imagePath;
    return `${process.env.REACT_APP_BACKEND_URL}${imagePath.replace(/\\/g, '/')}`;
  }, []);

  const cacheImage = useCallback((userId, imageUrl) => {
    if (!userId || !imageUrl) return;
    setImageCache(prev => ({
      ...prev,
      [userId]: normalizeImagePath(imageUrl)
    }));
  }, [normalizeImagePath]);

  const getCachedImage = useCallback((userId) => {
    return imageCache[userId] || imageDefaultUser;
  }, [imageCache]);

  const loadInitialChats = async () => {
    try {
      const response = await api.get('/chats');
      console.log('Chats cargados:', response.data); // Para debugging
      
      if (response.data && Array.isArray(response.data.records)) {
        dispatch({
          type: 'SET_CHATS',
          payload: response.data.records
        });
        
        // Calcular mensajes no leídos
        const unreads = response.data.records.reduce((acc, chat) => {
          const currentUser = chat.users.find(u => u.userId === user.id);
          return acc + (currentUser?.unreads || 0);
        }, 0);
        
        dispatch({
          type: 'SET_UNREAD_COUNT',
          payload: unreads
        });
      } else {
        console.error('Formato de respuesta inválido:', response.data);
      }
    } catch (err) {
      console.error("Error cargando chats:", err);
    }
  };

  React.useEffect(() => {
    if (user?.id) {
      loadInitialChats();
    }
  }, [user?.id]); // Solo depender del ID del usuario

  React.useEffect(() => {
    if (!user?.id) return;

    // Cachear la imagen del usuario actual
    if (user.profile_image) {
      cacheImage(user.id, user.profile_image);
    }

    const socket = getSocketConnection(user.companyId);

    if (socket) {
      socket.on(`company-${user.companyId}-chat`, async (data) => {
        if (data.action === "new-message") {
          try {
            const existingChat = state.chats.find(chat => chat.id === data.chat.id);
            
            if (!existingChat) {
              console.error("Chat no encontrado:", data.chat.id);
              return;
            }

            const updatedChat = {
              ...existingChat,
              lastMessage: data.chat.lastMessage,
              status: data.chat.status || existingChat.status,
              title: existingChat.title,
              users: existingChat.users,
              groupInfo: existingChat.groupInfo,
              participants: existingChat.participants,
              updatedAt: new Date().toISOString()
            };
            
            dispatch({ 
              type: 'UPDATE_CHAT',
              payload: updatedChat
            });
            
            const currentChats = state.chats.map(chat => 
              chat.id === updatedChat.id ? updatedChat : chat
            );
            
            const unreads = currentChats.reduce((acc, chat) => {
              const currentUser = chat.users.find(u => u.userId === user.id);
              return acc + (currentUser?.unreads || 0);
            }, 0);
            
            dispatch({
              type: 'SET_UNREAD_COUNT',
              payload: unreads
            });
          } catch (err) {
            console.error("Error actualizando chat:", err);
          }
        }
      });

      return () => {
        socket.off(`company-${user.companyId}-chat`);
      };
    }
  }, [user?.id, user?.companyId, user?.profile_image, cacheImage, state.chats]);

  const markChatAsRead = async (chatId) => {
    if (!user?.id) return;
    
    try {
      await api.post(`/chats/${chatId}/read`, { userId: user.id });
      dispatch({
        type: 'UPDATE_CHAT_UNREADS',
        payload: { chatId, userId: user.id }
      });
    } catch (err) {
      console.error("Error marcando chat como leído:", err);
    }
  };

  return (
    <ChatContext.Provider value={{ 
      unreadCount: state.unreadCount, 
      chats: state.chats,
      dispatch,
      markChatAsRead,
      imageCache,
      getCachedImage,
      cacheImage,
      normalizeImagePath
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}; 