import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    height: '4px',
  },
  bar: {
    position: 'absolute',
    width: '100%',
    '& .MuiLinearProgress-bar': {
      transition: 'transform 0.1s linear',
    },
  },
  background: {
    backgroundColor: '#4CAF50',
    width: '100%',
    height: '4px',
    position: 'absolute',
    opacity: 0.2,
    borderRadius: '2px',
  },
  progress: {
    backgroundColor: '#FFD700',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FFD700',
      transition: 'none',
    },
  },
  label: {
    position: 'absolute',
    right: 0,
    top: '-20px',
    color: '#fff',
  }
});

export default function LinearWithValueLabel({ progress }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <LinearProgress
        variant="determinate"
        value={progress}
        className={`${classes.bar} ${classes.progress}`}
      />
      <Typography variant="body2" className={classes.label}>
        {`${Math.round(progress)}%`}
      </Typography>
    </div>
  );
}