const messages = {
  es: {
    translations: {
      signup: {
        title: "Registro",
        toasts: {
          success: "¡Usuario creado exitosamente!",
          fail: "Error al crear usuario. Comprueba los datos proporcionados.",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Registrar",
          login: "¿Ya tienes una cuenta? ¡Inicia sesión!"
        },
      },
      login: {
        title: "Iniciar Sesión",
        form: {
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Acceder",
          register: "¿No tienes una cuenta? ¡Regístrate!",
        },
      },
      companies: {
        title: "Registrar Empresa",
        form: {
          name: "Nombre de la empresa",
          plan: "Departamento",
          token: "Token",
          submit: "Registrar",
          success: "¡Empresa creada con éxito!",
        },
      },
      auth: {
        toasts: {
          success: "¡Inicio de sesión exitoso!",
        },
        token: "Token",
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Servicios hoy: ",
          },
        },
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted: "¡Conexión eliminada con éxito!",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "¿Estás seguro? ¡Esta acción no se puede revertir!",
          disconnectTitle: "Desconectar",
          disconnectMessage: "¿Estás seguro? Tendrás que escanear el código QR de nuevo.",
        },
        buttons: {
          add: "Agregar WhatsApp",
          disconnect: "Desconectar",
          tryAgain: "Intentar de nuevo",
          qrcode: "Código QR",
          newQr: "Conectar",
          connecting: "Conectando",
          ConfirmDeleting: "Desconectar",
          edit: "Editar"
        },
        toolTips: {
          disconnected: {
            title: "Falló la conexión con WhatsApp",
            content: "Asegúrate de que tu teléfono esté conectado a internet y vuelve a intentarlo, o solicita un nuevo código QR"
          },
          qrcode: {
            title: "Esperando lectura del código QR",
            content: "Haz clic en el botón 'Código QR' y escanea el código QR con tu teléfono para iniciar la sesión"
          },
          connected: {
            title: "¡Conexión establecida!"
          },
          timeout: {
            title: "Se perdió la conexión con el teléfono",
            content: "Asegúrate de que tu teléfono esté conectado a internet y WhatsApp esté abierto, o haz clic en el botón 'Desconectar' para obtener un nuevo código QR"
          }
        },
        table: {
          name: "Nombre",
          status: "Estado",
          lastUpdate: "Última actualización",
          default: "Predeterminado",
          actions: "Acciones",
          session: "Sesión",
        },
      },
      whatsappModal: {
        title: {
          add: "Agregar Whatsapp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nombre",
          default: "Marcar como predeterminado",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "WhatsApp se guardó correctamente.",
      },
      qrCode: {
        message: "Escanea el QrCode para iniciar la sesión",
      },
      contacts: {
        title: "Contactos",
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Eliminar",
          importTitle: "Importar contactos",
          deleteMessage:
            "¿Estás seguro de que deseas eliminar este contacto? Se perderán todos los servicios relacionados.",
          importMessage: "¿Quieres importar todos los contactos desde tu teléfono?",
        },
        buttons: {
          import: "Importar Contactos",
          importExcel: "Importar desde Excel",
          importPhone: "Importar desde el teléfono",
          add: "Agregar Contacto",
        },
        table: {
          name: "Nombre",
          whatsapp: "WhatsApp",
          email: "Correo Electrónico",
          actions: "Acciones",
        },
        toasts: {
          importSuccess: "Contactos importados correctamente.",
          deleted: "¡Contacto eliminado exitosamente!",
          importError: "Error al importar contactos."
        }
      },
      contactModal: {
        title: {
          add: "Agregar Contacto",
          edit: "Editar Contacto",
        },
        form: {
          mainInfo: "Detalles del Contacto",
          extraInfo: "Información Adicional",
          name: "Nombre",
          number: "Número de Whatsapp",
          email: "Correo Electrónico",
          extraName: "Nombre del campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Añadir información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar"
        },
        success: "Contacto guardado exitosamente.",
      },
      queueModal: {
        title: {
          add: "Agregar departamentos",
          edit: "Editar departamentos",
        },
        form: {
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
          complationMessage: "Mensaje de finalización",
          outOfHoursMessage: "Mensaje fuera de horario",
          ratingMessage:  "Mensaje de valoración" ,
          token: "Token",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
          profile: "Perfil",
          language: "Idioma",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Usuario guardado exitosamente.",
      },
      scheduleModal: {
        title: {
          add: "Nuevo horario",
          edit: "Editar horario",
        },
        form: {
          body: "Mensaje",
          contact: "Contacto",
          sendAt: "Fecha de programacion",
          sentAt: "Fecha de envío",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Mensaje guardado exitosamente.",
      },
      tagModal: {
        title: {
          add: "Nueva etiqueta",
          edit: "Editar etiqueta",
          addKanban: "Nuevo etapa",
          editKanban: "Editar etapa",
        },
        form: {
          name: "Nombre",
          color: "Color",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Etiqueta guardada exitosamente.",
        successKanban: "Etapa guardada con éxito.",
      },
      kanban: {
        title: "Embudos",
        searchPlaceholder: "Buscar",
        subMenus: {
          list: "Panel",
          tags: "Etapas - deprecable"
        }
      },
      tagsKanban: {
        title: "Etapas",
        laneDefault: "Tickets",
        confirmationModal: {
            deleteTitle: "Borrar etapa",
            deleteMessage: "¿Estás seguro de que desea borrar esta etapa?",
        },
        table: {
          name: "Nombre",
          color: "Color",
          tickets: "Tickets",
          actions: "Acciones",
        },
        buttons: {
          add: "Nueva etapa",
        },
        toasts: {
          deleted: "Etapa eliminada con éxito.",
        },
      },
      chat: {
        noTicketMessage: "Seleccione un ticket para comenzar a chatear.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "ARRASTRA Y SUELTA ARCHIVOS EN EL CAMPO A CONTINUACIÓN",
          titleFileList: "Lista de archivos"
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Departamentos",
      },
      tickets: {
        toasts: {
          deleted: "El servicio en el que estabas ha sido eliminado.",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Bandeja" },
          closed: { title: "Resuelto" },
          search: { title: "Buscar" },
        },
        search: {
          placeholder: "Buscar servicios y mensajes.",
        },
        buttons: {
          showAll: "Todo",
        },
      },
      transferTicketModal: {
        title: "Transferir chat",
        fieldLabel: "Escribe para buscar usuarios",
        fieldQueueLabel: "Transferir a departamentos",
        fieldQueuePlaceholder: "Seleccione una cola",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "En espera",
        assignedHeader: "Activos",
        noTicketsTitle: "¡Nada aquí!",
        noTicketsMessage:
          "No se encontró ningún servicio con este estado o término de búsqueda",
        buttons: {
          accept: "Aceptar",
        },
      },
      newTicketModal: {
        title: "Nuevo chat",
        fieldLabel: "Buscar contacto",
        add: "Agregar",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Analítica",
          connections: "Conexiones",
          tickets: "Chats",
          todolist: "Tareas",
          quickMessages: "Respuestas rápidas",
          contacts: "Contactos",
          queues: "Departamentos",
          tags: "Etiquetas",
          administration: "Configuración",
          users: "Equipo",
          settings: "Ajustes",
          helps: "Ayuda",
          messagesAPI: "API",
          schedules: "Programar mensajes",
          campaigns: "Marketing",
          annoucements: "Boletines",
          chats: "Chat interno",
          financeiro: "Mi suscripción",
          kanban: "Embudos",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Salir",
          },
        },
      },
      messagesAPI: {
        title: "API",
        textMessage: {
          number: "Número",
          body: "Mensaje",
          token: "Token registrado",
        },
        mediaMessage: {
          number: "Número",
          body: "Nombre del archivo",
          media: "Archivo",
          token: "Token registrado",
        },
      },
      notifications: {
        noTickets: "No hay notificaciones.",
      },
      quickMessages: {
        title: "Respuestas rápidas",
        table:{
          shortcode:"Atajo",
          message:"Mensaje"
        },
        buttons: {
          add: "Nueva respuesta",
        },
        dialog: {
          shortcode: "Atajo",
          message: "Respuesta",
        },
        toasts: {
          success: "Respuesta guardada con éxito.",
          successEdit: "Respuesta actualizada con éxito.",
          successDelete: "Respuesta eliminada con éxito.",
        },
      },
      contactLists: {
        title: "Listas de contactos",
        table: {
          name: "Nombre",
          contacts: "Contactos",
          actions: "Acciones",
        },
        buttons: {
          add: "Nueva lista",
        },
        dialog: {
          name: "Nombre",
          company: "Empresa",
          okEdit: "Editar",
          okAdd: "Agregar",
          add: "Agregar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        confirmationModal: {
          deleteTitle: "¿Desea eliminar esta lista de contactos?",
          deleteMessage: "¿Estás seguro? Esta acción no se puede revertir.",
        },
        toasts: {
          deleted: "Lista de contactos eliminada correctamente.",
          created: "Lista de contactos creada correctamente."
        },
      },
      contactListItems: {
        title: "Contactos",
        searchPlaceholder: "Buscar",
        buttons: {
          add: "Nuevo",
          lists: "Volver a las listas",
          import: "Importar",
        },
        dialog: {
          name: "Nombre",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "Correo electrónico",
          okEdit: "Editar",
          okAdd: "Agregar",
          add: "Agregar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        table: {
          name: "Nombre",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "Correo electrónico",
          actions: "Acciones",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "¿Estás seguro? Esta acción no se puede revertir.",
          importMessage: "¿Quieres importar contactos desde esta hoja de cálculo? ",
          importTitlte: "Importar",
        },
        toasts: {
          deleted: "Registro eliminado",
        },
      },
      campaigns: {
        title: "Campañas",
        searchPlaceholder: "Buscar",
        buttons: {
          add: "Nueva campaña",
          contactLists: "Listas de contactos",
        },
        table: {
          name: "Nombre",
          whatsapp: "Conexión",
          contactList: "Lista de contactos",
          status: "Estado",
          scheduledAt: "Programada",
          completedAt: "Completada",
          confirmation: "Confirmación",
          actions: "Acciones",
        },
        dialog: {
          new: "Nueva campaña",
          update: "Editar campaña",
          readonly: "Solo lectura",
          form: {
            name: "Nombre",
            message1: "Mensaje 1",
            message2: "Mensaje 2",
            message3: "Mensaje 3",
            message4: "Mensaje 4",
            message5: "Mensaje 5",
            confirmationMessage1: "Mensaje de confirmacion 1",
            confirmationMessage2: "Mensaje de confirmacion 2",
            confirmationMessage3: "Mensaje de confirmacion 3",
            confirmationMessage4: "Mensaje de confirmacion 4",
            confirmationMessage5: "Mensaje de confirmacion 5",
            messagePlaceholder: "Contenido del mensaje",
            whatsapp: "Conexión",
            status: "Estado",
            scheduledAt: "Programada",
            confirmation: "Confirmación",
            contactList: "Lista de contactos",
            instructions:"Utilice variables como {nombre}, {numero}, {email} o defina variables personalizadas."
          },
          buttons: {
            add: "Agregar",
            edit: "Actualizar",
            okadd: "Ok",
            cancel: "Cancelar envío",
            restart: "Reiniciar envío",
            close: "Cerrar",
            attach: "Adjuntar archivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "¿Estás seguro? Esta acción no se puede revertir.",
        },
        toasts: {
          success: "Nueva campaña creada con éxito.",
          cancel: "Campaña cancelada",
          restart: "Campaña reiniciada",
          deleted: "Registro eliminado",
        },
      },
      announcements: {
        title: "Boletines",
        searchPlaceholder: "Buscar",
        buttons: {
          add: "Nuevo boletín",
          contactLists: "Listas de noticias",
        },
        table: {
          priority: "Prioridad",
          title: "Título",
          text: "Texto",
          mediaName: "Archivo",
          status: "Estado",
          actions: "Acciones",
          noAttachment:"Sin anexo"
        },
        priority:{
          high:"Alto",
          medium:"Medio",
          low:"Bajo"
        },
        status:{
          active:"Activo",
          inactive:"Inactivo"
        },
        dialog: {
          edit: "Edición del boletín",
          add: "Nuevo boletín",
          update: "Editar boletín",
          readonly: "Solo lectura",
          form: {
            priority: "Prioridad",
            title: "Título",
            text: "Texto",
            mediaPath: "Archivo",
            status: "Estado",
          },
          buttons: {
            add: "Agregar",
            edit: "Actualizar",
            okadd: "Ok",
            cancel: "Cancelar",
            close: "Cerrar",
            attach: "Adjuntar archivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "¿Estás seguro? Esta acción no se puede revertir.",
        },
        toasts: {
          success: "Operación exitosa",
          deleted: "Registro eliminado",
        },
      },
      campaignsConfig: {
        title: "Configuración de campaña",
      },
      queues: {
        title: "Departamentos",
        table: {
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de saludo",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar departamentos",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
           "¿Estás seguro? ¡Esta acción no se puede revertir! Los servicios en esta cola seguirán existiendo, pero ya no tendrán ninguna cola asignada."
        },
        toast:{
          delete: "El departamento se ha eliminado correctamente.",
          create: "Se ha creado el departamento correctamente."
        }
      },
      queueSelect: {
        inputLabel: "Departamentos",
      },
      users: {
        title: "Usuarios",
        table: {
          name: "Nombre",
          email: "Correo electrónico",
          profile: "Perfil",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar usuario"
        },
        toasts: {
          deleted: "Usuario eliminado exitosamente.",
          cantDeleteYourself:"No puedes eliminar tu propia cuenta"
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "Todos los datos del usuario se perderán. Las llamadas abiertas de este usuario se moverán a la cola.",
        },
      },
      helps: {
        title: "Centro de ayuda",
      },
      schedules: {
        title: "Programar mensajes",
        confirmationModal: {
          deleteTitle: "¿Está seguro de que desea eliminar este mensaje programado?",
          deleteMessage: "Esta acción no se puede revertir.",
        },
        table: {
          contact: "Contacto",
          body: "Mensaje",
          sendAt: "Fecha de programación",
          sentAt: "Fecha de envío",
          status: "Estado",
          actions: "Acciones",
        },
        buttons: {
          add: "Nuevo mensaje",
        },
        toasts: {
          deleted: "Programación eliminada exitosamente.",
        },
      },
      tags: {
        title: "Etiquetas",
        confirmationModal: {
          deleteTitle: "¿Estás seguro de que deseas eliminar esta etiqueta?",
          deleteMessage: "Esta acción no se puede revertir.",
        },
        table: {
          name: "Nombre",
          color: "Color",
          tickets: "Registros etiquetados",
          actions: "Acciones",
        },
        buttons: {
          add: "Nueva etiqueta",
        },
        toasts: {
          deleted: "Etiqueta eliminada correctamente.",
        },
      },
      settings: {
        success: "La configuración se guardó correctamente.",
        title: "Ajustes",
        settings: {
          userCreation: {
            name: "Creación de usuarios",
            options: {
              enabled: "Activado",
              disabled: "Desactivado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Asignado a ",
          buttons: {
            return: "Volver",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceptar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Mensaje",
        placeholderClosed:
          "Vuelva a abrir o acepte este ticket para enviar un mensaje.",
        signMessage: "firmar",
      },
      contactDrawer: {
        header: "Detalles de contacto",
        buttons: {
          edit: "Editar contacto",
        },
        extraInfo: "Otras informaciones",
      },
      ticketOptionsMenu: {
        schedule: "Programar",
        edit:"Editar",
        delete: "Eliminar",
        transfer: "Transferir",
        registerAppointment: "Registrar notas",
        appointmentsModal: {
          title: "Notas de contacto",
          textarea: "Observación",
          placeholder: "Ingresa aquí los datos que deseas registrar",
        },
        confirmationModal: {
          title: "Eliminar el chat del contacto #",
          titleFrom: "De",
          message:
            "¡Atención! Se perderán todos los mensajes relacionados con el ticket.",
        },
        buttons: {
          delete: "Eliminar",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Sí, borrar",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Eliminar",
        reply: "Responder",
        confirmationModal: {
          title: "Eliminar mensaje?",
          message: "Esta acción no se puede revertir.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Debe existir al menos una cuenta de WhatsApp predeterminada.",
        ERR_NO_DEF_WAPP_FOUND:
          "No se encontró ninguna cuenta de WhatsApp predeterminada. Revisa la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sesión de WhatsApp no se ha iniciado. Revisa la página de conexiones.",
        ERR_WAPP_CHECK_CONTACT:
          "No se puede verificar el contacto de WhatsApp. Revisa la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de WhatsApp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "No se pueden descargar medios de WhatsApp. Consulta la página de conexiones.",
        ERR_INVALID_CREDENTIALS:
          "Error de autenticación. Inténtalo de nuevo.",
        ERR_SENDING_WAPP_MSG:
          "Error al enviar mensaje de WhatsApp. Consulta la página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se puede eliminar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
        ERR_SESSION_EXPIRED: "Sesión expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "El administrador ha desactivado la creación de usuarios.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
        ERR_NO_CONTACT_FOUND: "No se encontraron contactos con este ID.",
        ERR_NO_TICKET_FOUND: "No se encontraron boletos con esta identificación.",
        ERR_NO_USER_FOUND: "No se encontraron usuarios con este ID.",
        ERR_NO_WAPP_FOUND: "No se encontró ningún WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al crear ticket en la base de datos.",
        ERR_FETCH_WAPP_MSG:
          "Error al buscar el mensaje en WhtasApp, quizás sea demasiado antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Este color ya está en uso. Por favor, elige otro.",
        ERR_QUEUE_NAME_ALREADY_EXISTS:
          "El nombre del departamento ya está en uso. Por favor, elige otro.",
        ERR_WAPP_GREETING_REQUIRED:
          "El mensaje de saludo es obligatorio cuando hay más de un departamento.",
        ERR_QUICKMESSAGE_SHORTCODE_ALREADY_EXISTS: "Este atajo ya existe",
      },
      selectChannel: {
        title: "Seleccionar canal"
      },
      webChatModal: {
        title: {
          add: "Agregar WebChat",
          edit: "Editar WebChat"
        },
        form: {
          name: "Nombre",
          greetingMessage: "Mensaje de bienvenida",
          farewellMessage: "Mensaje de despedida",
          outOfHoursMessage: "Mensaje fuera de horario",
          isDefault: "Establecer como predeterminado"
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar"
        },
        success: {
          create: "WebChat creado con éxito!",
          update: "WebChat actualizado con éxito!"
        }
      },
    },
  },
};

export { messages };
