import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Grid,
  Card,
  Button,
} from "@material-ui/core";
import CloseIcon from "../../assets/icon/CloseIcon";
import { i18n } from "../../translate/i18n";

// Importar las imágenes
import messengerIcon from "../../assets/img/messenger.png";
import whatsappIcon from "../../assets/img/whatsapp.png";
import instagramIcon from "../../assets/img/instagram.png";
import smsIcon from "../../assets/img/chat.png";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    background: theme.palette.tabHeaderBackground,
    overflow: "hidden"
  },
  dialogContent: {
    padding: theme.spacing(2),
    overflow: "hidden"
  },
  channelCard: {
    cursor: "pointer",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    transition: "all 0.3s",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: theme.shadows[4],
    },
  },
  channelIcon: {
    width: 48,
    height: 48,
    marginBottom: theme.spacing(1),
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  },
  channelName: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    fontSize: "1rem"
  },
  channelDescription: {
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: 10,
    fontSize: "0.875rem",
    lineHeight: "1.2"
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#ff1744",
  },
  gridContainer: {
    margin: 0,
    width: "100%"
  },
  comingSoon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    background: theme.palette.primary.main,
    color: "white",
    padding: "4px 8px",
    borderRadius: 4,
    fontSize: "0.75rem",
  },
}));

const SelectChannelModal = ({ open, onClose, onSelect }) => {
  const classes = useStyles();

  const channels = [
    {
      id: "whatsapp",
      name: "WhatsApp",
      description: "Conecta la Plataforma WhatsApp Business (API) a través de Facebook para habilitar la interacción y soporte fluido con el cliente.",
      icon: whatsappIcon,
      aviable: true,
    },
    {
      id: "webchat",
      name: "Web Chat",
      description: "Integra un widget de chat en tu sitio web para que tus clientes puedan comunicarse directamente contigo desde tu página. Todas las conversaciones llegarán a tu bandeja de Ventty.",
      icon: smsIcon,
      aviable: false,
      comingSoon: true,
    },
    {
      id: "messenger",
      name: "Facebook Messenger",
      description: "Conecta Facebook Messenger para interactuar con tus clientes en la plataforma de redes sociales más grande del mundo.",
      icon: messengerIcon,
      comingSoon: true,
    },
    {
      id: "instagram",
      name: "Instagram",
      description: "Conecta Instagram para responder mensajes privados y construir conexiones sólidas con tu marca.",
      icon: instagramIcon,
      comingSoon: true,
    },
  ];

  const handleChannelSelect = (channel) => {
    if (!channel.comingSoon) {
      onSelect(channel.id);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
    >
      <DialogTitle className={classes.modalContainer}>
        {i18n.t("selectChannel.title")}
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.modalContainer} ${classes.dialogContent}`}>
        <Grid container spacing={2} className={classes.gridContainer}>
          {channels.map((channel) => {
            return (
              <Grid item xs={12} sm={6} key={channel.id}>
                <Card 
                  className={classes.channelCard}
                  onClick={() => handleChannelSelect(channel)}
                >
                  <div className={classes.channelIcon}>
                    <img src={channel.icon} alt={channel.name} />
                  </div>
                  <Typography variant="h6" className={classes.channelName}>
                    {channel.name}
                  </Typography>
                  <Typography variant="body2" className={classes.channelDescription}>
                    {channel.description}
                  </Typography>
                  <Button 
                    variant={channel.comingSoon ? "outlined" : "contained"}
                    color="primary"
                    disabled={channel.comingSoon}
                    size="small"
                  >
                    {channel.comingSoon ? "Próximamente" : "Conectar"}
                  </Button>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SelectChannelModal; 