import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import { Paper, makeStyles } from "@material-ui/core";
import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInputCustom/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtonsCustom";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TagsContainer } from "../TagsContainer";
import { socketConnection } from "../../services/socket";
import ArrowLeftIcon from "../../assets/icon/ArrowLeftIcon";
import backgroundChat from '../../assets/img/backgroundChat.png';
import { TicketsContext } from "../../context/Tickets/TicketsContext";
const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    width: "100%",
    marginRight: -drawerWidth,
    backgroundImage: `url(${backgroundChat})`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  containerHeader: {
    margin: "0 10px 0 10px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
    },
  },
}));

const Ticket = ({ reloadTickets }) => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const { getCurrentTicketInfo, ticketsList, setTicketsList } = useContext(TicketsContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(true);
  const [loadingTags, setLoadingTags] = useState(false);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});

  useEffect(() => {
    if (!ticketId) return;

    const fetchTicketData = async () => {
      try {
        setLoadingTicket(true);
        
        // Primero intentamos obtener del contexto
        const ticketExists = ticketsList.some(t => t.uuid === ticketId);
        if (!ticketExists) {
          history.push("/tickets");
          return;
        }

        const contextTicket = getCurrentTicketInfo();
        const cachedTicket = ticketsList.find(t => t.uuid === ticketId);
        const ticketToUse = cachedTicket || contextTicket;

        if (ticketToUse) {

          setContact(ticketToUse.contact);
          setTicket(ticketToUse);
          
          // Solo hacemos fetch de las etiquetas si no las tenemos
          if (!ticketToUse.tags) {
            setLoadingTags(true);
            const { data } = await api.get(`/tickets/u/${ticketToUse.uuid}`);
            
            setTicket(prevTicket => ({
              ...prevTicket,
              tags: data.tags
            }));

            setTicketsList(prevTickets => {
              return prevTickets.map(t => 
                t.uuid === ticketId ? { ...t, tags: data.tags } : t
              );
            });
            setLoadingTags(false);
          }
        } else {
          // Si no está en el contexto, hacemos una única petición
          const { data } = await api.get(`/tickets/u/${ticketId}`);
          setContact(data.contact);
          setTicket(data);
          setTicketsList(prevTickets => [data, ...prevTickets]);
        }
      } catch (err) {
        toastError(err);
      } finally {
        setLoadingTicket(false);
      }
    };

    fetchTicketData();
  }, [ticketId]); // Solo depende del ticketId

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const renderTicketInfo = () => {
    if (ticket?.id && contact?.id) {
      return (
        <TicketInfo
          contact={contact}
          ticket={ticket}
          onClick={handleDrawerOpen}
        />
      );
    }
    return null;
  };

  const renderMessagesList = () => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
        ></MessagesList>
        <MessageInput
          ticketId={ticket.id}
          contactId={ticket.contactId}
          ticketStatus={ticket.status}
        />
      </>
    );
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <div className={classes.containerHeader}>
          <TicketHeader loading={loadingTicket}>
            {renderTicketInfo()}
            <TicketActionButtons
              ticket={ticket}
              handleDrawerOpen={handleDrawerOpen}
              reloadTickets={reloadTickets}
            />
          </TicketHeader>
          {!loadingTicket && <TagsContainer ticket={ticket} loading={loadingTags} />}
        </div>
        <ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loadingTicket}
        ticket={ticket}
      />
    </div>
  );
};

export default Ticket;
