import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
  Tooltip,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import CloseIcon from "../../assets/icon/CloseIcon";
import ContentCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  tokenContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  scriptBox: {
    backgroundColor: "#f5f5f5", 
    padding: 8, 
    borderRadius: 4, 
    overflow: "auto",
    border: '1px solid #ddd',
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    marginTop: 8,
  },
  scriptTabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  customizationField: {
    marginBottom: theme.spacing(2),
  },
}));

const WebChatSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

// Componente para mostrar una pestaña
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

const WebChatModal = ({ open, onClose, webChatId }) => {
  const classes = useStyles();
  const [queues, setQueues] = useState([]);
  const [webChat, setWebChat] = useState(null);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [customOptions, setCustomOptions] = useState({
    position: 'right',
    color: '#4285f4',
    title: 'Chat de Soporte',
    subtitle: 'Estamos aquí para ayudarte',
    welcomeMessage: '¡Hola! ¿En qué podemos ayudarte?',
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!webChatId) {
      setWebChat(null);
      setSelectedQueueIds([]);
      return;
    }
    setLoading(true);
    const fetchWebChat = async () => {
      try {
        const { data } = await api.get(`/whatsapp/${webChatId}`);
        setWebChat(data);
        setSelectedQueueIds(data.queues?.map((queue) => queue.id) || []);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchWebChat();
  }, [webChatId]);

  const handleClose = () => {
    onClose();
    setWebChat(null);
  };

  const handleSaveWebChat = async (values) => {
    try {
      setLoading(true);
      if (webChatId) {
        const { data } = await api.put(`/whatsapp/${webChatId}`, {
          ...values,
          queueIds: selectedQueueIds,
          channel: "webchat"
        });
        setWebChat(data);
        toast.success(i18n.t("webChatModal.success.update"));
        setLoading(false);
        return;
      } else {
        const { data } = await api.post("/whatsapp", {
          ...values,
          queueIds: selectedQueueIds,
          channel: "webchat",
          status: "CONNECTED",
          provider: "web"
        });
        setWebChat(data);
        toast.success(i18n.t("webChatModal.success.create"));
        setLoading(false);
        return;
      }
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleQueueChange = (queueIds) => {
    setSelectedQueueIds(queueIds);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Token copiado al portapapeles");
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCustomOptionChange = (option, value) => {
    setCustomOptions({
      ...customOptions,
      [option]: value
    });
  };

  // Genera el script básico con solo el token
  const generateBasicScript = () => {
    const cdnUrl = window.location.origin;
    return `<script src="${cdnUrl}/widget-inject.js" 
  data-company-id="${webChat?.companyId || ''}" 
  data-token="${webChat?.token || ''}">
</script>`;
  };

  // Genera el script con opciones de personalización
  const generateCustomScript = () => {
    const cdnUrl = window.location.origin;
    return `<script src="${cdnUrl}/widget-inject.js" 
  data-company-id="${webChat?.companyId || ''}" 
  data-token="${webChat?.token || ''}"
  data-position="${customOptions.position}"
  data-color="${customOptions.color}"
  data-title="${customOptions.title}"
  data-subtitle="${customOptions.subtitle}"
  data-welcome="${customOptions.welcomeMessage}">
</script>`;
  };

  const renderTokenSection = () => {
    if (!webChat || !webChat.token) return null;

    return (
      <Paper className={classes.tokenContainer} style={{ marginTop: 16, padding: 16, marginBottom: 16 }}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#4285f4' }}>
          Token de conexión
        </Typography>
        <Typography variant="body2" gutterBottom>
          Usa este token para conectar tu widget de chat con Venttify:
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={webChat?.token || ''}
              InputProps={{
                readOnly: true,
                style: { backgroundColor: '#f5f5f5' }
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Copiar token">
              <IconButton
                size="small"
                onClick={() => copyToClipboard(webChat?.token || '')}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {/* Pestañas para básico y personalizado */}
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          indicatorColor="primary"
          textColor="primary"
          className={classes.scriptTabs}
        >
          <Tab label="Script Básico" />
          <Tab label="Script Personalizable" />
        </Tabs>

        {/* Panel para script básico */}
        <TabPanel value={tabValue} index={0}>
          <Typography variant="body2">
            Copia este script básico para integrar el chat en tu sitio web:
          </Typography>
          <div className={classes.scriptBox}>
            {generateBasicScript()}
          </div>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ContentCopyIcon />}
            onClick={() => copyToClipboard(generateBasicScript())}
            style={{ marginTop: 8 }}
          >
            Copiar Script
          </Button>
        </TabPanel>

        {/* Panel para script personalizable */}
        <TabPanel value={tabValue} index={1}>
          <Typography variant="body2" gutterBottom>
            Personaliza tu widget y copia el script:
          </Typography>
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className={classes.customizationField}>
              <TextField
                fullWidth
                label="Posición"
                select
                SelectProps={{ native: true }}
                value={customOptions.position}
                onChange={(e) => handleCustomOptionChange('position', e.target.value)}
                variant="outlined"
                size="small"
              >
                <option value="right">Derecha</option>
                <option value="left">Izquierda</option>
              </TextField>
            </Grid>
            
            <Grid item xs={12} sm={6} className={classes.customizationField}>
              <TextField
                fullWidth
                label="Color Principal"
                type="color"
                value={customOptions.color}
                onChange={(e) => handleCustomOptionChange('color', e.target.value)}
                variant="outlined"
                size="small"
              />
            </Grid>
            
            <Grid item xs={12} sm={6} className={classes.customizationField}>
              <TextField
                fullWidth
                label="Título del Chat"
                value={customOptions.title}
                onChange={(e) => handleCustomOptionChange('title', e.target.value)}
                variant="outlined"
                size="small"
              />
            </Grid>
            
            <Grid item xs={12} sm={6} className={classes.customizationField}>
              <TextField
                fullWidth
                label="Subtítulo"
                value={customOptions.subtitle}
                onChange={(e) => handleCustomOptionChange('subtitle', e.target.value)}
                variant="outlined"
                size="small"
              />
            </Grid>
            
            <Grid item xs={12} className={classes.customizationField}>
              <TextField
                fullWidth
                label="Mensaje de Bienvenida"
                value={customOptions.welcomeMessage}
                onChange={(e) => handleCustomOptionChange('welcomeMessage', e.target.value)}
                variant="outlined"
                size="small"
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
          
          <Typography variant="body2" style={{ marginTop: 8 }}>
            Script personalizado:
          </Typography>
          <div className={classes.scriptBox}>
            {generateCustomScript()}
          </div>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ContentCopyIcon />}
            onClick={() => copyToClipboard(generateCustomScript())}
            style={{ marginTop: 8 }}
          >
            Copiar Script Personalizado
          </Button>
          
          <Typography variant="body2" style={{ marginTop: 16 }}>
            Para probar tu widget, puedes visitar nuestra <a href="/widget-test.html" target="_blank" rel="noopener noreferrer">página de prueba</a>.
          </Typography>
        </TabPanel>
      </Paper>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        {webChatId
          ? `${i18n.t("webChatModal.title.edit")}`
          : `${i18n.t("webChatModal.title.add")}`}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={webChatId ? webChat : {
          name: "",
          greetingMessage: "",
          farewellMessage: "",
          outOfHoursMessage: "",
          isDefault: false,
        }}
        enableReinitialize={true}
        validationSchema={WebChatSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveWebChat(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <Form>
            <DialogContent dividers>
              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {webChat && webChat.token && (
                    <div style={{ marginBottom: '20px' }}>
                      {renderTokenSection()}
                    </div>
                  )}
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("webChatModal.form.name")}
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <QueueSelect
                        selectedQueueIds={selectedQueueIds}
                        onChange={handleQueueChange}
                        queues={queues}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("webChatModal.form.greetingMessage")}
                        name="greetingMessage"
                        error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                        helperText={touched.greetingMessage && errors.greetingMessage}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("webChatModal.form.farewellMessage")}
                        name="farewellMessage"
                        error={touched.farewellMessage && Boolean(errors.farewellMessage)}
                        helperText={touched.farewellMessage && errors.farewellMessage}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("webChatModal.form.outOfHoursMessage")}
                        name="outOfHoursMessage"
                        error={touched.outOfHoursMessage && Boolean(errors.outOfHoursMessage)}
                        helperText={touched.outOfHoursMessage && errors.outOfHoursMessage}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            color="primary"
                            name="isDefault"
                            checked={values.isDefault}
                          />
                        }
                        label={i18n.t("webChatModal.form.isDefault")}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                disabled={isSubmitting}
                variant="outlined"
              >
                {i18n.t("webChatModal.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
                className={classes.btnWrapper}
              >
                {webChatId
                  ? i18n.t("webChatModal.buttons.okEdit")
                  : i18n.t("webChatModal.buttons.okAdd")}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default WebChatModal; 