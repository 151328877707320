import React, { useState, useEffect, useContext, memo, useMemo } from "react";
import {
  Avatar,
  useMediaQuery,
  useTheme,
  CardHeader,
  Box,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ArrowLeftIcon from "../../assets/icon/ArrowLeftIcon";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TicketsContext } from "../../context/Tickets/TicketsContext";

const useStyles = makeStyles((theme) => ({
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: -20,
    },
  },
  avatar: {
    pointerEvents: 'none',
  },
}));

const TicketInfo = memo(({ contact, ticket, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMdOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const { setCurrentTicket } = useContext(TicketsContext);

  const contactName = useMemo(() => {
    if (!contact?.name) return "";
    
    if (document.body.offsetWidth < 600 && contact.name.length > 10) {
      return contact.name.substring(0, 10) + "...";
    }
    return contact.name;
  }, [contact?.name]);

  const userName = useMemo(() => {
    if (!ticket?.user || !contact) return "";
    
    return document.body.offsetWidth < 600
      ? ticket.user.name
      : `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`;
  }, [ticket?.user, contact]);

  const handleBackClick = React.useCallback((event) => {
    event.stopPropagation();
    setCurrentTicket({ id: null, code: null });
    history.push('/tickets');
  }, [history, setCurrentTicket]);

  if (!ticket?.id || !contact?.id) {
    return null;
  }

  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true, style: { color: "#959595" } }}
      avatar={
        <Box className={classes.avatarBox}>
          {isMdOrSmaller && (
            <IconButton onClick={handleBackClick}>
              <ArrowLeftIcon fill="#AEADAC" />
            </IconButton>
          )}
          <Avatar
            src={contact.profilePicUrl}
            alt="contact_image"
            className={classes.avatar}
          >
            {contact.name ? contact.name[0].toUpperCase() : "?"}
          </Avatar>
        </Box>
      }
      title={contactName}
      subheader={ticket.user && userName}
    />
  );
}, (prevProps, nextProps) => {
  // Solo re-renderizar si cambian datos importantes
  return (
    prevProps.contact?.id === nextProps.contact?.id &&
    prevProps.contact?.name === nextProps.contact?.name &&
    prevProps.contact?.profilePicUrl === nextProps.contact?.profilePicUrl &&
    prevProps.ticket?.id === nextProps.ticket?.id &&
    prevProps.ticket?.user?.id === nextProps.ticket?.user?.id &&
    prevProps.ticket?.user?.name === nextProps.ticket?.user?.name
  );
});

export default TicketInfo;