import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";
import { Tooltip, Badge } from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoodIcon from "@material-ui/icons/Mood";
import MessageIcon from "@material-ui/icons/Message";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
  Typography,
  useMediaQuery,
  Modal,
  LinearProgress,
  Popover,
  Drawer,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isString, isEmpty, isObject, has } from "lodash";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";

import useQuickMessages from "../../hooks/useQuickMessages";

import Compressor from "compressorjs";
import LinearWithValueLabel from "./ProgressBarCustom";
import { Event } from "@material-ui/icons";
import ScheduleModal from "../ScheduleModal";
import EmoteIcon from "../../assets/icon/EmoteIcon";
import PaperClipIcon from "../../assets/icon/PaperClipIcon";
import DocumentTextIcon from "../../assets/icon/DocumentTextIcon";
import CalendarIcon from "../../assets/icon/CalendarIcon";
import CustomSwitch from "../SwitchCustom/SwitchCustom";
import SuccessIcon from "../../assets/icon/SuccessIcon";
import ErrorIcon from "../../assets/icon/ErrorIcon";
import SendIcon from "../../assets/icon/SendIcon";
import SquareAddIcon from "../../assets/icon/SquareAddIcon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { getSocketConnection } from "../../services/socket";
import WhisperIcon from '@material-ui/icons/VolumeDown';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    minHeight: 60,
    width: "100%",
  },

  mainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
  },

  newMessageBox: {
    background: theme.palette.background.default,
    width: "100%",
    display: "flex",
    padding: "10px 15px",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
    },
    "& > .MuiInputBase-root": {
      display: "flex !important",
      width: "100%"
    }
  },

  messageInputWrapper: {
    padding: 8,
    marginRight: 10,
    flex: 1,
    background: theme.palette.inputMessage,
    fontSize: "1",
    display: "flex",
    borderRadius: 20,
    width: "100%",
    minHeight: 44,
  },

  messageInput: {
    paddingLeft: 12,
    paddingRight: 12,
    flex: 1,
    width: "100%",
    border: "none",
    fontSize: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },

  placeholderText: {
    position: "absolute",
    left: 10,
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "12px",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    pointerEvents: "none",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.total,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  SendButton: {
    background: theme.palette.primary.main,
    height: 45,
    width: 45,
    padding: 0,
    minWidth: "10px !important",
    borderRadius: 22.5,
    marginLeft: 10,
    boxShadow: "none",
    border: "none",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 40,
      width: 40,
      borderRadius: 20,
    },
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center"
  },

  quickMessagesBox: {
    position: "absolute",
    bottom: "70px",
    left: "8px",
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
    overflowX: "auto",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
    background: theme.palette.background.default,
    padding: "10px",
  },
  quickMessageItem: {
    cursor: "pointer",
    padding: "8px",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
  emojiBoxMobile: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },
  quickMessagesBoxMobile: {
    position: "absolute",
    bottom: "70px",
    left: "8px",
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
    overflowX: "auto",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
    background: theme.palette.background.default,
    padding: "10px",
  },

  previewModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },

  previewModalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '80%',
    maxHeight: '80vh',
    outline: 'none',
    borderRadius: '10px',
    padding: '20px',
    marginTop: '40px',
  },

  previewImage: {
    maxWidth: '100%',
    maxHeight: 'calc(80vh - 120px)',
    objectFit: 'contain',
    borderRadius: '8px',
  },

  previewActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '20px',
    gap: '10px',
  },

  previewInfo: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    flex: 1,
  },

  fileName: {
    fontSize: '16px',
    color: '#fff',
    marginBottom: '4px',
    wordBreak: 'break-word',
  },

  fileSize: {
    fontSize: '14px',
    color: 'rgba(255, 255, 255, 0.7)',
  },

  actionButtons: {
    display: 'flex',
    gap: '10px',
  },

  sendButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '8px 16px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(255, 255, 255, 0.3)',
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },

  cancelButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: '#fff',
    padding: '8px 16px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },

  progressBar: {
    width: '100%',
    marginTop: '16px',
    height: 4,
    borderRadius: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },

  filePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 120px)',
  },

  whisperButton: {
    color: theme.palette.text.secondary,
  },

  whisperActiveButton: {
    color: theme.palette.success.main,
    background: 'rgba(76, 175, 80, 0.1)',
    '&:hover': {
      background: 'rgba(76, 175, 80, 0.2)',
    },
  },

  messageActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },

  messageActionsButton: {
    color: theme.palette.text.secondary,
  },

  actionIcon: {
    fontSize: 20,
  },

  whisperModeIndicator: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px',
    margin: '0 auto 8px',
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    backgroundColor: 'rgba(76, 175, 80, 0.08)',
    color: theme.palette.success.main,
    zIndex: 2,
  },

  actionMenu: {
    padding: theme.spacing(1),
  },
  
  actionMenuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
  },
  
  actionMenuItemIcon: {
    marginRight: theme.spacing(1),
  },

  menuButton: {
    color: theme.palette.text.secondary,
    margin: '0 5px',
  },
}));

const EmojiOptions = (props) => {
  const { disabled, showEmoji, setShowEmoji, handleAddEmoji, menu } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEmojiSelect = (emoji) => {
    handleAddEmoji(emoji);
    setTimeout(() => {
      setShowEmoji(false);
    }, 100);
  };

  const getEmojiPerLine = () => {
    if (isSmallScreen) return 8;
    if (isMediumScreen) return 12;
    return 16; 
  };

  const handleClickOutside = (e) => {
    const emojiPicker = document.querySelector('.emoji-mart');
    const emojiButton = document.querySelector('[aria-label="emojiPicker"]');
    
    if (
      emojiPicker && 
      !emojiPicker.contains(e.target) && 
      emojiButton && 
      !emojiButton.contains(e.target)
    ) {
      setShowEmoji(false);
    }
  };

  useEffect(() => {
    if (showEmoji) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmoji]);

  return (
    <>
      <IconButton
        aria-label="emojiPicker"
        component="span"
        disabled={disabled}
        onClick={() => setShowEmoji((prevState) => !prevState)}
        style={isSmallScreen && !menu ? { display: "none" } : {}}
      >
        <EmoteIcon />
      </IconButton>
      {showEmoji ? (
        <div className={isSmallScreen ? classes.emojiBoxMobile : classes.emojiBox}>
          <Picker
            perLine={getEmojiPerLine()} 
            showPreview={false}
            showSkinTones={false}
            onSelect={handleEmojiSelect}
          />
        </div>
      ) : null}
    </>
  );
};

const QuickMessages = (props) => {
  const {
    disabled,
    showMessage,
    setShowMessage,
    handleAddQuickMessage,
    quickMessages,
    menu,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleQuickMessageSelect = (message) => {
    handleAddQuickMessage(message);
    setShowMessage(false); // Cierra el modal después de seleccionar un mensaje rápido
  };

  // Agregar manejador para clicks fuera del componente
  const handleClickOutside = (e) => {
    if (!e.target.closest('.'+classes.quickMessagesBox) && 
        !e.target.closest('[aria-label="quickMessages"]')) {
      setShowMessage(false);
    }
  };

  useEffect(() => {
    if (showMessage) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMessage]);

  return (
    <>
      <IconButton
        aria-label="quickMessages"
        component="span"
        disabled={disabled}
        onClick={(e) => setShowMessage((prevState) => !prevState)}
        style={isSmallScreen & !menu ? { display: "none" } : {}}
      >
        <DocumentTextIcon />
      </IconButton>
      {showMessage ? (
        <div className={classes.quickMessagesBox}>
          {quickMessages.map((item) => (
            <div
              key={item.id}
              className={classes.quickMessageItem}
              onClick={() => handleQuickMessageSelect(item.message)}
            >
              {item.shortcode}: {truncateText(item.message, 94)}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

const SignSwitch = (props) => {
  const { setSignMessage, signMessage, menu, small, marginLeft } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <FormControlLabel
      style={
        isSmallScreen & !menu
          ? { display: "none" }
          : { marginLeft: 10, color: theme.palette.text.secondary }
      }
      label={i18n.t("messagesInput.signMessage")}
      labelPlacement="end"
      control={
        <CustomSwitch
          size={small}
          checked={signMessage}
          onChange={(e) => {
            setSignMessage(e.target.checked);
          }}
          name="showAllTickets"
          color="primary"
          marginTop={-1}
          marginLeft={marginLeft}
        />
      }
    />
  );
};

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

const FileInput = (props) => {
  const { handleChangeMedias, disableOption, menu } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }

    const maxSize = 10 * 1024 * 1024; // 10MB en bytes

    for (let file of Array.from(e.target.files)) {
      if (file.size > maxSize) {
        toastError(`El archivo ${file.name} excede el límite de 15MB permitido`);
        e.target.value = ''; // Limpiar input
        return;
      }
    }

    handleChangeMedias(e);
  };

  return (
    <>
      <input
        multiple
        type="file"
        id="upload-button"
        disabled={disableOption()}
        className={classes.uploadInput}
        onChange={handleFileChange}
      />
      <label htmlFor="upload-button" style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: -10 }}>
        <IconButton
          aria-label="upload"
          component="span"
          disabled={disableOption()}
          style={isSmallScreen & !menu ? { display: "none" } : {}}
        >
          <PaperClipIcon />
        </IconButton>
        <span style={{ marginLeft: 10 }}>Adjuntar Archivo</span>
      </label>
    </>
  );
};

const ScheduleEventButton = ({ contactId, menu, handleMenuClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
    if (handleMenuClose) {
      handleMenuClose();
    }
  };

  const handleModalClose = (event, reason) => {
    if (reason === 'backdropClick') {
      setOpen(false);
    }
    setOpen(false); // Asegura que el modal se cierre en todos los casos
  };

  return (
    <>
      <IconButton
        aria-label="schedule-event"
        component="span"
        onClick={handleOpenModal}
        className={classes.scheduleButton}
        style={isSmallScreen && !menu ? { display: "none" } : {fontSize: 15, marginRight: theme.spacing(1)} }
      >
        <CalendarIcon className={classes.icon} />
        {isSmallScreen && <Typography style={{marginLeft: theme.spacing(1.8), color: theme.palette.text.secondary}}>Programar Mensajes</Typography> }  
      </IconButton>

      <ScheduleModal
        open={open}
        onClose={handleModalClose}
        contactId={contactId}
      />
    </>
  );
};

const ActionButtons = (props) => {
  const {
    loading,
    recording,
    ticketStatus,
    handleSendMessage,
    handleCancelAudio,
    handleUploadAudio,
    handleStartRecording,
  } = props;
  const classes = useStyles();
  if (recording) {
    return (
      <div className={classes.recorderWrapper}>
        <IconButton
          aria-label="cancelRecording"
          component="span"
          fontSize="large"
          disabled={loading}
          onClick={handleCancelAudio}
        >
          <ErrorIcon
            className={classes.cancelAudioIcon}
            width="24"
            height="24"
          />
        </IconButton>
        {loading ? (
          <div>
            <CircularProgress className={classes.audioLoading} />
          </div>
        ) : (
          <RecordingTimer />
        )}

        <IconButton
          aria-label="sendRecordedAudio"
          component="span"
          onClick={handleUploadAudio}
          disabled={loading}
        >
          <SuccessIcon
            width="24"
            height="24"
            className={classes.sendAudioIcon}
          />
        </IconButton>
      </div>
    );
  } else {
    return (
      <IconButton
        aria-label="showRecorder"
        component="span"
        disabled={loading || ticketStatus !== "open"}
        onClick={handleStartRecording}
      >
        <MicIcon style={{ color: "grey" }} />
      </IconButton>
    );
  }
};

const ActionIcons = ({ loading, disableOption, handleOpenEmojiModal, handleMenuOpen }) => {
  const classes = useStyles();
  
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* Botón de emoji */}
      <Tooltip title="Emoji">
        <IconButton
          aria-label="showEmoji"
          component="span"
          disabled={loading || disableOption()}
          onClick={handleOpenEmojiModal}
          className={classes.messageActionsButton}
        >
          <MoodIcon className={classes.actionIcon} />
        </IconButton>
      </Tooltip>
      
      {/* Botón de menú */}
      <Tooltip title="Opciones">
        <IconButton 
          onClick={handleMenuOpen} 
          className={classes.menuButton}
          disabled={loading || disableOption()}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const CustomInput = React.memo(({ 
  loading,
  inputRef,
  ticketStatus,
  inputMessage,
  setInputMessage,
  handleSendMessage,
  handleInputPaste,
  disableOption,
  quickMessages
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    if (
      isString(inputMessage) &&
      !isEmpty(inputMessage) &&
      inputMessage.length > 1
    ) {
      const firstWord = inputMessage.charAt(0);
      setPopupOpen(firstWord.indexOf("/") > -1);

      const filteredOptions = quickMessages.filter(
        (m) => m.label.indexOf(inputMessage) > -1
      );
      setOptions(filteredOptions);
    } else {
      setPopupOpen(false);
    }
  }, [inputMessage, quickMessages]);

  const onKeyPress = (e) => {
    if (loading || e.shiftKey) return;
    else if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const onPaste = (e) => {
    if (ticketStatus === "open") {
      handleInputPaste(e);
    }
  };

  const setInputRef = (input) => {
    if (input) {
      input.disabled = loading;
      input.focus();
      inputRef.current = input;
    }
  };

  return (
    <div className={classes.messageInputWrapper} style={{ flex: 1, width: '100%' }}>
      <Autocomplete
        freeSolo
        open={popupOpen}
        id="grouped-demo"
        value={inputMessage}
        options={options}
        closeIcon={null}
        getOptionLabel={(option) => {
          if (isObject(option)) {
            return option.label;
          } else {
            return option;
          }
        }}
        onChange={(event, opt) => {
          if (isObject(opt) && has(opt, "value")) {
            setInputMessage(opt.value);
            setTimeout(() => {
              inputRef.current.scrollTop = inputRef.current.scrollHeight;
            }, 200);
          }
        }}
        onInputChange={(event, opt, reason) => {
          if (reason === "input") {
            setInputMessage(event.target.value);
          }
        }}
        onPaste={onPaste}
        onKeyPress={onKeyPress}
        style={{ width: "100%", flex: 1 }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              {...params.InputProps}
              {...rest}
              disabled={disableOption() || loading}
              inputRef={setInputRef}
              multiline
              className={classes.messageInput}
              maxRows={5}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder={i18n.t("messagesInput.placeholderOpen")}
              style={{ width: "100%" }}
            />
          );
        }}
      />
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.loading === nextProps.loading &&
    prevProps.ticketStatus === nextProps.ticketStatus &&
    prevProps.inputMessage === nextProps.inputMessage &&
    prevProps.disableOption === nextProps.disableOption &&
    prevProps.quickMessages === nextProps.quickMessages
  );
});

const useTicketData = (ticketId) => {
  const [ticketData, setTicketData] = useState(null);
  const cache = useRef(new Map());

  useEffect(() => {
    const fetchData = async () => {
      if (cache.current.has(ticketId)) {
        const { data, timestamp } = cache.current.get(ticketId);
        const fiveMinutes = 5 * 60 * 1000;
        if (Date.now() - timestamp < fiveMinutes) {
          setTicketData(data);
          return;
        }
      }

      try {
        const response = await api.get("/tickets/" + ticketId);
        const newData = response.data;
        cache.current.set(ticketId, {
          data: newData,
          timestamp: Date.now()
        });
        setTicketData(newData);
      } catch (error) {
        console.error("Error al obtener datos del ticket");
      }
    };

    if (ticketId) {
      fetchData();
    }
  }, [ticketId]);

  return ticketData;
};

const MessageInputCustom = (props) => {
  const { ticketStatus, ticketId, contactId, currentUser } = props;
  const classes = useStyles();
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [percentLoading, setPercentLoading] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [quickMessagesData, setQuickMessagesData] = useState([]);
  const [status, setstatus] = useState("");
  const [whisperMode, setWhisperMode] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const { list: listQuickMessages } = useQuickMessages();

  const inputRef = useRef();
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);

  const memoizedQuickMessages = useMemo(() => {
    return quickMessagesData.map((m) => {
      let truncatedMessage = m.message;
      if (isString(truncatedMessage) && truncatedMessage.length > 35) {
        truncatedMessage = m.message.substring(0, 35) + "...";
      }
      return {
        value: m.message,
        label: `/${m.shortcode} - ${truncatedMessage}`,
      };
    });
  }, [quickMessagesData]);

  useEffect(() => {
    const fetchQuickMessages = async () => {
      try {
        const companyId = localStorage.getItem("companyId");
        const messages = await listQuickMessages({
          companyId,
          userId: user.id,
        });
        setQuickMessagesData(messages);
      } catch (error) {
        console.log("Error al obtener la informacion del usuario");
        setQuickMessagesData([]);
      }
    };
    fetchQuickMessages();
  }, [user.id]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [replyingMessage]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = getSocketConnection({ companyId });

    const handleMessage = (data) => {
      if (data.message && data.action === "create") {
        if (data.message.ticketId === ticketId) {
          setInputMessage("");
          setShowEmoji(false);
          setLoading(false);
          setReplyingMessage(null);
          inputRef.current?.focus();
        }
      }
    };

    socket.on(`company-${companyId}-message`, handleMessage);

    return () => {
      socket.off(`company-${companyId}-message`, handleMessage);
      // No desconectar el socket aquí
    };
  }, [ticketId, setReplyingMessage]);

  const handleMenuOpen = (event) => {
    setShowEmoji(false);
    setShowMessage(false);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddEmoji = useCallback((e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  }, []);

  const handleAddQuickMessage = useCallback((message) => {
    setInputMessage(message);
    setShowMessage(false);
    handleMenuClose();
  }, []);

  const handleOpenEmojiModal = () => {
    setShowEmoji(prevState => !prevState);
    setShowMessage(false);
    handleMenuClose();
  };

  const handleOpenQuickMessageModal = () => {
    setShowMessage(true);
    setShowEmoji(false);
    handleMenuClose();
  };

  const handleChangeMedias = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validar tamaño máximo (16MB)
    const maxSize = 16 * 1024 * 1024;
    if (file.size > maxSize) {
      toastError("El archivo excede el límite de 16MB permitido");
      e.target.value = null;
      return;
    }

    setMedias([file]);
    
    // Crear preview para imágenes
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowPreview(true);
      };
      reader.readAsDataURL(file);
    } else {
      // Para otros tipos de archivos, mostrar también el modal pero sin preview
      setShowPreview(true);
    }

    handleMenuClose();
    e.target.value = null;
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async (file) => {
    try {
      const formData = new FormData();
      
      if (file.type.startsWith('image/')) {
        const compressedFile = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success: (compressedFile) => resolve(compressedFile),
            error: (err) => reject(err),
          });
        });

        formData.append("medias", compressedFile);
        formData.append("body", file.name);
        formData.append("fromMe", true);
        formData.append("isWhisper", whisperMode);

        await api.post(`/messages/${ticketId}`, formData, {
          onUploadProgress: (progressEvent) => {
            const currentProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercentLoading(currentProgress);
          },
        });
      } else {
        formData.append("medias", file);
        formData.append("body", file.name);
        formData.append("fromMe", true);
        formData.append("isWhisper", whisperMode);

        await api.post(`/messages/${ticketId}`, formData, {
          onUploadProgress: (progressEvent) => {
            const currentProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercentLoading(currentProgress);
          },
        });
      }
    } catch (err) {
      console.error(err);
      toastError(`Error al procesar archivo: ${err.message || 'Error desconocido'}`);
      throw err;
    }
  };

  const handleSendMessage = useCallback(async () => {
    if (inputMessage.trim() === "" && medias.length === 0) return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
      isWhisper: whisperMode
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    inputRef.current.focus();
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  }, [inputMessage, signMessage, user?.name, replyingMessage, ticketId, whisperMode]);

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `audio-record-site-${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const disableOption = () => {
    return loading || recording || ticketStatus !== "open";
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            {message.body}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon />
        </IconButton>
      </div>
    );
  };

  // Usar el custom hook para manejar los datos del ticket
  const ticketData = useTicketData(ticketId);
  useEffect(() => {
    if (ticketData) {
      setstatus(ticketData.status);
    }
  }, [ticketData]);

  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [uploadingMedia, setUploadingMedia] = useState(false);

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewUrl(null);
    setUploadingMedia(false);
  };

  const getEmojiPerLine = () => {
    const width = props.width || 'md';
    if (isWidthUp('lg', width)) {
      return 12;
    } else if (isWidthUp('md', width)) {
      return 9;
    } else {
      return 7;
    }
  };

  const renderMainComponent = () => {
    if (medias.length > 0) {
      return (
        <>
          {/* Mantenemos este indicador fuera del Paper principal */}
          {whisperMode && (
            <div className={classes.whisperModeIndicator}>
              <WhisperIcon fontSize="small" style={{ marginRight: '4px' }} />
              <Typography variant="caption">
                Modo Susurro Activado - Este mensaje no se enviará por WhatsApp
              </Typography>
            </div>
          )}
          
          <Paper variant="outlined" className={classes.mainWrapper}>
            {replyingMessage && renderReplyingMessage(replyingMessage)}
            
            <Paper
              square
              elevation={0}
              className={clsx(classes.mainContainer, {
                [classes.mainContainerClosed]: disableOption()
              })}
            >
              {status === "open" && (
                <div className={classes.newMessageBox}>
                  {isSmallScreen ? (
                    <>
                      <ActionIcons
                        loading={loading}
                        disableOption={disableOption}
                        handleOpenEmojiModal={handleOpenEmojiModal}
                        handleMenuOpen={handleMenuOpen}
                      />

                      <CustomInput
                        loading={loading}
                        inputRef={inputRef}
                        ticketStatus={ticketStatus}
                        inputMessage={inputMessage}
                        setInputMessage={setInputMessage}
                        handleSendMessage={handleSendMessage}
                        handleInputPaste={handleInputPaste}
                        disableOption={disableOption}
                        quickMessages={memoizedQuickMessages}
                      />

                      {inputMessage ? (
                        <Button
                          onClick={handleSendMessage}
                          disabled={loading || disableOption()}
                          variant="containedPrimary"
                          className={classes.SendButton}
                        >
                          <SendIcon fill={theme.palette.text.main} />
                        </Button>
                      ) : (
                        <ActionButtons
                          loading={loading}
                          recording={recording}
                          ticketStatus={ticketStatus}
                          handleSendMessage={handleSendMessage}
                          handleCancelAudio={handleCancelAudio}
                          handleUploadAudio={handleUploadAudio}
                          handleStartRecording={handleStartRecording}
                        />
                      )}

                      {showEmoji && (
                        <div className={classes.emojiBoxMobile}>
                          <Picker
                            perLine={8}
                            showPreview={false}
                            showSkinTones={false}
                            onSelect={(emoji) => {
                              handleAddEmoji(emoji);
                              setShowEmoji(false);
                              handleMenuClose();
                            }}
                          />
                        </div>
                      )}

                      {showMessage && (
                        <div className={classes.quickMessagesBoxMobile}>
                          {quickMessagesData.map((item) => (
                            <div
                              key={item.id}
                              className={classes.quickMessageItem}
                              onClick={() => {
                                handleAddQuickMessage(item.message);
                                setShowMessage(false);
                                handleMenuClose();
                              }}
                            >
                              {item.shortcode}: {truncateText(item.message, 94)}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        <ActionIcons
                          loading={loading}
                          disableOption={disableOption}
                          handleOpenEmojiModal={handleOpenEmojiModal}
                          handleMenuOpen={handleMenuOpen}
                        />

                        <CustomInput
                          loading={loading}
                          inputRef={inputRef}
                          ticketStatus={ticketStatus}
                          inputMessage={inputMessage}
                          setInputMessage={setInputMessage}
                          handleSendMessage={handleSendMessage}
                          handleInputPaste={handleInputPaste}
                          disableOption={disableOption}
                          quickMessages={memoizedQuickMessages}
                        />

                        {inputMessage ? (
                          <Button
                            onClick={handleSendMessage}
                            disabled={loading || disableOption()}
                            variant="containedPrimary"
                            className={classes.SendButton}
                          >
                            <SendIcon fill={theme.palette.text.main} />
                          </Button>
                        ) : (
                          <ActionButtons
                            loading={loading}
                            recording={recording}
                            ticketStatus={ticketStatus}
                            handleSendMessage={handleSendMessage}
                            handleCancelAudio={handleCancelAudio}
                            handleUploadAudio={handleUploadAudio}
                            handleStartRecording={handleStartRecording}
                          />
                        )}
                      </div>

                      {showEmoji && (
                        <div className={classes.emojiBox}>
                          <Picker
                            perLine={getEmojiPerLine(props.width)}
                            showPreview={false}
                            showSkinTones={false}
                            onSelect={handleAddEmoji}
                          />
                        </div>
                      )}

                      {showMessage && (
                        <div className={classes.quickMessagesBox}>
                          {quickMessagesData.map((item) => (
                            <div
                              key={item.id}
                              className={classes.quickMessageItem}
                              onClick={() => handleAddQuickMessage(item.message)}
                            >
                              {item.shortcode}: {truncateText(item.message, 94)}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </Paper>

            <Modal
              open={showPreview}
              onClose={() => !uploadingMedia && handleClosePreview()}
              className={classes.previewModal}
            >
              <div className={classes.previewModalContent}>
                <IconButton 
                  className={classes.closeButton}
                  onClick={() => {
                    handleClosePreview();
                    setMedias([]);
                  }}
                  disabled={uploadingMedia}
                >
                  <CancelIcon style={{ color: '#fff' }} />
                </IconButton>

                {previewUrl && medias[0]?.type.startsWith('image/') ? (
                  <img 
                    src={previewUrl} 
                    alt="Preview" 
                    className={classes.previewImage}
                  />
                ) : (
                  <div className={classes.filePreview}>
                    <DocumentTextIcon style={{ fontSize: 64, color: '#fff' }} />
                  </div>
                )}
                
                <div className={classes.previewActions}>
                  <div className={classes.previewInfo}>
                    <Typography className={classes.fileName}>
                      {medias[0]?.name}
                    </Typography>
                    <Typography className={classes.fileSize}>
                      {formatFileSize(medias[0]?.size || 0)}
                    </Typography>
                  </div>

                  <div className={classes.actionButtons}>
                    <Button
                      variant="contained"
                      className={classes.cancelButton}
                      onClick={() => {
                        handleClosePreview();
                        setMedias([]);
                      }}
                      disabled={uploadingMedia}
                      startIcon={<CancelIcon />}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.sendButton}
                      onClick={async () => {
                        if (uploadingMedia) return;
                        setUploadingMedia(true);
                        setPercentLoading(0);
                        try {
                          await handleUploadMedia(medias[0]);
                          setTimeout(() => {
                            handleClosePreview();
                            setMedias([]);
                          }, 500);
                        } catch (err) {
                          console.error(err);
                          toastError("Error al enviar el archivo");
                        } finally {
                          setUploadingMedia(false);
                        }
                      }}
                      disabled={uploadingMedia}
                      startIcon={uploadingMedia ? null : <SendIcon />}
                      style={{ 
                        opacity: uploadingMedia ? 0.5 : 1,
                        cursor: uploadingMedia ? 'not-allowed' : 'pointer'
                      }}
                    >
                      {uploadingMedia ? 'Enviando...' : 'Enviar'}
                    </Button>
                  </div>
                </div>

                {uploadingMedia && (
                  <div style={{ 
                    width: '100%', 
                    marginTop: '40px',
                    position: 'absolute',
                    bottom: '20px',
                    left: 0,
                    padding: '0 20px'
                  }}>
                    <LinearWithValueLabel progress={percentLoading} />
                  </div>
                )}
              </div>
            </Modal>

            {/* Menú de opciones */}
            <Menu
              id="icon-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              disableAutoFocusItem
              disableRestoreFocus
              MenuListProps={{
                style: { padding: 0 },
                autoFocusItem: false,
              }}
              PaperProps={{
                style: {
                  minWidth: "300px",
                  width: isSmallScreen ? "100vw" : "auto",
                  maxWidth: isSmallScreen ? "100%" : "350px",
                  height: "auto",
                  maxHeight: "40vh",
                  backgroundColor: theme.palette.inputBackground,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 1300,
                  padding: 0,
                  marginLeft: isSmallScreen ? -15 : 0,
                  marginBottom: 15,
                  borderRadius: isSmallScreen ? 0 : 8,
                  color: theme.palette.text.secondary,
                },
              }}
            >
              
              <MenuItem>
                  <FileInput
                    disableOption={disableOption}
                    handleChangeMedias={handleChangeMedias}
                    menu={true}
                  />
              </MenuItem>
              
              <MenuItem onClick={handleOpenQuickMessageModal}>
                <QuickMessages
                  disabled={disableOption()}
                  showMessage={false}
                  setShowMessage={setShowMessage}
                  menu={true}
                />
                <span style={{ marginLeft: 10 }}>Mensajes rápidos</span>
              </MenuItem>
              
              <MenuItem onClick={() => {
                setOpen(true);
                handleMenuClose();
              }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%',  marginLeft: '12px'}}>
                  <CalendarIcon />
                  <span style={{ marginLeft: '22px', width: '24px', height: '24px' }} >Programar mensaje</span>
                </div>
              </MenuItem>
              
              <MenuItem
                onClick={() => {
                  setWhisperMode(prev => !prev);
                  handleMenuClose();
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '11px', marginTop: '3px'}}>
                  <WhisperIcon 
                    style={{ 
                      marginRight: '17px',
                      color: whisperMode ? green[500] : 'inherit'
                    }}
                  />
                  {whisperMode ? "Desactivar modo susurro" : "Activar modo susurro"}
                </div>
              </MenuItem>
              
              <MenuItem onClick={handleMenuClose}>
                <SignSwitch
                  width={props.width}
                  setSignMessage={setSignMessage}
                  signMessage={signMessage}
                  menu={true}
                  small="small"
                  marginLeft={-1}
                />
              </MenuItem>
            </Menu>

            {/* Modal de programación de mensajes */}
            <ScheduleModal
              open={open}
              onClose={() => setOpen(false)}
              contactId={contactId}
            />
          </Paper>
        </>
      );
    } else {
      return (
        <>
          {/* Mantenemos este indicador fuera del Paper principal */}
          {whisperMode && (
            <div className={classes.whisperModeIndicator}>
              <WhisperIcon fontSize="small" style={{ marginRight: '4px' }} />
              <Typography variant="caption">
                Modo Susurro Activado - Este mensaje no se enviará por WhatsApp
              </Typography>
            </div>
          )}
          
          <Paper variant="outlined" className={classes.mainWrapper}>
            {replyingMessage && renderReplyingMessage(replyingMessage)}
            
            <Paper
              square
              elevation={0}
              className={clsx(classes.mainContainer, {
                [classes.mainContainerClosed]: disableOption()
              })}
            >
              {status === "open" && (
                <div className={classes.newMessageBox}>
                  {isSmallScreen ? (
                    <>
                      <ActionIcons
                        loading={loading}
                        disableOption={disableOption}
                        handleOpenEmojiModal={handleOpenEmojiModal}
                        handleMenuOpen={handleMenuOpen}
                      />

                      <CustomInput
                        loading={loading}
                        inputRef={inputRef}
                        ticketStatus={ticketStatus}
                        inputMessage={inputMessage}
                        setInputMessage={setInputMessage}
                        handleSendMessage={handleSendMessage}
                        handleInputPaste={handleInputPaste}
                        disableOption={disableOption}
                        quickMessages={memoizedQuickMessages}
                      />

                      {inputMessage ? (
                        <Button
                          onClick={handleSendMessage}
                          disabled={loading || disableOption()}
                          variant="containedPrimary"
                          className={classes.SendButton}
                        >
                          <SendIcon fill={theme.palette.text.main} />
                        </Button>
                      ) : (
                        <ActionButtons
                          loading={loading}
                          recording={recording}
                          ticketStatus={ticketStatus}
                          handleSendMessage={handleSendMessage}
                          handleCancelAudio={handleCancelAudio}
                          handleUploadAudio={handleUploadAudio}
                          handleStartRecording={handleStartRecording}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        <ActionIcons
                          loading={loading}
                          disableOption={disableOption}
                          handleOpenEmojiModal={handleOpenEmojiModal}
                          handleMenuOpen={handleMenuOpen}
                        />

                        <CustomInput
                          loading={loading}
                          inputRef={inputRef}
                          ticketStatus={ticketStatus}
                          inputMessage={inputMessage}
                          setInputMessage={setInputMessage}
                          handleSendMessage={handleSendMessage}
                          handleInputPaste={handleInputPaste}
                          disableOption={disableOption}
                          quickMessages={memoizedQuickMessages}
                        />

                        {inputMessage ? (
                          <Button
                            onClick={handleSendMessage}
                            disabled={loading || disableOption()}
                            variant="containedPrimary"
                            className={classes.SendButton}
                          >
                            <SendIcon fill={theme.palette.text.main} />
                          </Button>
                        ) : (
                          <ActionButtons
                            loading={loading}
                            recording={recording}
                            ticketStatus={ticketStatus}
                            handleSendMessage={handleSendMessage}
                            handleCancelAudio={handleCancelAudio}
                            handleUploadAudio={handleUploadAudio}
                            handleStartRecording={handleStartRecording}
                          />
                        )}
                      </div>

                      {showEmoji && (
                        <div className={classes.emojiBox}>
                          <Picker
                            perLine={getEmojiPerLine(props.width)}
                            showPreview={false}
                            showSkinTones={false}
                            onSelect={handleAddEmoji}
                          />
                        </div>
                      )}

                      {showMessage && (
                        <div className={classes.quickMessagesBox}>
                          {quickMessagesData.map((item) => (
                            <div
                              key={item.id}
                              className={classes.quickMessageItem}
                              onClick={() => handleAddQuickMessage(item.message)}
                            >
                              {item.shortcode}: {truncateText(item.message, 94)}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </Paper>

            {/* Menú de opciones */}
            <Menu
              id="icon-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              disableAutoFocusItem
              disableRestoreFocus
              MenuListProps={{
                style: { padding: 0 },
                autoFocusItem: false,
              }}
              PaperProps={{
                style: {
                  minWidth: "300px",
                  width: isSmallScreen ? "100vw" : "auto",
                  maxWidth: isSmallScreen ? "100%" : "350px",
                  height: "auto",
                  maxHeight: "40vh",
                  backgroundColor: theme.palette.inputBackground,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 1300,
                  padding: 0,
                  marginLeft: isSmallScreen ? -15 : 0,
                  marginBottom: 15,
                  borderRadius: isSmallScreen ? 0 : 8,
                  color: theme.palette.text.secondary,
                },
              }}
            >
              <MenuItem>
                  <FileInput
                    disableOption={disableOption}
                    handleChangeMedias={handleChangeMedias}
                    menu={true}
                  />
              </MenuItem>
              
              <MenuItem onClick={handleOpenQuickMessageModal}>
                <QuickMessages
                  disabled={disableOption()}
                  showMessage={false}
                  setShowMessage={setShowMessage}
                  menu={true}
                />
                <span style={{ marginLeft: 10 }}>Mensajes rápidos</span>
              </MenuItem>
              
              <MenuItem onClick={() => {
                setOpen(true);
                handleMenuClose();
              }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%',  marginLeft: '12px'}}>
                  <CalendarIcon />
                  <span style={{ marginLeft: '22px', width: '24px', height: '24px' }} >Programar mensaje</span>
                </div>
              </MenuItem>
              
              <MenuItem
                onClick={() => {
                  setWhisperMode(prev => !prev);
                  handleMenuClose();
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '11px', marginTop: '3px'}}>
                  <WhisperIcon 
                    style={{ 
                      marginRight: '17px',
                      color: whisperMode ? green[500] : 'inherit'
                    }}
                  />
                  {whisperMode ? "Desactivar modo susurro" : "Activar modo susurro"}
                </div>
              </MenuItem>
              
              <MenuItem onClick={handleMenuClose}>
                <SignSwitch
                  width={props.width}
                  setSignMessage={setSignMessage}
                  signMessage={signMessage}
                  menu={true}
                  small="small"
                  marginLeft={-1}
                />
              </MenuItem>
            </Menu>

            {/* Modal de programación de mensajes */}
            <ScheduleModal
              open={open}
              onClose={() => setOpen(false)}
              contactId={contactId}
            />
          </Paper>
        </>
      );
    }
  };

  // Renderizar también el modal de programación
  return (
    <>
      {/* Renderizar el componente principal */}
      {renderMainComponent()}
      
      {/* Modal de programación */}
      <ScheduleModal
        open={open}
        onClose={() => setOpen(false)}
        contactId={contactId}
      />
    </>
  );
};

export default MessageInputCustom;