import React, { useState, useEffect, useReducer, useRef, useContext } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
} from "@material-ui/core";

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  Facebook,
  Instagram,
  AttachFile,
  NavigateBeforeIcon,
  NavigateNextIcon,
  Cancel,
  VolumeDown,
  ArrowDownward as ArrowDownwardIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
  DoneAll as DoneAllIcon,
  Description as DescriptionIcon,
  Reply as ReplyIcon,
} from "@material-ui/icons";

import ArrowLeftIcon from "../../assets/icon/ArrowLeftIcon.js"
import BackArrowIcon from "../../assets/icon/BackArrow.js"

import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection, getSocketConnection } from "../../services/socket";
import { unstable_batchedUpdates } from 'react-dom';
import { TicketsContext } from "../../context/Tickets/TicketsContext";

// Importar el ícono de susurro
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    minWidth: 300,
    minHeight: 200,
  },

  messagesList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    margin: "3px 10px",
    maxWidth: 600,
    display: "block",
    backgroundColor: "rgba(255, 221, 128, 0.68)",
    color: "#303030",
    alignSelf: "flex-start",
    borderRadius: "12px",
    padding: "10px 15px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: "5px",
      right: "5px",
    },

    whiteSpace: "pre-wrap",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    margin: "2px 5px",
    minWidth: 50,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "rgba(206, 206, 206, 0.5)",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  avatarLeft: {
    position: 'absolute',
    bottom: 0,
    left: -50,
    width: 40,
    height: 40,
  },

  avatarRight: {
    position: 'absolute',
    bottom: 0,
    right: -50,
    width: 40,
    height: 40,
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "black",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
  imageLocation: {
    position: 'relative',
    width: '100%',
    height: 80,
    borderRadius: 5
  },
  imageModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  imageModalContent: {
    position: 'relative',
    maxWidth: '90vw',
    maxHeight: '90vh',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImage: {
    maxWidth: '100%',
    maxHeight: '90vh',
    objectFit: 'contain',
    borderRadius: '8px',
  },
  closeButton: {
    position: 'absolute',
    top: '-40px',
    right: '-40px',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  navigationButton: {
    color: '#fff',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '12px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  prevButton: {
    left: '-60px',
  },
  nextButton: {
    right: '-60px',
    '& svg': {
      transform: 'rotate(180deg)'
    }
  },
  whisperMessage: {
    backgroundColor: 'rgba(128, 0, 128, 0.15)',
    borderLeft: `4px solid purple`,
    position: 'relative',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(128, 0, 128, 0.2)',
  },
  
  whisperText: {
    fontStyle: 'italic',
    color: 'rgba(128, 0, 128, 0.9)',
  },
  
  whisperIndicator: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: 'purple',
    marginBottom: '4px',
    fontWeight: 'bold',
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_MESSAGES":
      const uniqueMessages = action.payload.filter((msg, index, self) =>
        index === self.findIndex((m) => m.id === msg.id)
      );
      return uniqueMessages;
    case "ADD_MESSAGE":
      if (state.some(msg => msg.id === action.payload.id)) {
        return state;
      }
      return [...state, action.payload];
    case "UPDATE_MESSAGE":
      return state.map(m => m.id === action.payload.id ? action.payload : m);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const MessagesList = ({ ticket, ticketId, isGroup }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    messagesCache,
    loadingMessages,
    fetchTicketMessages,
    updateTicketMessage,
    addTicketMessage,
    setLoadingMessages
  } = useContext(TicketsContext);
  
  const [messagesList, dispatch] = useReducer(reducer, []);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const lastMessageRef = useRef();
  const currentTicketId = useRef(ticketId);
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('xs'));

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);

  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [chatImages, setChatImages] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const loadInitialMessages = async () => {
      if (!ticketId) return;

      try {
        // Solo reseteamos cuando cambiamos de ticket
        if (currentTicketId.current !== ticketId) {
          dispatch({ type: "RESET" });
          setPageNumber(1);
          currentTicketId.current = ticketId;
          setLoadingMessages(prev => ({ ...prev, [ticketId]: true }));
        }

        // Verificar si ya tenemos los mensajes en cache y son válidos
        if (messagesCache[ticketId]?.messages?.length > 0) {
          if (isMounted) {
            dispatch({ type: "LOAD_MESSAGES", payload: messagesCache[ticketId].messages });
            setHasMore(messagesCache[ticketId].hasMore);
            scrollToBottom();
            setLoadingMessages(prev => ({ ...prev, [ticketId]: false }));
          }
          return;
        }

        // Si no hay cache válido, hacer una única petición
        const data = await fetchTicketMessages(ticketId, 1, true);
        
        if (isMounted && currentTicketId.current === ticketId) {
          dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
          setHasMore(data.hasMore);
          scrollToBottom();
        }
      } catch (err) {
        console.error(err);
        if (isMounted) {
          toastError("Error al cargar los mensajes");
        }
      } finally {
        if (isMounted) {
          setLoadingMessages(prev => ({ ...prev, [ticketId]: false }));
        }
      }
    };

    loadInitialMessages();

    return () => {
      isMounted = false;
    };
  }, [ticketId]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = getSocketConnection(companyId);

    // Unirse al canal del ticket específico
    if (ticketId) {
      socket.emit("joinChatBox", ticketId);
      console.log(`Joined chat channel for ticket: ${ticketId}`);
    }

    const handleMessage = (data) => {
      if (data.message) {
        if (data.action === "create") {
          if (messagesCache[data.message.ticketId]) {
            addTicketMessage(data.message.ticketId, data.message);
          }

          if (data.message.ticketId === currentTicketId.current) {
            dispatch({ type: "ADD_MESSAGE", payload: data.message });
            
            const messagesList = document.getElementById("messagesList");
            const isAtBottom = messagesList && 
              (messagesList.scrollHeight - messagesList.scrollTop) <= (messagesList.clientHeight + 100);
            
            if (isAtBottom) {
              scrollToBottom();
            }
          }
        }

        if (data.action === "update") {
          if (messagesCache[data.message.ticketId]) {
            updateTicketMessage(data.message.ticketId, data.message);
          }

          if (data.message.ticketId === currentTicketId.current) {
            dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
          }
        }
      }
    };

    socket.on(`company-${companyId}-contactsMessage`, handleMessage);

    return () => {
      socket.off(`company-${companyId}-contactsMessage`, handleMessage);
    };
  }, [ticketId, addTicketMessage, updateTicketMessage, fetchTicketMessages, messagesCache]);

  const scrollToBottom = () => {
    setTimeout(() => {
      const messagesList = document.getElementById("messagesList");
      if (messagesList) {
        messagesList.scrollTop = messagesList.scrollHeight;
      }
    }, 0);
  };

  const loadMore = async () => {
    if (!ticketId || loadingMessages[ticketId] || !hasMore) return;
    
    // Prevenir cargas duplicadas
    if (!Array.isArray(messagesList) || messagesList.length === 0) return;
    
    try {
      const nextPage = pageNumber + 1;
      
      // Mantener la posición del scroll antes de cualquier cambio
      const messagesListElement = document.getElementById("messagesList");
      const previousHeight = messagesListElement.scrollHeight;
      const previousScrollTop = messagesListElement.scrollTop;

      setLoadingMessages(prev => ({ ...prev, [ticketId]: true }));
      
      if (messagesCache[ticketId]?.lastPage >= nextPage) {
        const cachedMessages = messagesCache[ticketId].messages;
        const startIndex = (nextPage - 1) * 20;
        const endIndex = startIndex + 20;
        const newMessages = cachedMessages.slice(startIndex, endIndex);
        
        if (newMessages.length === 0) return;

        // Ordenar y combinar mensajes de manera estable
        const allMessages = [...newMessages, ...messagesList]
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .filter((msg, index, self) => index === self.findIndex((m) => m.id === msg.id));

        unstable_batchedUpdates(() => {
          dispatch({ type: "LOAD_MESSAGES", payload: allMessages });
          setHasMore(messagesCache[ticketId].hasMore);
          setPageNumber(nextPage);
        });

      } else {
        const data = await fetchTicketMessages(ticketId, nextPage);
        
        if (currentTicketId.current === ticketId) {
          // Ordenar y combinar mensajes de manera estable
          const allMessages = [...data.messages, ...messagesList]
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .filter((msg, index, self) => index === self.findIndex((m) => m.id === msg.id));

          unstable_batchedUpdates(() => {
            dispatch({ type: "LOAD_MESSAGES", payload: allMessages });
            setHasMore(data.hasMore);
            setPageNumber(nextPage);
          });
        }
      }

      // Restaurar la posición del scroll después de los cambios
      requestAnimationFrame(() => {
        const newHeight = messagesListElement.scrollHeight;
        const heightDifference = newHeight - previousHeight;
        messagesListElement.scrollTop = previousScrollTop + heightDifference;
      });

    } catch (err) {
      console.error(err);
      toastError("Error al cargar más mensajes");
    } finally {
      setLoadingMessages(prev => ({ ...prev, [ticketId]: false }));
    }
  };

  const handleScroll = (e) => {
    if (!hasMore || loadingMessages[ticketId]) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop < 50) {
      requestAnimationFrame(() => {
        loadMore();
      });
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleImageClick = (mediaUrl) => {
    console.log("handleImageClick:", mediaUrl); // Para debug
    console.log("chatImages:", chatImages); // Para debug
    const imageIndex = chatImages.findIndex(url => url === mediaUrl);
    console.log("imageIndex encontrado:", imageIndex); // Para debug
    if (imageIndex !== -1) {
      setSelectedImageIndex(imageIndex);
    }
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevImage = () => {
    setSelectedImageIndex(prev => 
      prev > 0 ? prev - 1 : chatImages.length - 1
    );
  };

  const handleNextImage = () => {
    setSelectedImageIndex(prev => 
      prev < chatImages.length - 1 ? prev + 1 : 0
    );
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (selectedImageIndex !== null) {
        if (e.key === 'ArrowLeft') {
          handlePrevImage();
        } else if (e.key === 'ArrowRight') {
          handleNextImage();
        } else if (e.key === 'Escape') {
          handleCloseModal();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [selectedImageIndex, chatImages.length]);

  useEffect(() => {
    if (Array.isArray(messagesList)) {
      const imageUrls = messagesList
        .filter(msg => {
          // Verificar que el mensaje tenga una imagen válida
          return msg.mediaType?.startsWith("image") && 
                 msg.mediaUrl && 
                 msg.message !== "Este mensaje fue eliminado";
        })
        .map(msg => msg.mediaUrl);
      
      console.log("Imágenes encontradas:", imageUrls); // Para debug
      setChatImages(imageUrls);
    }
  }, [messagesList]);

  const renderMessageMedia = (message) => {
    if (!message.mediaUrl) return null;
    
    // Extraer el nombre del archivo y tipo
    let displayName = message.mediaName;
    // Si no hay mediaName pero hay body y hay mediaUrl, el body podría contener el nombre del archivo
    if (!displayName && message.mediaUrl && message.body) {
      // Verificar si el body no contiene etiquetas HTML o saltos de línea, 
      // lo que sugeriría que es solo el nombre del archivo
      const isPlainFileName = !message.body.includes('<') && !message.body.includes('\n');
      if (isPlainFileName) {
        displayName = message.body;
      }
    }
    // Si todavía no tenemos un nombre, intentar extraerlo de la URL
    if (!displayName && message.mediaUrl) {
      // Intentar extraer el nombre del archivo de la URL
      const urlParts = message.mediaUrl.split('/');
      const fileNameWithParams = urlParts[urlParts.length - 1];
      // Eliminar parámetros de consulta si existen
      displayName = fileNameWithParams.split('?')[0];
      // Decodificar el nombre en caso de que tenga caracteres especiales
      try {
        displayName = decodeURIComponent(displayName);
      } catch (e) {
        console.error("Error decodificando nombre de archivo:", e);
      }
    }
    
    // Determinar el tipo de archivo para mostrar información más relevante
    let fileType = "";
    if (message.mediaType) {
      if (message.mediaType.includes('pdf')) {
        fileType = "PDF";
      } else if (message.mediaType.includes('excel') || message.mediaType.includes('sheet') || message.mediaUrl.includes('.xls')) {
        fileType = "Excel";
      } else if (message.mediaType.includes('word') || message.mediaType.includes('document')) {
        fileType = "Word";
      } else if (message.mediaType.includes('text')) {
        fileType = "Texto";
      } else {
        // Usar la extensión del tipo MIME si está disponible
        fileType = message.mediaType.split('/')[1]?.toUpperCase() || "Archivo";
      }
    }
    
    if (message.mediaType.startsWith("image")) {
      return (
        <div className={classes.messageMediaContainer}>
          <img
            src={message.mediaUrl}
            alt={displayName || "Imagen"}
            className={classes.messageMedia}
            onClick={() => {
              console.log("Click en imagen:", message.mediaUrl);
              handleImageClick(message.mediaUrl);
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
      );
    }
    if (message.mediaType.startsWith("audio")) {
      return (
        <audio
          controls
          src={message.mediaUrl}
          className={classes.messageMedia}
        />
      );
    }
    if (message.mediaType.startsWith("video")) {
      return (
        <video
          src={message.mediaUrl}
          controls
          className={classes.messageMedia}
        />
      );
    }
    if (message.mediaType.startsWith("application") || message.mediaType.startsWith("text")) {
      return (
        <div className={classes.downloadMedia}>
          <Button
            startIcon={<AttachFile />}
            className={classes.downloadButton}
            onClick={() => window.open(message.mediaUrl)}
          >
            {displayName || fileType || message.body || 'Descargar'}
          </Button>
        </div>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index]?.createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index]?.createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1]?.createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message?.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index]?.createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message?.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index]?.fromMe;
      let previousMessageUser = messagesList[index - 1]?.fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message?.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message?.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message?.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}
          {message.quotedMsg?.body}
        </div>
      </div>
    );
  };

  const isVCard = (message) => {
    return message.includes('BEGIN:VCARD');
  };

  const vCard = (message) => {
    const name = message?.substring(message.indexOf("N:;") + 3, message.indexOf(";;;"))
    const description = message?.substring(message.indexOf("TION:") + 5, message.indexOf("TEL"))
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
          <Avatar style={{ marginRight: 10, marginLeft: 20, width: 60, height: 60 }} />
          <div style={{ width: 350 }}>
            <div>
              <Typography
                noWrap
                component="h4"
                variant="body2"
                color="textPrimary"
                style={{ fontWeight: '700' }}
              >
                {name}
              </Typography>
            </div>

            <div style={{ width: 350 }}>
              <Typography
                component="span"
                variant="body2"
                color="textPrimary"
                style={{ display: 'flex' }}
              >
                {description}
              </Typography>
            </div>
          </div>
        </div>
        <div style={{
          width: '100%', display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
          borderWidth: '1px 0 0 0',
          borderTopColor: '#bdbdbd',
          borderStyle: 'solid',
          padding: 8
        }}>
          <Typography
            noWrap
            component="h4"
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: '700', color: '#2c9ce7' }}
          >
            Conversación
          </Typography>
        </div>
      </div>
    )
  };

  const messageLocation = (message, createdAt) => {
    return (
      <div className={[classes.textContentItem, { display: 'flex', padding: 5 }]}>
        <img src={message?.split('|')[0]} className={classes.imageLocation} />
        <a
          style={{ fontWeight: '700', color: 'gray' }}
          target="_blank"
          href={message?.split('|')[1]}> Clique para ver localização</a>
        <span className={classes.timestamp}>
          {format(parseISO(createdAt), "HH:mm")}
        </span>
      </div>
    )
  };
  const renderAvatar = (message) => (
    <Avatar
      className={message.fromMe ? classes.avatarRight : classes.avatarLeft}
      src={message?.contact?.profilePicUrl}
    >
      {message?.contact?.name?.charAt(0).toUpperCase()}
    </Avatar>
  );

  // Modificar la lógica para detectar mensajes de susurro
  // Añadir una función auxiliar para verificar si un mensaje es de tipo susurro
  const isWhisperMessage = (message) => {
    // Log para depuración
    if (message.id && message.id.toString().startsWith('whisper_')) {
      console.log('Mensaje de susurro detectado por ID:', message);
      console.log('iswhisper:', message.iswhisper);
      console.log('isWhisper:', message.isWhisper);
    }
    
    // Verificar todas las posibles variantes del campo iswhisper
    const isWhisper = 
      message.isWhisper === true ||
      (message.id && message.id.toString().startsWith('whisper_'));
    
    // Si es un mensaje de susurro por ID pero no tiene el flag iswhisper,
    // forzamos la propiedad para que se muestre correctamente
    if (message.id && message.id.toString().startsWith('whisper_') && !message.iswhisper) {
      message.iswhisper = true;
    }
    
    return isWhisper;
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        const isLastInSequence = 
          index === messagesList.length - 1 ||
          messagesList[index + 1].fromMe !== message.fromMe;

        const additionalStyle = isLastInSequence
          ? message.fromMe
            ? { borderBottomRightRadius: 0 }
            : { borderBottomLeftRadius: 0 }
          : {}; 

        // Añadir lógica para mensajes de tipo susurro
        const whisperClass = isWhisperMessage(message) ? classes.whisperMessage : '';

        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}

              <div
                className={`${classes.messageLeft} ${whisperClass}`}
                title={message.queueId && message.queue?.name}
                style={additionalStyle}
              >
                {isLastInSequence && !isSmOrLess && renderAvatar(message)}
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}

                {/* Indicador de mensaje tipo susurro */}
                {isWhisperMessage(message) && (
                  <div className={classes.whisperIndicator}>
                    <VisibilityOffIcon fontSize="small" style={{ marginRight: '4px' }} />
                    <span>Mensaje privado - Solo visible para ti</span>
                  </div>
                )}

                {message.mediaUrl && renderMessageMedia(message)}

                {message.body.includes('data:image') ? messageLocation(message.body, message.createdAt)
                  :
                  isVCard(message.body) ?
                    <div className={[classes.textContentItem, { marginRight: 0 }]}>
                      {vCard(message.body)}
                    </div>
                  :
                  (<div className={classes.textContentItem}>
                    {message.quotedMsg && renderQuotedMessage(message)}
                    <MarkdownWrapper className={isWhisperMessage(message) ? classes.whisperText : ''}>
                      {message.body}
                    </MarkdownWrapper>
                    <span className={classes.timestamp}>
                      {format(parseISO(message?.createdAt), "HH:mm")}
                    </span>
                  </div>)}
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div
                className={`${classes.messageRight} ${whisperClass}`}
                title={message.queueId && message.queue?.name}
                style={additionalStyle}
              >
                {isLastInSequence && !isSmOrLess && renderAvatar(message)}
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>

                {/* Indicador de mensaje tipo susurro */}
                {isWhisperMessage(message) && (
                  <div className={classes.whisperIndicator}>
                    <VisibilityOffIcon fontSize="small" style={{ marginRight: '4px' }} />
                    <span>Mensaje privado - Solo visible para ti</span>
                  </div>
                )}

                {message.mediaUrl && renderMessageMedia(message)}
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}
                >
                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.body.includes('data:image') ? messageLocation(message.body, message.createdAt)
                  :
                  isVCard(message.body) ?
                    <div className={[classes.textContentItem]}>
                      {vCard(message.body)}
                    </div>
                  :
                  message.quotedMsg && renderQuotedMessage(message)}
                  <MarkdownWrapper className={isWhisperMessage(message) ? classes.whisperText : ''}>
                    {message.body}
                  </MarkdownWrapper>
                  <span className={classes.timestamp}>
                    {format(parseISO(message.createdAt), "HH:mm")}
                    {renderMessageAck(message)}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };
  
  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 && renderMessages()}
        {loadingMessages[ticketId] && (
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            padding: '20px'
          }}>
            <CircularProgress className={classes.circleLoading} />
          </div>
        )}
      </div>
      <Modal
        open={selectedImageIndex !== null}
        onClose={handleCloseModal}
        className={classes.imageModal}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
        }}
      >
        <div className={classes.imageModalContent}>
          <IconButton 
            className={classes.closeButton}
            onClick={handleCloseModal}
            size="medium"
          >
            <Cancel />
          </IconButton>

          {chatImages.length > 1 && (
            <>
              <IconButton
                className={`${classes.navigationButton} ${classes.prevButton}`}
                onClick={handlePrevImage}
                size="medium"
              >
                <BackArrowIcon fill="white" />
              </IconButton>

              <IconButton
                className={`${classes.navigationButton} ${classes.nextButton}`}
                onClick={handleNextImage}
                size="medium"
              >
                <BackArrowIcon fill="white" />
              </IconButton>
            </>
          )}

          {selectedImageIndex !== null && (
            <img
              src={chatImages[selectedImageIndex]}
              alt="Vista ampliada"
              className={classes.modalImage}
              style={{
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MessagesList;
