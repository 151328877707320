import { Chip, Paper, TextField, useTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useMemo, useContext, useState } from "react";
import { isArray, isString } from "lodash";
import { Skeleton } from "@material-ui/lab";
import { TicketsContext } from "../../context/Tickets/TicketsContext";

export function TagsContainer({ ticket }) {
    const theme = useTheme();
    const { 
        tags, 
        loadingTags, 
        createTag, 
        syncTicketTags,
        updateTicketTags
    } = useContext(TicketsContext);

    const [localTags, setLocalTags] = useState([]);

    useEffect(() => {
        if (Array.isArray(ticket.tags)) {
            setLocalTags(ticket.tags);
        }
    }, [ticket.tags]);

    // Memorizar las tags seleccionadas del ticket actual
    const selectedTags = useMemo(() => {
        return localTags;
    }, [localTags]);

    // Memorizar las opciones de renderizado para el Autocomplete
    const renderOption = useMemo(() => (option) => (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        }}>
            <div style={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: option.color || '#2563eb'
            }} />
            <span>{option.name}</span>
        </div>
    ), []);

    // Memorizar el componente Paper para evitar re-renders innecesarios
    const PaperComponent = useMemo(() => ({ children }) => (
        <Paper style={{ 
            color: theme.palette.text.secondary, 
            backgroundColor: theme.palette.background.default 
        }}>
            {children}
        </Paper>
    ), [theme.palette.text.secondary, theme.palette.background.default]);

    // Memorizar el renderizado de los chips
    const renderTags = useMemo(() => (value, getTagProps) =>
        value.map((option, index) => (
            <Chip
                style={{
                    backgroundColor: option.color || '#2563eb',
                    color: 'white',
                    margin: '2px',
                    borderRadius: '9999px',
                    padding: 5
                }}
                label={option.name}
                {...getTagProps({ index })}
                size="small"
            />
        )), []);

    // Memorizar el componente de entrada
    const renderInput = useMemo(() => (params) => (
        <TextField
            {...params}
            variant="standard"
            placeholder="Seleccionar etiquetas existentes..."
            InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style: { color: theme.palette.text.blackWhite }
            }}
        />
    ), [theme.palette.text.blackWhite]);

    const handleChange = async (e, value) => {
        let finalTags = value;
        
        if (isArray(value)) {
            const newTags = [];
            for (let item of value) {
                if (isString(item)) {
                    const newTag = await createTag({ name: item });
                    if (newTag) newTags.push(newTag);
                } else {
                    newTags.push(item);
                }
            }
            finalTags = newTags;
        }

        // Actualizar estado local inmediatamente
        setLocalTags(finalTags);
        
        // Actualizar el contexto
        updateTicketTags(ticket.id, finalTags);
        
        // Sincronizar con el backend
        await syncTicketTags(ticket.id, finalTags);
    };

    return (
        <div style={{ 
            padding: 12, 
            backgroundColor: theme.palette.background.default, 
            borderRadius: '15px', 
            boxShadow: '0 1px 1px rgba(0,0,0,0.10)',
        }}>
            {loadingTags ? (
                <Skeleton variant="rect" width="100%" height={20} />
            ) : (
                <Autocomplete
                    multiple
                    size="small"
                    options={tags}
                    value={selectedTags}
                    freeSolo={false}
                    onChange={handleChange}
                    getOptionLabel={(option) => option.name}
                    renderTags={renderTags}
                    renderInput={renderInput}
                    renderOption={renderOption}
                    PaperComponent={PaperComponent}
                />
            )}
        </div>
    );
}