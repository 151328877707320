import { useState, useEffect, useReducer } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";

const reducer = (state, action) => {
  const currentState = Array.isArray(state) ? state : [];

  switch (action.type) {
    case "LOAD_WHATSAPPS":
      return Array.isArray(action.payload) ? action.payload : [action.payload].filter(Boolean);
    case "UPDATE_WHATSAPPS":
      try {
        const whatsApp = action.payload;
        if (!whatsApp) return currentState;
        
        const updatedState = [...currentState];
        
        const whatsAppIndex = updatedState.findIndex((s) => s && s.id === whatsApp.id);
        
        if (whatsAppIndex !== -1) {
          updatedState[whatsAppIndex] = whatsApp;
          
          if (whatsApp.isDefault && whatsApp.channel) {
            return updatedState.map(item => {
              if (item && typeof item === 'object' && item.id && item.channel) {
                if (item.id !== whatsApp.id && item.channel === whatsApp.channel) {
                  return { ...item, isDefault: false };
                }
              }
              return item;
            });
          }
          
          return updatedState;
        } else {
          return [whatsApp, ...updatedState];
        }
      } catch (error) {
        console.error("Error al actualizar WhatsApp:", error);
        return currentState;
      }
    case "UPDATE_SESSION":
      try {
        const whatsAppSession = action.payload;
        if (!whatsAppSession || !whatsAppSession.id) return currentState;
        
        const updatedState = [...currentState];
        
        const whatsAppSessionIndex = updatedState.findIndex(
          (s) => s && s.id === whatsAppSession.id
        );

        if (whatsAppSessionIndex !== -1) {
          updatedState[whatsAppSessionIndex] = {
            ...updatedState[whatsAppSessionIndex],
            status: whatsAppSession.status || updatedState[whatsAppSessionIndex].status,
            qrcode: whatsAppSession.qrcode || updatedState[whatsAppSessionIndex].qrcode,
            retries: typeof whatsAppSession.retries === 'number' ? whatsAppSession.retries : updatedState[whatsAppSessionIndex].retries
          };
          return updatedState;
        }
        return currentState;
      } catch (error) {
        console.error("Error al actualizar sesión de WhatsApp:", error);
        return currentState;
      }
    case "DELETE_WHATSAPPS":
      try {
        const whatsAppId = action.payload;
        if (!whatsAppId) return currentState;
        
        const whatsAppList = currentState.filter((s) => s && s.id !== whatsAppId);
        return whatsAppList;
      } catch (error) {
        console.error("Error al eliminar WhatsApp:", error);
        return currentState;
      }
    case "RESET":
      return [];
    default:
      return currentState;
  }
};

const useWhatsApps = () => {
  const [loading, setLoading] = useState(true);
  const [whatsApps, dispatch] = useReducer(reducer, []);
  const [lastManualUpdate, setLastManualUpdate] = useState(0);
  const [connectionWarning, setConnectionWarning] = useState(false);

  // Función wrapper para dispatch que también actualiza lastManualUpdate
  const dispatchWithTimestamp = (action) => {
    if (action.type === "LOAD_WHATSAPPS") {
      setLastManualUpdate(Date.now());
    }
    dispatch(action);
  };

  useEffect(() => {
    setLoading(true);
    const fetchSession = async () => {
      try {
        const companyId = localStorage.getItem("companyId");
        if (!companyId) {
          throw new Error("No se encontró el ID de la compañía");
        }

        const { data } = await api.get(`/whatsapp/one?session=0&companyId=${companyId}`);
        const safeData = data === null || data === undefined ? [] : 
                        Array.isArray(data) ? data : [data];
        dispatchWithTimestamp({ type: "LOAD_WHATSAPPS", payload: safeData });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatchWithTimestamp({ type: "LOAD_WHATSAPPS", payload: [] });
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-whatsapp`, (data) => {
      const currentTime = Date.now();
      const timeSinceLastManualUpdate = currentTime - lastManualUpdate;
      
      if (data.action === "update" && timeSinceLastManualUpdate < 2000) {
        console.log("Ignorando actualización de socket debido a actualización manual reciente");
        return;
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
      }
      
      if (data.action === "refresh") {
        const refreshData = async () => {
          try {
            setLoading(true);
            const response = await api.get(`/whatsapp/one?session=0&companyId=${companyId}`);
            const safeData = response.data === null || response.data === undefined ? [] : 
                          Array.isArray(response.data) ? response.data : [response.data];
            dispatch({ type: "LOAD_WHATSAPPS", payload: safeData });
            setLoading(false);
          } catch (err) {
            console.error("Error al refrescar WhatsApps:", err);
            setLoading(false);
          }
        };
        refreshData();
      }
    });

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_SESSION", payload: data.session });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [lastManualUpdate]);

  // Efecto para verificar el estado de las conexiones
  useEffect(() => {
    const checkConnections = () => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        
        const hasActiveConnections = whatsApps.some(whats => whats.status === "CONNECTED");
        
        if (offlineWhats.length === whatsApps.length || !hasActiveConnections) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    };

    const interval = setInterval(checkConnections, 2000);
    return () => clearInterval(interval);
  }, [whatsApps]);

  return { whatsApps, loading, dispatch: dispatchWithTimestamp, connectionWarning };
};

export default useWhatsApps;
