import React, { useContext, useEffect, useRef, useState } from "react";

import { useParams, useHistory } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import ChatList from "./ChatList";
import ChatMessages from "./ChatMessages";
import { UsersFilter } from "../../components/UsersFilter";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";

import { has, isObject } from "lodash";

import { AuthContext } from "../../context/Auth/AuthContext";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import AddIcon from "../../assets/icon/AddIcon";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    height: "100vh",
    overflowY: "hidden",
    padding: 0,
    margin: 0,
    border: "none",
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    marginLeft: "10px",
  },
  gridContainer: {
    flex: 1,
    width: "100%",
    height: "100%",

    backgroundColor: theme.palette.background.default,
    padding: "0 10px",
  },
  gridItem: {
    height: "100%",
    padding: 0,
    background: "transparent",
    display: "flex",
    flexDirection: "column",
  },
  leftSection: {
    marginTop: "25px",
    borderRadius: "15px 15px 0px 0px",
    backgroundColor: theme.palette.tabHeaderBackground,
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    background: theme.palette.tabHeaderBackground,
    borderRadius: "15px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)",
  },
  headerTitle: {
    marginTop: "10px",
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  newButton: {
    display: "flex",
    alignItems: "center",
    height: "38px",
    minWidth: "90px",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    padding: "0 18px",
    marginTop: "10px",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "500",
    backgroundColor: theme.palette.tabHeaderBackground,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}10`,
    },
  },
  newButtonText: {
    marginLeft: "10px",
  },
  chatListWrapper: {
    flex: 1,
    overflowY: "auto",
    paddingTop: "8px",
    "& .selected": {
      backgroundColor: "#e0e0e0 !important",
    }
  }
}));

export function ChatModal({
  open,
  chat,
  type,
  handleClose,
  handleLoadNewChat,
}) {
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle("");
    setUsers([]);
    if (type === "edit" && chat?.users) {
      try {
        const userList = chat.users
          .filter(u => u?.user?.id && u?.user?.name)
          .map((u) => ({
            id: u.user.id,
            name: u.user.name,
          }));
        setUsers(userList);
        setTitle(chat?.title || "");
      } catch (err) {
        console.error("Error al cargar datos del chat:", err);
        handleClose();
      }
    }
  }, [chat, open, type]);

  const handleSave = async () => {
    try {
      if (!users || users.length === 0) {
        alert("Por favor seleccione al menos un usuario.");
        return;
      }

      if (users.length > 1 && !title) {
        alert("Por favor complete el nombre del grupo.");
        return;
      }

      const chatTitle = users.length === 1 ? users[0].name : title;

      if (type === "edit") {
        await api.put(`/chats/${chat.id}`, {
          users,
          title: chatTitle,
        });
      } else {
        const { data } = await api.post("/chats", {
          users,
          title: chatTitle,
        });
        handleLoadNewChat(data);
      }
      handleClose();
    } catch (err) {}
  };  

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" style={{ paddingBottom: "10px" }}>Conversación</DialogTitle>
      <DialogContent style={{ minHeight: "160px", paddingTop: 0 }}>
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <UsersFilter
              onFiltered={(users) => setUsers(users)}
              initialUsers={users}
            />
          </Grid>
          <Grid xs={12} style={{ 
            padding: "0 18px", 
            height: users.length > 1 ? "60px" : "0px", 
            overflow: "hidden", 
            transition: "height 0.3s ease-in-out" 
          }} item>
            {users.length > 1 && (
              <TextField
                label="Nombre del grupo"
                placeholder="Nombre del grupo"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "10px 24px 16px" }}>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Chat(props) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const theme = useTheme();

  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState("new");
  const [currentChat, setCurrentChat] = useState({});
  const [chats, setChats] = useState([]);
  const [chatsPageInfo, setChatsPageInfo] = useState({ hasMore: false });
  const [messages, setMessages] = useState([]);
  const [messagesPageInfo, setMessagesPageInfo] = useState({ hasMore: false });
  const [messagesPage, setMessagesPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const isMounted = useRef(true);
  const scrollToBottomRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      findChats().then((data) => {
        if (data && data.records) {
          const { records } = data;
          if (records.length > 0) {
            setChats(records);
            setChatsPageInfo(data);

            if (id && records.length) {
              const chat = records.find((r) => r.uuid === id);
              if (chat && chat.id) {
                selectChat(chat);
              }
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isObject(currentChat) && has(currentChat, "id")) {
      findMessages(currentChat.id).then(() => {
        if (typeof scrollToBottomRef.current === "function") {
          setTimeout(() => {
            scrollToBottomRef.current();
          }, 300);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat-user-${user.id}`, (data) => {
      if (data.action === "create" && data.record) {
        setChats((prev) => [data.record, ...prev]);
      }
      if (data.action === "update") {
        setChats(prevChats => {
          if (!Array.isArray(prevChats)) return [];
          
          return prevChats.map(chat => {
            if (!chat) return chat;
            
            if (chat.id === data.record?.id) {
              setCurrentChat(data.record);
              return {
                ...data.record,
              };
            }
            return chat;
          }).filter(Boolean);
        });
      }
    });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "delete" && data.id) {
        setChats(prevChats => {
          if (!Array.isArray(prevChats)) return [];
          return prevChats.filter((c) => c?.id !== +data.id);
        });
        setMessages([]);
        setMessagesPage(1);
        setMessagesPageInfo({ hasMore: false });
        setCurrentChat({});
        history.push("/chats");
      }
      
      if (data.action === "new-message" && data.chat && data.newMessage) {
        setChats(prevChats => {
          if (!Array.isArray(prevChats)) return prevChats;
          
          return prevChats.map(chat => {
            if (!chat?.id) return chat;
            
            if (chat.id === data.chat.id) {
              return {
                ...chat,
                lastMessage: `${data.newMessage.sender?.name || 'Usuario'}: ${data.newMessage.message}`,
                updatedAt: data.newMessage.createdAt,
                users: data.chat.users
              };
            }
            return chat;
          }).filter(Boolean);
        });
      }
    });

    if (isObject(currentChat) && has(currentChat, "id")) {
      socket.on(`company-${companyId}-chat-${currentChat.id}`, (data) => {
        if (data.action === "new-message" && data.newMessage) {
          setMessages((prev) => {
            if (!Array.isArray(prev)) return [data.newMessage];
            if (!data.newMessage?.id) return prev;
            return [...prev, data.newMessage];
          });
          
          setChats(prevChats => {
            if (!Array.isArray(prevChats)) return prevChats;
            
            return prevChats.map(chat => {
              if (!chat?.id) return chat;
              
              if (chat.id === data.newMessage.chatId) {
                return {
                  ...chat,
                  lastMessage: `${data.newMessage.sender?.name || 'Usuario'}: ${data.newMessage.message}`,
                  updatedAt: data.newMessage.createdAt,
                  users: data.chat.users
                };
              }
              return chat;
            }).filter(Boolean);
          });
          
          scrollToBottomRef.current();
        }

        if (data.action === "update" && data.chat) {
          setChats(prevChats => {
            if (!Array.isArray(prevChats)) return prevChats;
            
            return prevChats.map(chat => {
              if (!chat?.id) return chat;
              
              if (chat.id === data.chat.id) {
                return {
                  ...data.chat,
                };
              }
              return chat;
            }).filter(Boolean);
          });
          scrollToBottomRef.current();
        }
      });
    }

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat, chats, user.id]);

  const selectChat = (chat) => {
    try {
      if (!chat || !chat.id) {
        console.error("Chat inválido seleccionado");
        return;
      }
      
      setMessages([]);
      setMessagesPage(1);
      setCurrentChat(chat);
      setTab(1);
    } catch (err) {
      console.error("Error al seleccionar chat:", err);
    }
  };

  const sendMessage = async (contentMessage) => {
    setLoading(true);
    try {
      await api.post(`/chats/${currentChat.id}/messages`, {
        message: contentMessage,
      });
    } catch (err) {}
    setLoading(false);
  };

  const deleteChat = async (chat) => {
    try {
      await api.delete(`/chats/${chat.id}`);
      toast.success("Chat eliminado con éxito");
      history.push("/chats");
    } catch (err) {
      toastError("Error al eliminar el chat");
    }
  };

  const findMessages = async (chatId) => {
    if (!chatId) return;
    
    setLoading(true);
    try {
      const { data } = await api.get(
        `/chats/${chatId}/messages?pageNumber=${messagesPage}`
      );
      
      if (data && Array.isArray(data.records)) {
        setMessagesPage((prev) => prev + 1);
        setMessagesPageInfo(data);
        setMessages((prev) => {
          const prevMessages = Array.isArray(prev) ? prev : [];
          const validMessages = data.records.filter(msg => msg && msg.id);
          return [...validMessages, ...prevMessages];
        });
      }
    } catch (err) {
      console.error("Error al cargar mensajes:", err);
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreMessages = async () => {
    if (!loading) {
      findMessages(currentChat.id);
    }
  };

  const findChats = async () => {
    try {
      const { data } = await api.get("/chats");
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const renderGrid = () => {
    return (
      <Grid className={classes.gridContainer} container>
        <Grid className={`${classes.leftSection}`} md={3} item>
          <div className={classes.sectionHeader}>
            <Typography className={classes.headerTitle}>
              Chat interno
            </Typography>
            <Button
              className={classes.newButton}
              onClick={() => {
                setDialogType("new");
                setShowDialog(true);
              }}
            >
              <AddIcon fill="#FDB813"/>
              <span className={classes.newButtonText}>Nuevo</span>
            </Button>
          </div>
          <div className={classes.chatListWrapper}>
            <ChatList
              chats={chats}
              pageInfo={chatsPageInfo}
              loading={loading}
              handleSelectChat={(chat) => selectChat(chat)}
              handleDeleteChat={(chat) => deleteChat(chat)}
              handleEditChat={() => {
                setDialogType("edit");
                setShowDialog(true);
              }}
            />
          </div>
        </Grid>
        <Grid className={classes.gridItem} md={9} item>
          {isObject(currentChat) && has(currentChat, "id") && (
            <ChatMessages
              chat={currentChat}
              scrollToBottomRef={scrollToBottomRef}
              pageInfo={messagesPageInfo}
              messages={messages}
              loading={loading}
              handleSendMessage={sendMessage}
              handleLoadMore={loadMoreMessages}
              handleEditChat={() => {
                setDialogType("edit");
                setShowDialog(true);
              }}
              handleDeleteChat={deleteChat}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const renderTab = () => {
    return (
      <Grid className={classes.gridContainer} container>
        {tab === 0 && (
          <Grid className={classes.gridItem} md={12} item>
            <div className={classes.btnContainer}>
              <Button
                onClick={() => setShowDialog(true)}
                color="primary"
                variant="contained"
              >
                Novo
              </Button>
            </div>
            <ChatList
              chats={chats}
              pageInfo={chatsPageInfo}
              loading={loading}
              handleSelectChat={(chat) => selectChat(chat)}
              handleDeleteChat={(chat) => deleteChat(chat)}
            />
          </Grid>
        )}
        {tab === 1 && (
          <Grid className={classes.gridItem} md={12} item>
            {isObject(currentChat) && has(currentChat, "id") && (
              <ChatMessages
                scrollToBottomRef={scrollToBottomRef}
                pageInfo={messagesPageInfo}
                messages={messages}
                loading={loading}
                handleSendMessage={sendMessage}
                handleLoadMore={loadMoreMessages}
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <ChatModal
        type={dialogType}
        open={showDialog}
        chat={currentChat}
        handleLoadNewChat={(data) => {
          if (data && data.id) {
            setMessages([]);
            setMessagesPage(1);
            setCurrentChat(data);
            setTab(1);
            history.push(`/chats/${data.uuid}`);
          }
        }}
        handleClose={() => setShowDialog(false)}
      />
      <div className={classes.mainContainer}>
        {isWidthUp("md", props.width) ? renderGrid() : renderTab()}
      </div>
    </>
  );
}

export default withWidth()(Chat);
